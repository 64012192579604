<script>
    import Form from '../Form.vue';

    export default {
        extends: Form,

        computed: {
            triggeringTextPatternParts: {
                get() {
                    return (this.formData.triggering_text_pattern || '').split('|').map(content => ({ content }));
                },
                set(value) {
                    this.formData.triggering_text_pattern = value.map(item => item.content).join('|');
                },
            },
        },
    };
</script>
