import serialize from 'devour-client/lib/middleware/json-api/_serialize';
import _isPlainObject from 'lodash/isPlainObject';
import _flatten from 'lodash/flatten';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
import _forOwn from 'lodash/forOwn';

function collection(modelName, items) {
    return _flatten(items.map(item => {
        return resource.call(this, modelName, item);
    }));
}

function resource(modelName, item) {
    const model = this.modelFor(modelName);
    const options = model.options || {};
    const readOnly = options.readOnly || [];
    let serializedRelationships = [];
    _forOwn(model.attributes, (value, key) => {
        if (isReadOnly(key, readOnly)) {
            return;
        }
        if (isRelationship(value) && shouldBeIncluded(value)) {
            serializedRelationships = serializedRelationships.concat(serializeRelationship.call(this, item[key], value));
        }
    });

    return serializedRelationships.filter(Boolean);
}

function isReadOnly(attribute, readOnly) {
    return readOnly.indexOf(attribute) !== -1;
}

function isRelationship(attribute) {
    return (_isPlainObject(attribute) && _includes(['hasOne', 'hasMany'], attribute.jsonApi));
}

function shouldBeIncluded(attribute) {
    return attribute.include === true;
}

function serializeRelationship(relationship, relationshipType) {
    if (relationshipType.jsonApi === 'hasMany' && relationship !== undefined) {
        return serializeHasMany.call(this, relationship, relationshipType.type);
    }
    if (relationshipType.jsonApi === 'hasOne' && relationship !== undefined) {
        return [serializeHasOne.call(this, relationship, relationshipType.type)];
    }
}

function serializeHasMany(relationships, type) {
    return _map(relationships, item => {
        return serializeHasOne.call(this, item, type || item.type);
    });
}

function serializeHasOne(relationship, type) {
    if (Object.keys(relationship).length < 2) {
        return null;
    }

    const item = serialize.resource.call(this, type || relationship.type, relationship);
    delete item.relationships;

    return item;
}

export default {
    resource,
    collection,
};
