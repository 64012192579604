<template>
    <div>
        <div v-if="isOnlineIntake" class="form-group-vertical">
            <div class="form-group-vertical__heading">
                <label class="form-group-vertical__label" for="complaint">
                    {{ $t('fysioroadmap.questionnaire-invite.complaint') }}
                </label>
            </div>
            <div class="form-group-vertical__field">
                <frm-input-smart-ajax-select
                    id="complaint"
                    v-model="complaintId"
                    :data-url="complaintsDataUrl"
                    :placeholder="$t('fysioroadmap.questionnaire-invite.no-complaint')"
                    name="complaint"
                />
            </div>
        </div>
        <div class="overview">
            <div class="overview__table">
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                <span class="table__th-wrapper">
                                    <input id="select-all"
                                           type="checkbox"
                                           checked
                                           class="form-radio__input form-checkbox__input"
                                           @input="toggleAll($event.target.checked)"
                                    >
                                    <label class="form-checkbox__label" for="select-all" />
                                </span>
                            </th>
                            <th>
                                <span class="table__th-wrapper">
                                    {{ $t('fysioroadmap.questionnaire-invite.clinimetrics-description') }}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="clinimetricsItem in clinimetricItems" :key="clinimetricsItem.id" class="zebra">
                            <td class="overview__column overview__column--checkbox">
                                <input :id="'clinimetrics_resources-'+clinimetricsItem.id"
                                       v-model="selectedClinimetricIds"
                                       :value="clinimetricsItem.id"
                                       type="checkbox"
                                       name="clinimetrics_resources[]"
                                       class="form-radio__input form-checkbox__input"
                                >
                                <label class="form-checkbox__label"
                                       :for="'clinimetrics_resources-'+clinimetricsItem.id"
                                />
                            </td>
                            <td>
                                <label :for="'clinimetrics_resources-'+clinimetricsItem.id">
                                    {{ clinimetricsItem.description }}
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td />
                            <td>
                                <frm-input-smart-ajax-select
                                    v-model="extraClinimetricsId"
                                    :data-url="extraClinimetricsDataUrl"
                                    :placeholder="$t('fysioroadmap.questionnaire-invite.extra-clinimetrics')"
                                    name="extra_clinimetrics"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    props: {
        initialData: {
            type: Array,
            required: true,
        },
        initialIds: {
            type: Array,
            required: true,
        },
        extraClinimetricsDataUrl: {
            type: String,
            required: true,
        },
        clinimetricsShowUrl: {
            type: String,
            required: true,
        },
        isOnlineIntake: {
            type: Boolean,
            default: false,
        },
        complaintsDataUrl: {
            type: String,
            default: null,
        },
        complaintClinimetricsDataUrl: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            selectedClinimetricIds: [...this.initialIds],
            clinimetricItems: [...this.initialData],
            complaintId: null,
            extraClinimetricsId: null,
        };
    },

    watch: {
        async complaintId(id) {
            if (!id) {
                return;
            }

            const url = window.decodeURIComponent(this.complaintClinimetricsDataUrl);
            const complaintClinimetrics = (await axios.get(url.replace(':id', id))).data.data;

            this.clinimetricItems = complaintClinimetrics
                .map(complaintClinimetricsItem => complaintClinimetricsItem.clinimetrics)
                .filter(clinimetrics => clinimetrics.can_be_sent_online);

            this.selectedClinimetricIds = this.clinimetricItems.map(item => item.id);
        },
        async extraClinimetricsId(id) {
            if (!id) {
                return;
            }

            const clinimetrics = (await axios.get(this.clinimetricsShowUrl.replace(':id', id))).data.data;

            this.clinimetricItems.push({
                id: clinimetrics.id,
                description: clinimetrics.description,
            });
            this.selectedClinimetricIds.push(clinimetrics.id);
            this.extraClinimetricsId = null;
        },
    },

    methods: {
        toggleAll(value) {
            this.selectedClinimetricIds = value ? this.clinimetricItems.map(clinimetrics => clinimetrics.id) : [];
        },
    },
};
</script>
