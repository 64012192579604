<template>
    <div ref="wrapper" class="table-scrollable-wrapper" :style="{ maxHeight: calculatedHeight + 'px' }">
        <slot />
    </div>
</template>

<script>

import $ from 'jquery';

const bottomOffset = 71;

// Use this component when you want to mimic the "getScrollY" datatable behavior in a traditional HTML-<table>.
// The wrapper will get a max-height with the "remaining" height in the viewport, minus a bottom padding.

export default {
    props: {
        stickyFooter: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return { calculatedHeight: null };
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.$nextTick(() => this.calculateHeight());
        });

        this.$nextTick(() => this.calculateHeight());
    },

    methods: {
        calculateHeight() {
            const rect = this.$el.getBoundingClientRect();

            this.calculatedHeight = Math.max(0,
                $(window).height() - rect.y - bottomOffset - (this.stickyFooter ? 28 : 0)
            );
        },
    },
};
</script>
