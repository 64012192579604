import Vue from 'vue';
import jQuery from 'jquery';
import axios from 'axios';
import { Service } from 'axios-middleware';
import store from './store';

window.Vue = Vue;
window.jQuery = window.$ = jQuery;

Vue.config.productionTip = false;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';

/**
 * We'll register a HTTP middleware to add all requests to a pool.
 * This pool can then be used to determine if HTTP requests are
 * pending and this allows us to show some sort of loader.
 */
let requestPool = [];
function pushRequestOnPool(request) {
    store.commit('core/setRequestPoolPending', true);
    requestPool.push(request);
    const requestPoolSize = requestPool.length;
    Promise.allSettled(requestPool).then(() => {
        if (requestPoolSize === requestPool.length) {
            store.commit('core/setRequestPoolPending', false);
            requestPool = [];
        }
    });
}

(new Service(axios)).register({
    onSync(promise) {
        pushRequestOnPool(promise);

        return promise;
    },
});

jQuery(document).ajaxSend((event, jqXHR, settings) => {
    pushRequestOnPool(jqXHR);
});

/**
 * We'll register a HTTP interceptor to attach the "X-Request-Id" header
 * to each of the outgoing requests issued by this application.
 * This header is used to identify related requests.
 */
const requestId = document.head.querySelector('meta[name="request-id"]');
if (requestId) {
    axios.defaults.headers.common['X-Request-Id'] = requestId.content;

    jQuery(document).ajaxSend((event, jqXHR, settings) => {
        jqXHR.setRequestHeader('X-Request-Id', requestId.content);
    });
}

/**
 * We'll register a HTTP interceptor to attach the "CSRF" header to each of
 * the outgoing requests issued by this application. The CSRF middleware
 * included with Laravel will automatically verify the header's value.
 */
const token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;

    jQuery(document).ajaxSend((event, jqXHR, settings) => {
        jqXHR.setRequestHeader('X-CSRF-TOKEN', token.content);
    });
} else {
    // eslint-disable-next-line no-console
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Add request interceptors that refresh the browser if they receive a 401.
 * This redirects the user to the login page and after login the
 * user is redirected back to the page (s)he came from.
 */
axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status && error.response.status === 401) {
        window.location.reload();
    }

    return Promise.reject(error);
});

jQuery(document).ajaxError((event, jqXHR, settings) => {
    if (jqXHR.status === 401) {
        window.location.reload();
    }
});
