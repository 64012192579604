<template>
    <div class="form-smart-text">
        <div
            :class="[ textareaClass, { 'form-input--initial': isInitial } ]"
            :disabled="disabledComputed"
            :required="required"
            class="form-input form-smart-text__input form-smart-text__input--text"
        >
            <div
                :id="id"
                ref="input"
                v-bind="$attrs"
                :contenteditable="!disabledComputed"
                :tabindex="disabledComputed ? -1 : 0"
                class="form-smart-text__input-inner"
                @blur="blur"
                @change="$emit('change', getPlainTextValue($event.target.innerHTML))"
                @contextmenu="$event.preventDefault()"
                @focus="focus"
                @input="$emit('input', getPlainTextValue($event.target.innerHTML))"
                @keydown="closeLinkContextMenu"
            />
        </div>
        <div
            v-if="showPlaceholder"
            :class="{'form-smart-text__placeholder--disabled': disabledComputed}"
            class="form-smart-text__placeholder"
        >
            {{ placeholder }}
        </div>
        <!--suppress HtmlFormInputWithoutLabel -->
        <input
            :disabled="disabledComputed"
            :name="name"
            :required="required"
            :value="value"
            autocomplete="off"
            class="form-smart-text__hidden"
            tabindex="-1"
            type="text"
        >
        <button
            :aria-label="buttonTooltip"
            :disabled="disabledComputed || loading"
            class="form-smart-text__button hint--left"
            type="button"
        >
            <frm-icon
                :class="{'form-smart-text__button-icon--error': error}"
                :name="buttonIconName"
                class="form-smart-text__button-icon"
            />
        </button>
    </div>
</template>

<script>
    import S from 'string';
    import $ from 'jquery';
    import 'jquery-contextmenu';
    import 'jquery-ui/ui/position';
    import debounce from '../../../services/debounce';
    import buildContextMenuItemsFromActions from '../../../services/buildContextMenuItemsFromActions';
    import MarkInitialValue from '../../../mixins/Inputs/MarkInitialValue';

    let uniqueIdentifier = 0;

    export default {
        mixins: [MarkInitialValue],

        inheritAttrs: false,

        props: {
            name: {
                type: String,
                default: null,
            },

            id: {
                type: String,
                default() {
                    return 'smart_text_' + this.uniqueIdentifier;
                },
            },

            value: {
                type: String,
                default: null,
            },

            placeholder: {
                type: String,
                default: '',
            },

            options: {
                type: Array,
                required: true,
            },

            links: {
                type: Object,
                default() {
                    return {};
                },
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            required: {
                type: Boolean,
                default: false,
            },

            loading: {
                type: Boolean,
                default: false,
            },

            error: {
                type: Boolean,
                default: false,
            },

            textareaClass: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                uniqueIdentifier: uniqueIdentifier++,
                focused: false,
                ignoreNextValueWatch: false,
                disabledByFieldset: false,
                contextMenuOpen: false,
            };
        },

        computed: {
            showPlaceholder() {
                return !this.value;
            },

            buttonIconName() {
                return this.error ? 'exclamation-mark' : this.loading ? 'small-loading' : 'smart-text';
            },

            buttonTooltip() {
                return this.error ? this.$t('form.smart-text.error') : this.loading ? this.$t('form.smart-text.loading') : '';
            },

            disabledComputed() {
                return this.disabled || this.disabledByFieldset;
            },
        },

        watch: {
            value(newValue) {
                if (!this.ignoreNextValueWatch && newValue !== this.getPlainTextValue(this.$refs.input.innerHTML)) {
                    this.setValue(newValue, this.links);
                }
                this.ignoreNextValueWatch = false;
            },

            links(newLinks) {
                if (this.value) {
                    this.setValue(this.value, newLinks);
                }
            },

            disabledComputed(newValue) {
                $(this.$el).find('.form-smart-text__input').contextMenu(!newValue);
            },
        },

        mounted() {
            this.init();
        },

        methods: {
            init() {
                const fieldset = this.$el.closest('fieldset');
                if (fieldset && fieldset.disabled) {
                    this.disabledByFieldset = true;
                }

                this.initContextMenu();
                this.initLinks();

                if (this.value) {
                    this.setValue(this.value);
                }

                // Remove formatting on paste
                this.$refs.input.addEventListener('paste', (event) => {
                    if (event.clipboardData && event.clipboardData.getData) {
                        event.preventDefault();
                        const text = event.clipboardData.getData('text/plain');
                        document.execCommand('insertText', false, this.getPlainTextFromPaste(text));
                    }
                });

                // Mimic the behaviour of focusing the input when clicking the label until it's implemented by the browser vendors (see https://github.com/w3c/html/issues/734)
                $(document).on('click', `label[for="${this.id}"]`, () => this.$refs.input.focus());

                this.initSpecialBehaviour();
            },

            initContextMenu() {
                const baseOptions = {
                    build: () => {
                        if (!this.options.length) {
                            return false;
                        }

                        return {
                            className: 'smart_text_contextmenu_' + this.uniqueIdentifier,
                            zIndex: 10,
                            items: buildContextMenuItemsFromActions(this.options, this.clicked, () => this.disabledComputed),
                            events: {
                                show: () => {
                                    this.contextMenuOpen = true;
                                },
                                hide: () => {
                                    this.contextMenuOpen = false;
                                },
                            },
                        };
                    },
                };

                // Input (right click)
                $(this.$el).contextMenu({
                    selector: '.form-smart-text__input',
                    ...baseOptions,
                });
                // Button (left click)
                $(this.$el).contextMenu({
                    selector: '.form-smart-text__button',
                    trigger: 'left',
                    ...baseOptions,
                });

                if (this.disabledComputed) {
                    $(this.$el).find('.form-smart-text__input').contextMenu(false);
                }
            },

            initLinks() {
                const baseOptions = {
                    selector: '.form-smart-text__link',
                    build: ($triggerElement) => {
                        const options = this.links[$triggerElement.data('name')] || [];

                        if (!options.length) {
                            return false;
                        }

                        return {
                            className: 'smart_text_contextmenu_link_' + this.uniqueIdentifier,
                            zIndex: 10,
                            autoHide: true,
                            items: buildContextMenuItemsFromActions(options, (option) => this.clickedLink(option, $triggerElement), () => this.disabledComputed),
                            events: {
                                show: () => {
                                    this.contextMenuOpen = true;
                                },
                                hide: () => {
                                    this.contextMenuOpen = false;
                                },
                            },
                        };
                    },
                };

                // Trigger 'both' doesn't exist so we just init it twice...
                $(this.$refs.input).contextMenu(baseOptions);
                $(this.$refs.input).contextMenu({ trigger: 'left', ...baseOptions });

                // Select the entire link when the cursor is placed inside it with the arrow keys
                $(this.$refs.input).on('keyup', (event) => {
                    // we only want keycodes 37 (left), 38 (up), 39 (right) & 40 (down)
                    if (event.keyCode < 37 || event.keyCode > 40) {
                        return;
                    }

                    const selection = window.getSelection();
                    if (!selection.rangeCount) {
                        return;
                    }

                    const $closestLink = $(selection.getRangeAt(0).commonAncestorContainer).closest('.form-smart-text__link');

                    if ($closestLink.length) {
                        this.selectElement($closestLink.closest('.form-smart-text__selectable')[0]);
                    }
                });

                // Select the entire link on focus
                $(this.$refs.input).on('focus', '.form-smart-text__link', (event) => {
                    this.selectElement($(event.target).closest('.form-smart-text__selectable')[0]);
                });

                // Replace any existing links
                if (this.value) {
                    this.setValue(this.value, this.links);
                }
            },

            initSpecialBehaviour() {
                // Prevent enter because this component mimics a text input
                this.$refs.input.addEventListener('keydown', (event) => {
                    if (event.keyCode === 13) {
                        event.preventDefault();
                    }
                });

                // The input needs a fixed width for 'overflow: hidden; white-space: nowrap;'...
                const $input = $(this.$refs.input);
                const setInputWidth = () => {
                    $input.hide().width($input.parent().width()).show();
                };
                window.addEventListener('resize', debounce(setInputWidth, 25));
                window.setTimeout(setInputWidth, 100);
            },

            /**
             * @param {string} text
             *
             * @return {string}
             */
            getPlainTextFromPaste(text) {
                return text.replace(/(\r\n|\n\r|\r|\n)/g, ' ');
            },

            /**
             * @param {{content: string}} option
             */
            clicked(option) {
                if (option.content) {
                    this.insertValue(option.content);
                }
            },

            /**
             * @param {{content: string}} option
             * @param {JQuery} $triggerElement
             */
            clickedLink(option, $triggerElement) {
                let content = option.content;
                Object.keys(this.links).forEach((link) => {
                    content = this.replaceLink(content, link);
                });

                const selectable = $triggerElement.closest('.form-smart-text__selectable');

                // Position the caret after the clicked link
                const range = document.createRange();
                range.selectNode(selectable[0]);
                range.collapse(false);
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);

                selectable.replaceWith(content);

                this.emitNewValue(this.getPlainTextValue(this.$refs.input.innerHTML));
            },

            /**
             */
            closeLinkContextMenu() {
                $(this.$refs.input).find('.form-smart-text__link').each((index, element) => {
                    $(element).contextMenu('hide');
                });
            },

            /**
             * @param {string} value
             *
             * @returns {string}
             */
            getPlainTextValue(value) {
                return S(value)
                    .trim()
                    .stripTags()
                    .decodeHTMLEntities()
                    .toString();
            },

            /**
             * @param {string} content
             */
            insertValue(content) {
                // If our input would receive user input, simply execute the insertText command
                // to place the new content at the cursor/selection position.
                const selection = window.getSelection();
                if (this.$refs.input.contains(selection.focusNode)) {
                    Object.keys(this.links).forEach((link) => {
                        content = this.replaceLink(content, link);
                    });

                    document.execCommand('insertHTML', false, content);

                    this.emitNewValue(this.getPlainTextValue(this.$refs.input.innerHTML));

                    // Make sure our input stays focused after Vue sets the new value.
                    this.$nextTick(() => {
                        this.$refs.input.focus();
                    });

                    return;
                }

                // Our input will not receive user input, so we simply append the new content.
                let currentValue = this.value;

                if (currentValue && !currentValue.trim()) {
                    currentValue = null;
                }

                this.emitNewValue(
                    [currentValue, content].filter(Boolean).join(' ')
                );
            },

            /**
             * @param {string} content
             * @param {Object} [links]
             */
            setValue(content, links = {}) {
                content = S(content).escapeHTML().toString();

                if (content.endsWith(']')) {
                    content += '&nbsp;';
                }

                Object.keys(links).forEach((link) => {
                    content = this.replaceLink(content, link);
                });

                // Wrap all lines in a div, just like the browser does
                this.$refs.input.innerHTML = content.split('\n').map(line => `<div>${line || '<br>'}</div>`).join('');
            },

            /**
             * @param {string} content
             * @param {string} name
             */
            replaceLink(content, name) {
                const replace = `<span class="form-smart-text__selectable" contentEditable="false"><span class="form-smart-text__hidden">[</span><span class="form-smart-text__link" tabindex="0" data-name="${name}">${name}</span><span class="form-smart-text__hidden">]</span></span>`;

                // eslint-disable-next-line no-useless-escape
                return content.replace(new RegExp(`\\\[${name}\\\]`, 'gi'), replace);
            },

            /**
             * @param {Element} element
             */
            selectElement(element) {
                const range = document.createRange();
                range.selectNode(element);
                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            },

            /**
             * @param {string} value
             */
            emitNewValue(value) {
                this.$emit('input', value);
                this.$emit('change', value);
            },

            focus($event) {
                this.focused = true;
                this.$emit('focus', $event);
            },

            blur($event) {
                setTimeout(() => {
                    if (this.$el.contains(document.activeElement) || this.contextMenuOpen) {
                        return;
                    }

                    this.focused = false;
                    this.$emit('blur', $event);
                }, 1);
            },
        },
    };
</script>
