export default {
    name: 'location',
    definition: {
        appointments_per_week: '',
        revenue_per_week: '',
        appointments_per_month: '',
        revenue_per_month: '',
        institute_id: '',
        description: '',
        color: '',
        active: '',
        target_id: '',
        institute: {
            jsonApi: 'hasOne',
            type: 'institute',
        },
        location_rooms: {
            jsonApi: 'hasMany',
            type: 'location_room',
        },
        location_devices: {
            jsonApi: 'hasMany',
            type: 'location_device',
        },
    },
};
