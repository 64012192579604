<template>
    <div class="form-select" :class="{'form-select--disabled': disabled}">
        <select ref="select" v-bind="$attrs" :disabled="disabled" :value="value" class="form-select__field" v-on="listeners">
            <option v-for="(option, index) in options" :key="index" :value="option.value" v-text="option.label" />
        </select>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        options: {
            type: Array,
            required: true,
        },

        value: {
            type: [Number, String],
            default: null,
        },
    },

    computed: {
        listeners() {
            return {
                ...this.$listeners,
                change: event => {
                    this.$emit('change', event.target.value);
                },
            };
        },
    },

    watch: {
        options(newOptions, oldOptions) {
            if (!Array.isArray(newOptions) || JSON.stringify(newOptions) === JSON.stringify(oldOptions)) {
                return;
            }

            const exists = Boolean(newOptions.find(option => String(option.value) === String(this.value)));

            if (exists) {
                this.$nextTick(() => {
                    this.$refs.select.value = this.value;
                });
            } else {
                this.$emit('change', null);

                this.$nextTick(() => {
                    this.$refs.select.selectedIndex = -1;
                });
            }
        },
    },
};
</script>
