<script>
    import closest from '../services/closest';
    import breakpoint from '../services/breakpoint';

    export default {
        directives: {
            checked: {
                /**
                 * @param {Element} element
                 */
                inserted(element) {
                    if (breakpoint('screen-l')) {
                        element.checked = true;
                    }
                },
            },
        },

        props: {
            sidebarOpened: {
                type: Boolean,
                required: true,
            },
        },

        watch: {
            sidebarOpened() {
                if (!this.sidebarOpened) {
                    this.closeAll();
                }
            },
        },

        mounted() {
            // Close all the accordions (if the sidebar isn't opened) when clicked on body
            window.addEventListener('click', (event) => {
                if (!this.sidebarOpened && !closest(event.target, '.sidebar')) {
                    this.closeAll();
                }
            });
        },

        methods: {
            /**
             * @param {Event} event
             */
            toggle(event) {
                const label = closest(event.target, 'label');
                const radio = document.getElementById(label.getAttribute('for'));

                if (radio.checked) {
                    event.preventDefault();
                    this.closeAll();
                }
            },

            closeAll() {
                const radios = document.getElementsByName('accordion-nav');
                for (let i = 0; i < radios.length; i++) {
                    radios[i].checked = false;
                }
            },
        },
    };
</script>
