<template>
    <div class="checkbox-list checkbox-list--tall">
        <h3 class="checkbox-list__header">
            {{ $t('fysioroadmap.anamnesis.screening.red-flags') }}
        </h3>
        <div ref="list" class="checkbox-list__items-container">
            <div v-for="(list, key) in flagLists" :key="list.id" class="checkbox-list__group">
                <input :name="name + '[flag_list_data][' + key + '][id]'" :value="list.id" type="hidden">
                <input :name="name + '[flag_list_data][' + key + '][description]'" :value="list.description" type="hidden">
                <div class="checkbox-list__group-header">
                    {{ list.description }}
                    <div v-if="flagLists.length > 1" class="checkbox-list__group-close" @click="removeFlagList(list.id)">
                        <frm-icon name="cross" class="checkbox-list__group-close-icon" />
                    </div>
                </div>
                <div v-for="(item, flagKey) in list.flag_data.data" :key="item.id" class="checkbox-list__item">
                    <input :name="name + '[flag_list_data][' + key + '][flag_data][' + flagKey + '][id]'" :value="item.id" type="hidden">
                    <input :name="name + '[flag_list_data][' + key + '][flag_data][' + flagKey + '][description]'" :value="item.description" type="hidden">
                    <input :name="name + '[flag_list_data][' + key + '][flag_data][' + flagKey + '][is_selected]'" type="hidden" value="0">
                    <input :name="name + '[flag_list_data][' + key + '][flag_data][' + flagKey + '][is_subtitle]'" type="hidden" :value="item.is_subtitle ? 1 : 0">
                    <h4 v-if="item.is_subtitle" class="checkbox-list__subtitle">
                        {{ item.description }}
                    </h4>
                    <div v-else class="form-checkbox form-checkbox--in-checkbox-list">
                        <input
                            :id="'frm_multi_select_' + name + '_' + item.id"
                            v-model="flagListValues[item.id]"
                            :name="name + '[flag_list_data][' + key + '][flag_data][' + flagKey + '][is_selected]'"
                            class="form-checkbox__input"
                            type="checkbox"
                            value="1"
                        >
                        <label
                            :for="'frm_multi_select_' + name + '_' + item.id"
                            class="form-checkbox__label form-checkbox__label--with-icon"
                        >
                            <span class="form-checkbox__icon">
                                <frm-icon
                                    :class="{'screening-flag--active': flagListValues[item.id]}"
                                    class="screening-flag"
                                    name="flag"
                                />
                            </span>
                            <span class="form-checkbox__text">{{ item.description }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div v-if="listSelectIsShown" class="checkbox-list__group" style="margin-bottom: 40px;">
                <div class="checkbox-list__group-header">
                    <div v-if="flagGroupIsLoading" class="spinner">
                        <div class="spinner__dot" />
                        <div class="spinner__dot" />
                        <div class="spinner__dot" />
                    </div>
                    <div v-else class="form-select">
                        <select
                            v-model="flagListSelect"
                            :disabled="flagListIsLoading"
                            class="form-select__field"
                            @change="addFlagList($event)"
                        >
                            <option v-if="flagListIsLoading" :value="null">
                                {{ $t('form.ajaxselect.loading') }}
                            </option>
                            <template v-if="!flagListIsLoading">
                                <option :value="null" disabled>
                                    {{ $t('fysioroadmap.anamnesis.screening.flag-list-select-placeholder') }}
                                </option>
                                <option v-for="(option, index) in availableFlagListOptions" :key="index" :value="option.id">
                                    {{ option.description }}
                                </option>
                            </template>
                        </select>
                    </div>
                    <div class="checkbox-list__group-close" @click="listSelectIsShown = false">
                        <frm-icon name="cross" class="checkbox-list__group-close-icon" />
                    </div>
                </div>
            </div>
        </div>
        <div class="checkbox-list__actions">
            <button :disabled="listSelectIsShown" class="button button--small" type="button" @click="showListSelect">
                <span class="button__icon-group">
                    <frm-icon class="button__icon" name="plus" />
                    <span>{{ $t('fysioroadmap.anamnesis.screening.add-flag-list') }}</span>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        props: {
            data: {
                type: Object,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            flagListUrl: {
                type: String,
                required: true,
            },
        },
        data() {
            const flagListValues = {};
            this.data.data.forEach(list => {
                list.flag_data.data.forEach(item => {
                    flagListValues[item.id] = !!item.is_selected;
                });
            });

            const flagLists = [].concat(this.data.data);

            return {
                listSelectIsShown: false,
                flagListIsLoading: false,
                flagGroupIsLoading: false,
                flagListOptions: [],
                flagListValues: flagListValues,
                flagLists,
                flagListSelect: null,
                newListCounter: 0,
                newFlagCounter: 0,
            };
        },
        computed: {
            availableFlagListOptions() {
                return this.flagListOptions.filter(option => {
                    return !this.flagLists.find(list => {
                        return String(list.id) === String(option.id);
                    });
                });
            },
        },
        watch: {
            flagListValues: {
                deep: true,
                handler(newValue) {
                    this.$emit('change', newValue);
                },
            },
        },
        created() {
            this.$emit('change', this.flagListValues);
        },
        methods: {
            showListSelect() {
                this.listSelectIsShown = true;
                this.fillFlagListOptions();

                this.$nextTick(function () {
                    this.$refs.list.scrollTop = this.$refs.list.scrollHeight;
                });
            },
            fillFlagListOptions() {
                if (this.flagListOptions.length === 0) {
                    this.flagListIsLoading = true;

                    if (this.$options.previousSource) {
                        this.$options.previousSource.cancel();
                        this.$options.previousSource = null;
                    }

                    this.$options.previousSource = axios.CancelToken.source();

                    axios
                        .get(this.flagListUrl, {
                            cancelToken: this.$options.previousSource.token,
                        })
                        .then(response => {
                            this.flagListOptions = response.data.data;
                            this.flagListIsLoading = false;
                        }).catch(() => {
                            // TODO: Do something on failure
                        });
                }
            },
            addFlagList($event) {
                this.flagGroupIsLoading = true;
                this.flagListSelect = null;

                if (this.$options.previousSourceFlagList) {
                    this.$options.previousSourceFlagList.cancel();
                    this.$options.previousSourceFlagList = null;
                }

                this.$options.previousSourceFlagList = axios.CancelToken.source();

                axios
                    .get(this.flagListUrl, {
                        params: {
                            list_id: $event.target.value,
                        },
                        cancelToken: this.$options.previousSourceFlagList.token,
                    })
                    .then(response => {
                        this.flagGroupIsLoading = false;
                        this.listSelectIsShown = false;
                        response.data.data.id = 'new_' + (++this.newListCounter);
                        response.data.data.flag_data.data.forEach(item => {
                            item.id = 'new_' + (++this.newFlagCounter);
                        });
                        this.flagLists.push(response.data.data);
                    }).catch(() => {
                        // TODO: Do something on failure
                    });
            },
            removeFlagList(flagListId) {
                let flagListIndex;
                this.flagLists.forEach((list, index) => {
                    if (list.id === flagListId) {
                        flagListIndex = index;
                    }
                });

                this.flagLists.splice(flagListIndex, 1);
            },
        },

        previousSource: null,

        previousSourceFlagList: null,
    };
</script>
