import Vue from 'vue';
import i18n from '../i18n';
import Modal from '../components/Modal.vue';
import Dialog from '../components/Modals/Dialog';

const modalManager = new Vue({ i18n, ...Modal, propsData: { dialog: true } }).$mount();
document.body.appendChild(modalManager.$el);

export default class DialogManager {
    /**
     * @param {String} message N.B. This can be HTML so make sure you escape user input!
     * @param {String} [ok]
     *
     * @returns {Promise} Resolved when the user clicks the ok button.
     */
    static alert(message, ok = i18n.t('core.dialog.alert.ok')) {
        return new Promise((resolve, reject) => {
            modalManager.openModal(
                Dialog,
                { message, ok },
                {
                    ok: () => {
                        modalManager.closeModal();
                        resolve();
                    },
                    cancel: () => {
                        modalManager.closeModal();
                        reject(new Error('Cancelled'));
                    },
                },
                'medium',
                false
            );
        });
    }

    static errors(errors = i18n.t('base.errors.server'), ok = i18n.t('core.dialog.alert.ok')) {
        // eslint-disable-next-line no-console
        console.error(errors);

        if (typeof errors === 'string') {
            return DialogManager.alert(errors, ok);
        }

        return DialogManager.alert(Object.values(errors).map(e => e.detail).join('<br>'), ok);
    }

    /**
     * @param {String} message N.B. This can be HTML so make sure you escape user input!
     * @param {String} [ok]
     * @param {String} [cancel]
     *
     * @returns {Promise} Resolved when the user clicks the ok button, rejected when the user clicks the cancel button.
     */
    static confirm(message, ok = i18n.t('core.dialog.confirm.ok'), cancel = i18n.t('core.dialog.confirm.cancel')) {
        return new Promise((resolve, reject) => {
            modalManager.openModal(
                Dialog,
                { message, ok, cancel },
                {
                    ok: () => {
                        modalManager.closeModal();
                        resolve();
                    },
                    cancel: () => {
                        modalManager.closeModal();
                        reject(new Error('Cancelled'));
                    },
                },
                'medium',
                false
            );
        });
    }
}
