export default {
    name: 'calendar_item_with_service_participation',
    definition: {
        business_data_id: '',
        calendar_item_id: '',
        patient_id: '',
        send_confirmation: '',
        send_reminder: '',
        confirmation_template_id: '',
        reminder_template_id: '',
        insurance_details: '',
        chronic: '',
        complaint: '',
        date_of_birth: '',
        date_of_indication: '',
        description: '',
        diagnosis_code: '',
        gender: '',
        general_practitioner: '',
        identification_number: '',
        indication: '',
        indication_code: '',
        indication_history_id: '',
        invoice_can_be_printed: '',
        invoicing_profile: '',
        module: '',
        paid_by: '',
        participation_date: '',
        patient: {
            jsonApi: 'hasOne',
            type: 'patient',
        },
        patient_address_phone_mobile: '',
        patient_address_phone_private: '',
        patient_age: '',
        patient_name: '',
        patient_policy_item_id: '',
        performance_code: '',
        photo_id: '',
        referrer: '',
        service_code: '',
        status: '',
        therapist: '',
        total_amount: '',
    },
};
