<script>
    import Default from './Default.vue';

    import axios from 'axios';
    import moment from 'moment';

    export default {
        extends: Default,

        props: {
            url: {
                type: String,
                required: true,
            },
            legacy: {
                type: Boolean,
                default: false,
            },
            birthdate: {
                type: String,
                required: true,
            },
            dueDate: {
                type: String,
                default: null,
            },
            questionsGraph: {
                type: Object,
                default: () => {},
            },
            questionsProne: {
                type: Array,
                required: true,
            },
            questionsSupine: {
                type: Array,
                required: true,
            },
            questionsSitting: {
                type: Array,
                required: true,
            },
            questionsStanding: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                activeTab: '',
                subscores: [],
                graphScores: [],
                checkedSubscores: [],
                loadingSubscores: true,
            };
        },

        computed: {
            ageInMonths() {
                return moment(this.administrationDate).diff(this.dueDate || this.birthdate, 'months', true);
            },

            ageFormatted() {
                return this.formatAgeFromDate(this.birthdate);
            },

            ageDueDateFormatted() {
                if (this.dueDate) {
                    return this.formatAgeFromDate(this.dueDate);
                }

                return this.ageFormatted;
            },

            ageFormattedWithTranslation() {
                return this.formatAgeFromDateWithTranslation(this.birthdate);
            },

            ageDueDateFormattedWithTranslation() {
                if (this.dueDate) {
                    return this.formatAgeFromDateWithTranslation(this.dueDate);
                }

                return this.ageFormattedWithTranslation;
            },

            pronePrevious() {
                return this.getScorePrevious(this.formData.clinimetrics_item_data[0].checked_answers, this.questionsProne);
            },
            proneWindow() {
                return this.getScoreWindow(this.formData.clinimetrics_item_data[0].checked_answers, this.questionsProne);
            },
            proneSubscale() {
                return this.pronePrevious + this.proneWindow;
            },

            supinePrevious() {
                return this.getScorePrevious(this.formData.clinimetrics_item_data[1].checked_answers, this.questionsSupine);
            },
            supineWindow() {
                return this.getScoreWindow(this.formData.clinimetrics_item_data[1].checked_answers, this.questionsSupine);
            },
            supineSubscale() {
                return this.supinePrevious + this.supineWindow;
            },

            sittingPrevious() {
                return this.getScorePrevious(this.formData.clinimetrics_item_data[2].checked_answers, this.questionsSitting);
            },
            sittingWindow() {
                return this.getScoreWindow(this.formData.clinimetrics_item_data[2].checked_answers, this.questionsSitting);
            },
            sittingSubscale() {
                return this.sittingPrevious + this.sittingWindow;
            },

            standingPrevious() {
                return this.getScorePrevious(this.formData.clinimetrics_item_data[3].checked_answers, this.questionsStanding);
            },
            standingWindow() {
                return this.getScoreWindow(this.formData.clinimetrics_item_data[3].checked_answers, this.questionsStanding);
            },
            standingSubscale() {
                return this.standingPrevious + this.standingWindow;
            },
            administrationDate() {
                if (!this.formData.administration_date) {
                    return moment().format('YYYY-MM-DD');
                }

                return this.formData.administration_date;
            },
            totalPrevious() {
                return this.pronePrevious + this.supinePrevious + this.sittingPrevious + this.standingPrevious;
            },
            totalWindow() {
                return this.proneWindow + this.supineWindow + this.sittingWindow + this.standingWindow;
            },
            totalSubscale() {
                return this.totalPrevious + this.totalWindow;
            },
            graphValue() {
                if (!this.legacy) {
                    return 'default';
                }

                const answer = this.questionsGraph[this.formData.clinimetrics_item_data[6].checked_answers];

                if (answer === 0) {
                    return 'legacy-premature';
                }

                return 'legacy-normal';
            },
            graphScoresComputed() {
                // Only show the checked subscores...
                const graphScores = this.graphScores.filter(score => {
                    return this.checkedSubscores.includes(score.id);
                });

                graphScores.push({
                    id: 0,
                    ageInMonths: this.ageInMonths,
                    score: this.totalSubscale,
                });

                return graphScores;
            },
        },

        mounted() {
            // Retrieve previous subscores of the clinimetric...
            axios.get(this.url).then(response => {
                this.subscores = response.data.data;

                // Show every subscore on the graph...
                this.subscores.forEach(subscore => {
                    const ageInMonths = moment(subscore.date).diff(this.dueDate || this.birthdate, 'months', true);

                    this.checkedSubscores.push(subscore.id);
                    this.graphScores.push({
                        id: subscore.id,
                        ageInMonths,
                        score: subscore.rough_score_value,
                    });
                });

                this.loadingSubscores = false;
            });
        },

        methods: {
            submit(event) {
                event.preventDefault();

                if (this.formIsInvalid()) {
                    this.showFirstInvalidElement();

                    return;
                }

                // Save the image in the attachment on submit...
                this.formData.clinimetrics_item_data[5].attachment_raw = this.$refs.aims.toBase64();

                this.formSubmitted = true;
                this.$nextTick(() => {
                    this.$refs.form.submit();
                });
            },

            formatAgeFromDate(date) {
                const administrationDate = moment(this.administrationDate);
                const momentDate = moment(date);

                if (administrationDate.isBefore(momentDate)) {
                    return '0.0';
                }

                const months = administrationDate.diff(momentDate, 'months');
                const days = administrationDate.diff(momentDate.add(months, 'months'), 'days');

                return `${months}.${days}`;
            },

            formatAgeFromDateWithTranslation(date) {
                const number = this.formatAgeFromDate(date).replace('.', ',');

                return this.$t('fysioroadmap.clinimetrics.aims.months', { number });
            },

            getScorePrevious(checkedAnswers, possibleAnswers) {
                let score = 0;
                const ids = [...checkedAnswers]
                    .map(String)
                    .sort((a, b) => {
                        if (possibleAnswers.indexOf(a) > possibleAnswers.indexOf(b)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });

                for (const [index, answer] of possibleAnswers.entries()) {
                    if (String(answer) !== ids[index]) {
                        break;
                    }

                    ++score;
                }

                return score;
            },

            getScoreWindow(checkedAnswers, possibleAnswers) {
                let score = 0;
                const ids = [...checkedAnswers]
                    .map(String)
                    .sort((a, b) => {
                        if (possibleAnswers.indexOf(a) > possibleAnswers.indexOf(b)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });

                for (const [index, answer] of possibleAnswers.entries()) {
                    if (String(answer) === ids[index]) {
                        continue;
                    }

                    if (ids.includes(String(answer))) {
                        score++;
                    }
                }

                return score;
            },
        },
    };
</script>
