<script>
    import Form from '../../Form.vue';
    import UrlQueryHelper from '../../../services/UrlQueryHelper';
    import updateQueryStringParameter from '../../../services/updateQueryStringParameter';
    import ModalManager from '../../../services/modalManager';
    import axios from 'axios';
    import ValidityForm from '../../Modals/DiagnosisCodeValidity.vue';

    export default {
        extends: Form,

        props: {
            indicationHistoryId: {
                type: String,
                required: true,
            },

            indicationYesAnswerId: {
                type: String,
                required: true,
            },

            researchYesAnswerId: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                redirectTo: '',
                caregiverPermissionConclusion: false,
            };
        },

        computed: {
            hasTreatmentOrigin() {
                return Boolean(this.formData.treatment_origin);
            },

            isSendByReferrer() {
                return this.formData.treatment_origin === 'send_by_referrer';
            },

            isDirectAccessPhysiotherapy() {
                return this.formData.treatment_origin === 'direct_access_physiotherapy';
            },

            isConsultation() {
                return this.formData.treatment_origin === 'consultation';
            },

            screeningTabEnabled() {
                return this.isDirectAccessPhysiotherapy;
            },

            examinationTabEnabled() {
                if (this.isDirectAccessPhysiotherapy) {
                    return this.formData.anamnesis.screening ? String(this.formData.anamnesis.screening.examine_patient) === this.researchYesAnswerId : false;
                }

                return this.hasTreatmentOrigin;
            },

            treatmentPlanTabEnabled() {
                if (this.isConsultation) {
                    return this.formData.anamnesis.consult ? String(this.formData.anamnesis.consult.indication_physical_therapy_after_consult) === this.indicationYesAnswerId : false;
                }

                return String(this.formData.anamnesis.indication_physical_therapy) === this.indicationYesAnswerId;
            },
        },

        methods: {
            switchTab(href) {
                const query = new UrlQueryHelper();
                if (query.isModal()) {
                    href = updateQueryStringParameter(href, 'modal', '1');
                }

                this.redirectTo = href;
                this.$nextTick(() => {
                    this.$refs.form.querySelector('button[type="submit"]').click();
                });
            },

            openContactInformationModal() {
                ModalManager.openModal(`/fysioroadmap/${this.formData.patient_id}/${this.indicationHistoryId}/anamnesis/registration/contact-information`);
            },

            fetchCaregiverPermission() {
                axios.get(`/fysioroadmap/${this.formData.patient_id}/${this.indicationHistoryId}/anamnesis/screening/caregiver_permission`).then(response => {
                    this.caregiverPermissionConclusion = response.data.conclusion;
                });
            },

            openPermissionsModal() {
                ModalManager.openModal(`/fysioroadmap/${this.formData.patient_id}/${this.indicationHistoryId}/permissions`, undefined, undefined, 'full')
                    .then(() => {
                        axios.get(`/fysioroadmap/${this.formData.patient_id}/${this.indicationHistoryId}/indication/show`)
                            .then(response => {
                                this.formData.indication_history_permission_needs_attention = response.data.data.indication_history_permission_needs_attention;
                            });

                        this.fetchCaregiverPermission();
                    });
            },

            openMedicalHistoryModal() {
                ModalManager.openModal(`/patient/${this.formData.patient_id}/medical_history?module=${this.module}`)
                    .then(() => {
                        this.fetchMedicalHistory();
                    });
            },

            openNotesModal() {
                ModalManager.openModal(`/fysioroadmap/${this.formData.patient_id}/${this.indicationHistoryId}/anamnesis/registration/note`);
            },

            editValidity() {
                ModalManager.openModal(
                    ValidityForm,
                    {
                        validFrom: this.formData.valid_from,
                        validUntil: this.formData.valid_until,
                    },
                    {
                        'set-validity': this.setValidity,
                    },
                    'medium'
                );
            },

            /**
             * @param {{valid_from: String, valid_until: String}} data
             */
            setValidity(data) {
                this.formData.valid_from = data.valid_from;
                this.formData.valid_until = data.valid_until;
            },
        },
    };
</script>
