export default class UrlQueryHelper {
    constructor() {
        this.parameters = new URLSearchParams(window.location.search);
    }

    get(name) {
        return this.parameters.get(name);
    }

    has(name) {
        return this.parameters.has(name);
    }

    append(name, value) {
        return this.parameters.append(name, value);
    }

    delete(name) {
        return this.parameters.delete(name);
    }

    parameters() {
        return this.parameters;
    }

    isModal() {
        return this.get('modal');
    }
}
