<script>
    import axios from 'axios';
    import ModalManager from '../services/modalManager';

    export default {
        props: {
            actionsUrl: {
                type: String,
                required: true,
            },

            countersUrl: {
                type: String,
                required: true,
            },

            messagesUrl: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                counters: {
                    actions: 0,
                    messages: 0,
                },
            };
        },

        mounted() {
            this.fetchCounters();
        },

        methods: {
            fetchCounters() {
                return axios.get(this.countersUrl)
                    .then(response => {
                        this.counters = response.data;
                    });
            },

            openActionsModal() {
                ModalManager.openModal(this.actionsUrl)
                    .then(() => {
                        this.fetchCounters();
                    });
            },

            openMessagesModal() {
                ModalManager.openModal(this.messagesUrl)
                    .then(() => {
                        this.fetchCounters();
                    });
            },

            openIndicationHistorySummaryModal() {
                ModalManager.openModal(`/fysioroadmap/${this.$store.state.patientId}/${this.$store.state.indicationId}/indication/summary`, undefined, {}, 'full');
            },

            openMigrationSummaryModal() {
                ModalManager.openModal(`/fysioroadmap/${this.$store.state.patientId}/${this.$store.state.indicationId}/migration/summary`, undefined, {}, 'full');
            },
        },
    };
</script>
