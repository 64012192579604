<template>
    <div class="form-group" :class="computedGroupClass">
        <label class="form-group__label" :class="computedLabelClass">
            {{ label }}
        </label>
        <div class="form-group__field" :class="computedFieldClass">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },

        static: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        computedGroupClass() {
            return this.static ? 'form-group--static' : '';
        },

        computedLabelClass() {
            return this.static ? 'form-group__label--static' : '';
        },

        computedFieldClass() {
            return this.static ? 'form-group__field--static' : '';
        },
    },
};
</script>
