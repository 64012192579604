/**
 * @param {String} uri
 * @param {String} key
 * @param {String|Number} value
 *
 * @returns {String} uri with added parameter
 */
export default function updateQueryStringParameter(uri, key, value) {
    const regex = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    let hash;
    [uri, hash] = uri.split('#', 2);
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';

    if (uri.match(regex)) {
        return uri.replace(regex, `$1${key}=${value}$2`) + (hash ? '#' + hash : '');
    } else {
        return `${uri}${separator}${key}=${value}${hash ? '#' + hash : ''}`;
    }
}
