<template>
    <div class="patient-search">
        <div class="patient-search__input">
            <div class="form-icon-field">
                <input
                    id="frm_quick_search_patient"
                    v-model="searchInput"
                    :placeholder="searchPlaceholder"
                    autocomplete="off"
                    class="form-input form-input--dark form-icon-field__field"
                    name="frm_quick_search_patient"
                    @blur="focused = false"
                    @focus="focused = true"
                    @keydown="keydown"
                >
                <label class="form-icon-field__label" for="frm_quick_search_patient">{{ searchPlaceholder }}</label>
                <button type="button" class="form-icon-field__button" @click="searchInput = ''">
                    <frm-icon :name="searchInput ? 'cross-2' : 'magnifying-glass'" class="form-icon-field__icon" />
                </button>
            </div>
        </div>
        <frm-patient-search-results
            v-if="showResults"
            ref="results"
            :create-url="createUrl"
            :patients="patients"
            :query="searchInput"
            :select-url="selectUrl"
            :show-all-url="showAllUrl"
        />
    </div>
</template>

<script>
    import Results from './PatientSearch/Results.vue';
    import i18n from '../i18n';
    import axios from 'axios';

    export default {
        components: {
            'frm-patient-search-results': Results,
        },

        props: {
            dataUrl: {
                type: String,
                required: true,
            },

            createUrl: {
                type: String,
                required: true,
            },

            selectUrl: {
                type: String,
                required: true,
            },

            showAllUrl: {
                type: String,
                required: true,
            },

            searchPlaceholder: {
                type: String,
                default: i18n.t('patient.selection.input-placeholder'),
            },
        },

        data() {
            return {
                searchInput: '',
                patients: [],
                focused: false,
                showResults: false,
            };
        },

        watch: {
            focused(focused) {
                const container = this.$el.closest('.js-patient-sidebar-search-container');
                if (focused) {
                    container.classList.add('patient-sidebar-header--focused');
                    return;
                }

                container.classList.remove('patient-sidebar-header--focused');
            },

            searchInput: {
                immediate: true,
                handler(query) {
                    if (query.length < 2) {
                        this.resetResults();

                        return;
                    }

                    if (this.$options.previousSource) {
                        this.$options.previousSource.cancel();
                        this.$options.previousSource = null;
                    }

                    this.$options.previousSource = axios.CancelToken.source();

                    return axios
                        .get(this.dataUrl, {
                            params: {
                                query,
                            },
                            cancelToken: this.$options.previousSource.token,
                        })
                        .then(response => {
                            this.setResults(response.data.data);
                        }).catch(() => {
                            this.resetResults();
                        });
                },
            },
        },

        methods: {
            resetResults() {
                this.patients = [];
                this.showResults = false;
            },

            setResults(data) {
                this.patients = data;
                this.showResults = true;
            },

            keydown(event) {
                if (!this.$refs.results) {
                    return;
                }

                switch (event.key) {
                    case 'ArrowUp':
                        this.$refs.results.up();
                        break;
                    case 'ArrowDown':
                        this.$refs.results.down();
                        break;
                    case 'Enter':
                        this.$refs.results.enter();
                        break;
                    case 'Escape':
                        this.searchInput = '';
                        break;
                    default:
                        return;
                }

                event.preventDefault();
            },
        },

        previousSource: null,
    };
</script>
