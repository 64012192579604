<script>
    export default {
        props: {
            data: {
                type: Object,
                default() {
                    return {};
                },
            },
        },

        data() {
            return { ...this.data };
        },

        watch: {
            module() {
                this.section = null;
            },

            section() {
                if (this.section) {
                    this.$refs.form.submit();
                }
            },
        },
    };
</script>
