<script>
import Form from '../Form.vue';
import ModalManager from '../../services/modalManager';
import HandlesActions from '../../mixins/Overview/HandlesActions';

export default {
    mixins: [Form, HandlesActions],

    methods: {
        async afterSubmit(event) {
            if (event) {
                event.preventDefault();
            }

            const params = new URLSearchParams(new FormData(this.$refs.form)).toString();
            const method = this.$refs.form.getAttribute('method');
            const url = `${this.$refs.form.getAttribute('action')}?${params}`;

            await this.handleDownload(url, method);

            ModalManager.closeModal();
        },
    },
};
</script>
