import moment from 'moment';

/**
 * Creates string from Date in ISO-8601 (without timezone) format e.g. 2017-01-01T00:00:00
 *
 * @param {Date|moment.Moment} date
 * @param {string} [format]
 * @returns {string}
 */
export default function dateFormatter(date, format = 'YYYY-MM-DDTHH:mm:ss') {
    return moment(date).format(format);
}
