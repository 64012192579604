<script>
    import axios from 'axios';
    import Form from '../Form.vue';

    export default {
        extends: Form,

        data() {
            return {
                fetchingDiagnosisCodeCode: false,
            };
        },

        watch: {
            'formData.indication'(value) {
                this.setDiagnosisCodeFromIndication(value);
            },
        },

        methods: {
            setDiagnosisCodeFromIndication(indicationId) {
                this.fetchingDiagnosisCodeCode = true;

                axios.get(`/masterfiles/indications/show?id=${indicationId}`)
                    .then(response => {
                        this.formData.diagnosis_code.code = response.data.data.diagnosis_code_code;
                    })
                    .finally(() => {
                        this.fetchingDiagnosisCodeCode = false;
                    });
            },
        },
    };
</script>
