/*
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. It returns a Promise that resolves when the function is called.
 *
 * @see https://gist.github.com/nmsdvid/8807205
 *
 */
export default function debounce(func, wait) {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);

        return new Promise((resolve) => {
            timeout = setTimeout(() => {
                timeout = null;

                resolve(func.apply(context, args));
            }, wait);
        });
    };
}
