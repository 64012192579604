import jsonApi from '../json-api-client';
import DialogManager from './DialogManager';
import apiUrl from './apiUrl';
import i18n from '../i18n';
import dateFormatter from '../i18n/dateFormatter';

export default function doCovCheck(patientId, date) {
    const urlDate = date || dateFormatter(new Date());

    return jsonApi.axios.get(apiUrl(`/patients/${patientId}/cov?date=${urlDate}`))
        .then(() => DialogManager.alert(i18n.t('patient.cov-check.success')))
        .catch(error => DialogManager.alert(`${Object.values(error.response.data.errors)
            .map(e => e.detail)
            .join('<br>')}`));
}
