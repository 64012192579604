import CalendarItemWithPerformanceCode from './CalendarItemWithPerformanceCode';

export default {
    ...CalendarItemWithPerformanceCode,
    name: CalendarItemWithPerformanceCode.name + '_resource',
    definition: {
        ...CalendarItemWithPerformanceCode.definition,
        calendar_item_participations: {
            ...CalendarItemWithPerformanceCode.definition.calendar_item_participations,
            type: CalendarItemWithPerformanceCode.definition.calendar_item_participations.type + '_resource',
        },
    },
};
