<template>
    <div data-testid="fysioroadmap-dashboard">
        <slot
            :dossierStatus="dossierStatus"
            :fetchDossierStatus="fetchDossierStatus"
            :openDossierStatusModal="openDossierStatusModal"
            :openRecoveringInfluencingFactorsModal="openRecoveringInfluencingFactorsModal"
            :medicalHistory="medicalHistory"
            :medicalHistoryBadge="medicalHistoryBadge"
            :openMedicalHistoryModal="openMedicalHistoryModal"
            :openIndicationHistorySummaryModal="openIndicationHistorySummaryModal"
            :openMigrationSummaryModal="openMigrationSummaryModal"
            :openPatientPolicySummaryModal="openPatientPolicySummaryModal"
        />
    </div>
</template>

<script>
    import axios from 'axios';
    import ModalManager from '../../services/modalManager';
    import RecoveringInfluencingFactorsDashboard from '../Modals/RecoveringInfluencingFactorsDashboard';
    import HandlesErrorResponses from '../../mixins/HandlesErrorResponses';
    import HandlePremsRecordsNotifications from '../../mixins/Fysioroadmap/HandlePremsRecordsNotifications';

    export default {
        mixins: [
            HandlePremsRecordsNotifications,
            HandlesErrorResponses,
        ],

        props: {
            module: {
                type: String,
                required: true,
            },
            premsRecordsUrl: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                dossierStatus: {
                    data: [],
                    overallStatus: null,
                    overallStatusIcon: null,
                },
                medicalHistory: {
                    indication: {
                        operation: null,
                        trauma: null,
                        medication: null,
                    },
                    patient: [],
                },
            };
        },

        computed: {
            medicalHistoryBadge() {
                return this.medicalHistory.patient.some(medicalHistory => medicalHistory.relevant);
            },
        },

        created() {
            this.fetchDossierStatus();
            this.fetchMedicalHistory();
        },

        methods: {
            fetchDossierStatus() {
                axios.get(`/fysioroadmap/${this.$store.state.patientId}/${this.$store.state.indicationId}/dashboard/dossier-status/data`)
                    .then(response => {
                        this.dossierStatus.data = response.data.data;
                        this.dossierStatus.overallStatus = response.data.meta.overall_status;
                        this.dossierStatus.overallStatusIcon = response.data.meta.overall_status_icon;
                    });
            },

            openDossierStatusModal(url, type) {
                ModalManager.openModal(url, undefined, undefined, 'full')
                    .then(async(data) => {
                        // if end-evaluation and it was saved succesfully, show prems notitification when needed
                        if (type === 'end_evaluation' && data) {
                            await this.sendPremsRecords();
                        }

                        this.fetchDossierStatus();
                    }).catch(response => this.handleErrorResponse(response));
            },

            openRecoveringInfluencingFactorsModal(options) {
                ModalManager.openModal(RecoveringInfluencingFactorsDashboard, options, {}, 'medium');
            },

            fetchMedicalHistory() {
                axios.get(`/fysioroadmap/${this.$store.state.patientId}/${this.$store.state.indicationId}/anamnesis/registration/patient-medical-histories`)
                    .then(response => {
                        this.medicalHistory = response.data;
                    });
            },

            openMedicalHistoryModal() {
                ModalManager.openModal(`/patient/${this.$store.state.patientId}/medical_history?module=${this.module}`)
                    .then(() => {
                        this.fetchMedicalHistory();
                    });
            },

            openIndicationHistorySummaryModal() {
                ModalManager.openModal(`/fysioroadmap/${this.$store.state.patientId}/${this.$store.state.indicationId}/indication/summary`, undefined, {}, 'full');
            },

            openMigrationSummaryModal() {
                ModalManager.openModal(`/fysioroadmap/${this.$store.state.patientId}/${this.$store.state.indicationId}/migration/summary`, undefined, {}, 'full');
            },

            openPatientPolicySummaryModal() {
                ModalManager.openModal(`/patient/${this.$store.state.patientId}/policy/summary`, undefined, {}, 'full');
            },
        },
    };
</script>
