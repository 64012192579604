<template>
    <div :class="{'form-file-input--required': required, 'form-file-input--empty': !value}" class="form-file-input">
        <div v-if="loading" class="form-file-input__loading-container">
            <div class="spinner">
                <div class="spinner__dot" />
                <div class="spinner__dot" />
                <div class="spinner__dot" />
            </div>
        </div>
        <div v-else class="button-group">
            <a v-if="value" :href="attachmentUrl" class="form-file-input__download" target="_blank">{{ $t('form.file-input.download') }}</a>
            <input
                :id="inputId"
                :class="{'form-file-input__input--focus': inputFocused}"
                class="form-file-input__input"
                type="file"
                @blur="onBlur"
                @change="onChange"
                @focus="onFocus"
            >
            <label :for="inputId" class="form-file-input__label">
                {{ buttonLabel }}
            </label>
            <button
                v-if="value"
                :title="$t('form.file-input.delete')"
                class="form-file-input__reset"
                type="button"
                @click="reset"
            >
                {{ $t('form.file-input.delete') }}
            </button>
        </div>
        <input
            :id="id"
            :name="name"
            :required="required"
            :value="value"
            class="form-file-input__hidden"
            tabindex="-1"
            type="text"
        >
    </div>
</template>

<script>
    import DialogManager from '../../services/DialogManager';
    import axios from 'axios';

    let identifier = 1;

    export default {
        props: {
            category: {
                type: String,
                required: true,
            },

            id: {
                type: String,
                default: 'form_file_input_' + identifier++,
            },

            name: {
                type: String,
                default: null,
            },

            required: {
                type: Boolean,
                default: false,
            },

            value: {
                type: [String, Number],
                default: '',
            },
        },

        data() {
            return {
                inputFocused: false,
                loading: false,
            };
        },

        computed: {
            buttonLabel() {
                return this.value ? this.$t('form.file-input.change') : this.$t('form.file-input.add');
            },

            inputId() {
                return this.id + '_file_input';
            },

            attachmentUrl() {
                return this.value ? this.getAttachmentUrl(this.value) : null;
            },
        },

        watch: {
            loading(newValue) {
                this.$emit('loading', newValue);
            },
        },

        methods: {
            /**
             * @param {Event} e
             */
            onChange(e) {
                // Do we have a file?
                if (!e.target.files || !e.target.files[0]) {
                    this.$emit('input', '');
                    this.$emit('change', '');

                    return;
                }

                this.loading = true;

                const data = new FormData();
                data.append('category', this.category);
                data.append('file', e.target.files[0]);

                if (this.$options.previousSource) {
                    this.$options.previousSource.cancel();
                    this.$options.previousSource = null;
                }

                this.$options.previousSource = axios.CancelToken.source();

                return axios
                    .post('/assets/attachments', data, {
                        cancelToken: this.$options.previousSource.token,
                    })
                    .then(response => {
                        this.$emit('input', response.data.id);
                        this.$emit('change', response.data.id);
                    })
                    .catch(error => {
                        if (error.response) {
                            return DialogManager.alert(this.$t('form.file-input.upload-failed', { error: error.response.data.message }));
                        }

                        return DialogManager.alert(this.$t('form.file-input.upload-failed', { error: 'Unknown' }));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            onFocus() {
                this.inputFocused = true;
            },

            onBlur() {
                this.inputFocused = false;
            },

            reset() {
                this.$emit('input', '');
                this.$emit('change', '');
            },

            /**
             * @param {String|Number} id
             *
             * @returns {String}
             */
            getAttachmentUrl(id) {
                return '/assets/attachments/' + id;
            },
        },

        previousSource: null,
    };
</script>
