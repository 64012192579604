<template>
    <div class="dashboard">
        <slot
            :actionCount="actionCount"
            :actionOverviewConfig="actionOverviewConfigEnriched"
            :actionRecipientOverviewConfig="actionRecipientOverviewConfigEnriched"
            :actionTab="actionTab"
            :createAction="createAction"
            :createMessage="createMessage"
            :createZorgMailMessage="createZorgMailMessage"
            :messageCount="messageCount"
            :messageRecipientOverviewConfig="messageRecipientOverviewConfigEnriched"
            :receivedZorgMailMessageOverviewConfig="receivedZorgMailMessageOverviewConfigEnriched"
            :refreshCounters="refreshCounters"
            :sentZorgMailMessageOverviewConfig="sentZorgMailMessageOverviewConfigEnriched"
            :setActionTab="setActionTab"
            :setZorgMailTab="setZorgMailTab"
            :zorgMailTab="zorgMailTab"
        />
    </div>
</template>

<script>
    import Vue from 'vue';
    import Messaging from '../../mixins/Dashboards/Messaging';
    import ModalManager from '../../services/modalManager';

    export default {
        mixins: [Messaging],

        props: {
            receivedZorgMailMessageOverview: {
                type: Vue,
                default: null,
            },

            receivedZorgMailMessageOverviewConfig: {
                type: Object,
                default() {
                    return {};
                },
            },

            sentZorgMailMessageOverview: {
                type: Vue,
                default: null,
            },

            sentZorgMailMessageOverviewConfig: {
                type: Object,
                default() {
                    return {};
                },
            },
        },

        computed: {
            receivedZorgMailMessageOverviewConfigEnriched() {
                return {
                    ...this.receivedZorgMailMessageOverviewConfig,
                    height: this.getOverviewHeight,
                };
            },

            sentZorgMailMessageOverviewConfigEnriched() {
                return {
                    ...this.sentZorgMailMessageOverviewConfig,
                    height: this.getOverviewHeight,
                };
            },
        },

        methods: {
            createZorgMailMessage() {
                ModalManager.openModal('/dashboard/sent-zorgmail-messages/create')
                    .then(() => {
                        this.receivedZorgMailMessageOverview.refresh();
                    });
            },
        },
    };
</script>
