import AppointmentSubject from './AppointmentSubject';
import CalendarEmployeeSchedule from './CalendarEmployeeSchedule';
import CalendarItem from './CalendarItem';
import CalendarItemParticipation from './CalendarItemParticipation';
import CalendarItemWithPerformanceCode from './CalendarItemWithPerformanceCode';
import CalendarItemWithPerformanceCodeParticipation from './CalendarItemWithPerformanceCodeParticipation';
import CalendarItemWithPerformanceCodeResource from './CalendarItemWithPerformanceCodeResource';
import CalendarItemWithService from './CalendarItemWithService';
import CalendarItemWithServiceParticipation from './CalendarItemWithServiceParticipation';
import CalendarItemWithServiceResource from './CalendarItemWithServiceResource';
import CalendarItemWithoutPatient from './CalendarItemWithoutPatient';
import CalendarItemWithoutPatientResource from './CalendarItemWithoutPatientResource';
import CalendarLocationDeviceSchedule from './CalendarLocationDeviceSchedule';
import CalendarLocationRoomSchedule from './CalendarLocationRoomSchedule';
import Employee from './Employee';
import EmployeeGroup from './EmployeeGroup';
import IndicationHistory from './IndicationHistory';
import IndicationHistoryOverviewDto from './IndicationHistoryOverviewDto';
import Institute from './Institute';
import Location from './Location';
import LocationDevice from './LocationDevice';
import LocationRoom from './LocationRoom';
import PerformanceCode from './PerformanceCode';
import PerformanceCodeAlias from './PerformanceCodeAlias';
import PremsRecord from './PremsRecord';
import Rate from './Rate';
import Service from './Service';
import Patient from './Patient';
import RevenueOverviewResource from './RevenueOverviewResource';
import TreatmentParticipationPaymentStatusResource from './TreatmentParticipationPaymentStatusResource';
import ServiceParticipationPaymentStatusResource from './ServiceParticipationPaymentStatusResource';
import CalendarItemWithPerformanceCodeParticipationResource
    from './CalendarItemWithPerformanceCodeParticipationResource';
import CalendarItemWithServiceParticipationResource from './CalendarItemWithServiceParticipationResource';
import CalendarItemWithSubscription from './CalendarItemWithSubscription';
import CalendarItemWithSubscriptionParticipation from './CalendarItemWithSubscriptionParticipation';
import CalendarItemWithSubscriptionResource from './CalendarItemWithSubscriptionResource';
import SubscriptionItemData from './SubscriptionItemData';
import CalendarItemWithSubscriptionParticipationResource from './CalendarItemWithSubscriptionParticipationResource';
import SubscriptionParticipationPaymentStatusResource from './SubscriptionParticipationPaymentStatusResource';
import SubscriptionUsage from './SubscriptionUsage';
import RemunerationOverviewResource from './RemunerationOverviewResource';
import CalendarNote from './CalendarNote';

export default [
    AppointmentSubject,
    CalendarEmployeeSchedule,
    CalendarItem,
    CalendarItemParticipation,
    CalendarItemWithPerformanceCode,
    CalendarItemWithPerformanceCodeParticipation,
    CalendarItemWithPerformanceCodeParticipationResource,
    CalendarItemWithPerformanceCodeResource,
    CalendarItemWithService,
    CalendarItemWithServiceParticipation,
    CalendarItemWithServiceParticipationResource,
    CalendarItemWithServiceResource,
    CalendarItemWithSubscription,
    CalendarItemWithSubscriptionParticipation,
    CalendarItemWithSubscriptionParticipationResource,
    CalendarItemWithSubscriptionResource,
    CalendarItemWithoutPatient,
    CalendarItemWithoutPatientResource,
    CalendarLocationDeviceSchedule,
    CalendarLocationRoomSchedule,
    CalendarNote,
    Employee,
    EmployeeGroup,
    IndicationHistory,
    IndicationHistoryOverviewDto,
    Institute,
    Location,
    LocationDevice,
    LocationRoom,
    Patient,
    PerformanceCode,
    PerformanceCodeAlias,
    PremsRecord,
    Rate,
    RemunerationOverviewResource,
    RevenueOverviewResource,
    Service,
    ServiceParticipationPaymentStatusResource,
    SubscriptionItemData,
    SubscriptionParticipationPaymentStatusResource,
    SubscriptionUsage,
    TreatmentParticipationPaymentStatusResource,
];
