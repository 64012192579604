<script>
    import InlineChildOverview from '../InlineChildOverview.vue';

    export default {
        extends: InlineChildOverview,

        props: {
            formData: {
                type: Object,
                required: true,
            },
        },

        methods: {
            parentChange(accessRight, item) {
                if (item.children.data.length < 1) {
                    return false;
                }

                const parentStatus = accessRight.enabled;

                item.children.data.forEach((child) => {
                    const childId = child.id;

                    this.formData.access_rights.forEach((accessRight) => {
                        if (accessRight.id === childId) {
                            accessRight.enabled = parentStatus;
                        }
                    });
                });
            },

            parentStatus(accessRight, item) {
                if (item.children.data.length > 0) {
                    const childrenIds = item.children.data.map(child => child.id);
                    const enabledCounter = this.formData.access_rights
                        .filter(accessRight => {
                            return childrenIds.indexOf(accessRight.id) >= 0 && accessRight.enabled;
                        }).length;

                    if (enabledCounter === item.children.data.length) {
                        accessRight.enabled = true;
                        return 'checked';
                    } else if (enabledCounter === 0) {
                        accessRight.enabled = false;
                        return 'unchecked';
                    } else {
                        accessRight.enabled = true;
                        return 'indeterminate';
                    }
                }

                return accessRight.enabled ? 'checked' : 'unchecked';
            },
        },

        previousSource: null,
    };
</script>
