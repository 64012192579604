<script>
import Form from '../Form.vue';

export default {
    extends: Form,

    computed: {
        vatFraction() {
            return this.formData.enforced_amount.vat_promille / 1000;
        },
    },

    created() {
        // enforced_amount[incl_vat]
        this.$watch('formData.enforced_amount.incl_vat', () => {
            this.fillFields();
        });
    },

    methods: {
        fillFields() {
            const amountInclVat = this.formData.enforced_amount.incl_vat;
            let amountExclVat = null;

            // Set enforced_amount[excl_vat]
            if (!amountExclVat && amountInclVat) {
                amountExclVat = Math.round(amountInclVat / (1 + this.vatFraction));
                this.$set(this.formData.enforced_amount, 'excl_vat', amountExclVat);
            }

            if (amountExclVat && amountInclVat) {
                this.$set(this.formData.enforced_amount, 'vat', amountInclVat - amountExclVat);
            }
        },
    },
};
</script>
