<script>
    import Default from './Default.vue';

    const TYPE_MANUAL = 'manual';
    const TYPE_PHOTO = 'photo';

    export default {
        extends: Default,

        data() {
            return {
                type: null,
                fileInputLoading: false,
                image: '',
                imageWidth: 0,
                imageHeight: 0,
                angle: 0,
            };
        },

        computed: {
            manual() {
                return this.type === TYPE_MANUAL;
            },

            photo() {
                return this.type === TYPE_PHOTO;
            },

            ap() {
                return this.formData.clinimetrics_item_data[0].value_as_double;
            },

            sd() {
                return this.formData.clinimetrics_item_data[1].value_as_double;
            },

            as() {
                return this.formData.clinimetrics_item_data[2].value_as_double;
            },

            ad() {
                return this.formData.clinimetrics_item_data[3].value_as_double;
            },

            ps() {
                return this.formData.clinimetrics_item_data[4].value_as_double;
            },

            pd() {
                return this.formData.clinimetrics_item_data[5].value_as_double;
            },

            ed() {
                return this.formData.clinimetrics_item_data[6].value_as_double;
            },

            odl() {
                return this.formData.clinimetrics_item_data[7].value_as_double;
            },

            odr() {
                return this.formData.clinimetrics_item_data[8].value_as_double;
            },

            asad() {
                if (this.as === null || this.ad === null) {
                    return '-';
                }

                return this.round(this.as - this.ad);
            },

            pdps() {
                if (this.pd === null || this.ps === null) {
                    return '-';
                }

                return this.round(this.pd - this.ps);
            },

            odd() {
                if (this.odl === null || this.odr === null) {
                    return '-';
                }

                return this.round(this.odl - this.odr);
            },

            oddi() {
                if (this.odl === null || this.odr === null) {
                    return '-';
                }

                return this.round(Math.max(this.odl, this.odr) / Math.min(this.odl, this.odr) * 100);
            },

            cpi() {
                if (this.sd === null || this.ap === null) {
                    return '-';
                }

                return this.round(this.sd / this.ap * 100);
            },

            edi() {
                if (this.ed === null || this.ap === null) {
                    return '-';
                }

                return this.round(this.ed / this.ap * 100);
            },
        },

        created() {
            if (!String(this.formData.clinimetrics_item_data[0].id).startsWith('new_')) {
                if (this.formData.clinimetrics_item_data[10].attachment) {
                    this.loadImage();
                } else {
                    this.setManual();
                }
            }

            this.$options.originalData = JSON.parse(JSON.stringify(this.formData));
        },

        mounted() {
            this.$refs.form.addEventListener('submit', event => {
                if (this.manual) {
                    return;
                }

                event.preventDefault();

                this.$refs.inputHidden.getImage()
                    .then(image => {
                        this.formData.clinimetrics_item_data[31].attachment_raw = image;

                        this.$nextTick(() => {
                            this.$refs.form.submit();
                        });
                    });
            });
        },

        methods: {
            setManual() {
                this.type = TYPE_MANUAL;
            },

            setPhoto() {
                this.type = TYPE_PHOTO;
            },

            setFileInputLoading(loading) {
                this.fileInputLoading = loading;
            },

            /**
             * @param {Number} value
             *
             * @returns {Number}
             */
            round(value) {
                return Math.round(value * 10) / 10;
            },

            reset() {
                [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].forEach(index => {
                    this.formData.clinimetrics_item_data[index].value_as_double = this.$options.originalData.clinimetrics_item_data[index].value_as_double;
                });

                // Let the input do it's reactivity magic and call reset in the next tick
                this.$nextTick(() => {
                    this.$refs.input.reset();
                });
            },

            loadImage() {
                this.setFileInputLoading(true);

                if (this.$options.canvas === null) {
                    this.$options.canvas = document.createElement('canvas');
                }

                if (this.$options.image === null) {
                    this.$options.image = new Image();
                    this.$options.image.onload = () => {
                        this.drawImage();

                        this.setFileInputLoading(false);
                        this.setPhoto();
                    };
                }

                // N.B. Make sure to always fetch a fresh image!
                this.$options.image.src = `/assets/attachments/${this.formData.clinimetrics_item_data[10].attachment}?v=${(new Date()).getTime()}`;
            },

            drawImage() {
                const image = this.$options.image;
                const canvas = this.$options.canvas;
                const context = canvas.getContext('2d');

                context.save();

                let width = image.naturalWidth;
                let height = image.naturalHeight;

                if (this.angle === 90 || this.angle === 270) {
                    // noinspection JSSuspiciousNameCombination
                    width = image.naturalHeight;
                    // noinspection JSSuspiciousNameCombination
                    height = image.naturalWidth;
                }

                canvas.width = width;
                canvas.height = height;

                context.translate(width / 2, height / 2);

                context.rotate(this.angle / 180 * Math.PI);

                context.drawImage(image, image.naturalWidth / -2, image.naturalHeight / -2);

                context.restore();

                this.imageWidth = width;
                this.imageHeight = height;
                this.image = canvas.toDataURL('image/jpeg');
            },

            rotate() {
                this.angle = (this.angle + 90) % 360;

                this.drawImage();
            },
        },

        originalData: null,

        image: null,

        canvas: null,
    };
</script>
