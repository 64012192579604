import Vue from 'vue';
import i18n from '../i18n';
import Shortcut from './shortcut';
import Modal from '../components/Modal.vue';

let manager;

if (window.parent.vm && window.parent.vm.ModalManager) {
    manager = window.parent.vm.ModalManager;
} else {
    manager = new Vue({ i18n, ...Modal }).$mount();

    document.body.appendChild(manager.$el);
}

Shortcut.$on('modal.close', () => manager.closeModalFromShortcut());

setTimeout(() => manager.recalculateModalHeight());

export default manager;
