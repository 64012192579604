<template>
    <span
        :aria-label="$t('patient.deceased_at_tooltip', {date: date})"
        class="deceased-icon hint--bottom"
    >
        <span class="deceased-icon__inner" />
    </span>
</template>

<script>
    export default {
        props: {
            date: {
                type: String,
                required: true,
            },
        },
    };
</script>
