// @vue/component
export default {
    props: {
        markInitialText: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        startValueHasChanged() {
            return this.$options.startValue !== this.value;
        },

        isInitial() {
            return this.markInitialText && !this.startValueHasChanged;
        },
    },

    created() {
        this.$options.startValue = this.value;
    },

    startValue: null,
};
