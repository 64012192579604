<script>
import HandlesInstitute from '../mixins/HandlesInstitute';

/**
 * Redirects to the provided URL with the preferred institute ID replaced.
 */
export default {
    mixins: [
        HandlesInstitute,
    ],

    props: {
        url: {
            type: String,
            required: true,
        },
    },

    render() {
        return null;
    },

    mounted() {
        window.location.assign(this.url.replace(':institute_id', this.instituteId));
    },
};
</script>
