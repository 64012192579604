<script>
    import Form from '../Form.vue';

    const TARGET_AMOUNT_EXCL_VAT = 'amount[excl_vat]';
    const TARGET_AMOUNT_INCL_VAT = 'amount[incl_vat]';
    const TARGET_VAT = 'vat';

    export default {
        extends: Form,

        props: {
            vats: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                focussedTarget: null,
                lastManuallyChangedTarget: TARGET_AMOUNT_EXCL_VAT,
            };
        },

        computed: {
            vat() {
                return this.vats.find((vat) => {
                    return vat.id === this.formData.vat;
                });
            },

            vatFraction() {
                return this.vat.attributes.promille / 1000;
            },
        },

        created() {
            // amount[excl_vat]
            this.$watch('formData.amount.excl_vat', () => {
                this.fillFields(TARGET_AMOUNT_EXCL_VAT);
            });
            // amount[incl_vat]
            this.$watch('formData.amount.incl_vat', () => {
                this.fillFields(TARGET_AMOUNT_INCL_VAT);
            });
            // vat
            this.$watch('formData.vat', () => {
                this.fillFields(TARGET_VAT);
            });
        },

        methods: {
            fieldFocussed(target) {
                this.focussedTarget = target;
            },

            fieldBlurred(target) {
                if (this.focussedTarget === target) {
                    this.focussedTarget = null;
                }
            },

            fillFields(target) {
                let amountExclVat = this.formData.amount.excl_vat;
                let amountInclVat = this.formData.amount.incl_vat;

                if (target === this.focussedTarget) {
                    this.lastManuallyChangedTarget = target;
                }

                // Set amounts if the user has selected a vat rate
                if (this.formData.vat) {
                    // Clear the other value based on the (last manually changed) target
                    if ((target === TARGET_AMOUNT_EXCL_VAT || target === TARGET_VAT) && this.lastManuallyChangedTarget === TARGET_AMOUNT_EXCL_VAT) {
                        amountInclVat = null;
                    } else if ((target === TARGET_AMOUNT_INCL_VAT || target === TARGET_VAT) && this.lastManuallyChangedTarget === TARGET_AMOUNT_INCL_VAT) {
                        amountExclVat = null;
                    }

                    // Set amount[excl_vat]
                    if (!amountExclVat && target !== TARGET_AMOUNT_EXCL_VAT && amountInclVat) {
                        amountExclVat = Math.round(amountInclVat / (1 + this.vatFraction));
                        this.$set(this.formData.amount, 'excl_vat', amountExclVat);
                    }

                    // Set amount[incl_vat]
                    if (!amountInclVat && target !== TARGET_AMOUNT_INCL_VAT && amountExclVat) {
                        amountInclVat = Math.round(amountExclVat * (1 + this.vatFraction));
                        this.$set(this.formData.amount, 'incl_vat', amountInclVat);
                    }
                }

                // Set amount[vat]
                if (amountExclVat && amountInclVat) {
                    this.$set(this.formData.amount, 'vat', amountInclVat - amountExclVat);
                } else {
                    this.$set(this.formData.amount, 'vat', null);
                }
            },
        },
    };
</script>
