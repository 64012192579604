<template>
    <component :is="tag" v-text="text" />
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },

        tag: {
            type: String,
            default: 'span',
        },
    },

    data() {
        return {
            time: (new Date()).getTime(),
        };
    },

    computed: {
        text() {
            const date = new Date(this.time);

            if (date.getHours() < 6) {
                return this.$t('base.greeting.night', { name: this.name });
            }

            if (date.getHours() < 12) {
                return this.$t('base.greeting.morning', { name: this.name });
            }

            if (date.getHours() < 18) {
                return this.$t('base.greeting.afternoon', { name: this.name });
            }

            return this.$t('base.greeting.evening', { name: this.name });
        },
    },

    created() {
        setInterval(() => {
            this.time = (new Date()).getTime();
        }, 60 * 1000);
    },
};
</script>
