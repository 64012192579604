export default {
    name: 'calendar_note',
    definition: {
        description: '',
        date: '',
        employees: '',
    },
    options: {
        collectionPath: 'calendar_notes',
    },
};
