<template>
    <div>
        <div v-if="loading" class="loading" data-testid="return-information:loading">
            <div class="alert alert--info">
                <div class="alert__content">
                    <p>
                        <frm-spinner class="spinner" />
                        {{ $t('financial.messages.return-information-loading') }}
                    </p>
                </div>
            </div>
        </div>

        <frm-overview v-if="!loading" ref="overview" :config="overviewConfig">
            <template v-if="countInvoicesWhichNeedAction" #header>
                <div class="alert alert--warning" data-testid="return-information:invoices-need-action-alert">
                    <frm-icon class="alert__icon" name="exclamation-mark" />
                    <div class="alert__content">
                        <a href="#" @click.prevent="showInvoicedWhichNeedAction()">
                            {{ $tc('financial.messages.invoices-need-action', countInvoicesWhichNeedAction) }}
                        </a>
                    </div>
                </div>
            </template>
        </frm-overview>
    </div>
</template>

<script>

import axios from 'axios';
import HandlesErrorResponses from '../mixins/HandlesErrorResponses';
import HandlesInstitute from '../mixins/HandlesInstitute';

export default {
    mixins: [HandlesErrorResponses, HandlesInstitute],
    props: {
        overviewConfig: {
            type: Object,
            required: true,
        },
        countInvoicesWhichNeedActionUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            countInvoicesWhichNeedAction: 0,
        };
    },
    mounted() {
        this.fetchInvoiceCountWhichNeedAction();
    },
    watch: {
        instituteId() {
            this.fetchInvoiceCountWhichNeedAction();
        },
    },
    methods: {
        async fetchInvoiceCountWhichNeedAction() {
            try {
                this.countInvoicesWhichNeedAction = (await axios.get(this.countInvoicesWhichNeedActionUrl, {
                    params: {
                        institute_id: this.instituteId,
                    },
                })).data.count;
            } catch (err) {
                await this.handleErrorResponse(err);
            } finally {
                this.loading = false;
            }
        },
        showInvoicedWhichNeedAction() {
            this.$refs.overview.setFilters({
                institute_id: this.instituteId,
                date: [],
                status: 'declined,partially_declined,approved_credit_to_insurer',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.loading {
    display: flex;
    align-items: center;
    width: 520px;
    margin: 160px auto 0;
}

.spinner {
    margin-right: rhythm(2);
}
</style>
