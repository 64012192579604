import { mapActions as vuexMapActions, mapMutations as vuexMapMutations, mapState as vuexMapState } from 'vuex';
import createPersistedStatePlugin from 'vuex-persistedstate';
import serialize from 'devour-client/lib/middleware/json-api/_serialize';
import jsonApi from '../json-api-client';
import deserialize from 'devour-client/lib/middleware/json-api/_deserialize';
import * as shvl from 'shvl';

/**
 * These helpers can optionally be used with a namespace. The namespace is assumed to be available on 'this.storeNamespace'.
 */

/**
 * @param {[]|{}} map
 *
 * @return {{}}
 */
export const mapActions = (map) => {
    return vuexMapActions(normalizeMap(map).reduce((acc, { key, val }) => {
        acc[key] = function (dispatch, payload) {
            return dispatch((this.storeNamespace || []).concat([val]).join('/'), payload);
        };

        return acc;
    }, {}));
};

/**
 * @param {[]|{}} map
 *
 * @return {{}}
 */
export const mapGetters = (map) => {
    return vuexMapState(normalizeMap(map).reduce((acc, { key, val }) => {
        acc[key] = function (state, getters) {
            return getters[(this.storeNamespace || []).concat([val]).join('/')];
        };

        return acc;
    }, {}));
};

/**
 * @param {[]|{}} map
 *
 * @return {{}}
 */
export const mapMutations = (map) => {
    return vuexMapMutations(normalizeMap(map).reduce((acc, { key, val }) => {
        acc[key] = function (commit, payload) {
            return commit((this.storeNamespace || []).concat([val]).join('/'), payload);
        };

        return acc;
    }, {}));
};

/**
 * @param {[]|{}} map
 *
 * @return {{}}
 */
export const mapState = (map) => {
    return vuexMapState(normalizeMap(map).reduce((acc, { key, val }) => {
        acc[key] = function (state) {
            return (this.storeNamespace || []).concat([val]).reduce((state, namespace) => state ? state[namespace] : undefined, state);
        };

        return acc;
    }, {}));
};

/**
 * N.B. Copied from vuex since we want to have the same behaviour
 *
 * @param {[]|{}} map
 *
 * @return {[]}
 */
function normalizeMap(map) {
    return Array.isArray(map)
        ? map.map((key) => ({ key: key, val: key }))
        : Object.keys(map).map((key) => ({ key: key, val: map[key] }));
}

const STORAGE = localStorage;

/**
 * @param {Object} options
 *
 * @returns {function(store: any): void}
 */
export function createPersistedState(options) {
    if (options.ttl) {
        const ttl = options.ttl;
        delete options.ttl;
        const jsonApiPaths = options.jsonApiPaths || {};
        delete options.jsonApiPaths;

        options = {
            ...options,
            getState(key, storage) {
                let value;
                try {
                    value = JSON.parse(storage.getItem(key));
                } catch (err) {
                    return undefined;
                }

                if (!value || (value._expires && value._expires < (new Date()).getTime())) {
                    return undefined;
                }

                delete value._expires;

                Object.keys(jsonApiPaths).forEach(path => {
                    const entry = shvl.get(value, path);

                    if (entry) {
                        shvl.set(value, path, {
                            data: deserialize.collection.call(jsonApi, entry.data, entry.included || []),
                            included: entry.included || [],
                        });
                    }
                });

                return value;
            },
            setState(key, state, storage) {
                const mutatedState = { ...state };

                Object.entries(jsonApiPaths).forEach(([path, model]) => {
                    const item = shvl.get(state, path);

                    if (item) {
                        shvl.set(mutatedState, path, {
                            data: serialize.collection.call(jsonApi, model, item.data),
                            included: item.included || [],
                        });
                    }
                });

                return storage.setItem(key, JSON.stringify({ ...mutatedState, _expires: (new Date()).getTime() + ttl }));
            },
        };
    }
    options.storage = STORAGE;

    return createPersistedStatePlugin(options);
}

export const stateFromJsonApiResponse = (response) => {
    return {
        data: response.data,
        included: response.document.included || [],
    };
};

// Clear expired keys from storage using a lottery
if (Math.floor(Math.random() * 50) === 0) {
    Object.keys(STORAGE).forEach(key => {
        if (!key.startsWith('vuex')) {
            return;
        }

        let value;
        try {
            value = JSON.parse(STORAGE.getItem(key));
        } catch (err) {
            return;
        }

        if (!value || !value._expires) {
            return;
        }

        if (value._expires < (new Date()).getTime()) {
            STORAGE.removeItem(key);
        }
    });
}
