<script>
    import Sortable from 'sortablejs';
    import DialogManager from '../services/DialogManager';
    import i18n from '../i18n';
    import axios from 'axios';

    export default {
        model: {
            prop: 'items',
            event: 'change',
        },

        props: {
            items: {
                type: Array,
                required: true,
            },

            sortableField: {
                type: String,
                default: '',
            },

            preprendNewToIdOnCreate: {
                type: Boolean,
                default: false,
            },

            groupable: {
                type: Boolean,
                default: false,
            },

            resettable: {
                type: Boolean,
                default: false,
            },

            resetButtonText: {
                type: String,
                default: i18n.t('overview.actions.reset'),
            },

            resetDataUri: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                loading: true,
            };
        },

        computed: {
            sortable() {
                return this.sortableField !== '';
            },

            addDisabled() {
                return false;
            },
        },

        mounted() {
            this.loading = false;

            if (this.sortable) {
                this.sortableInstance = Sortable.create(this.$el.querySelector('tbody'), {
                    filter: '.js-table-spinner',
                    handle: '.js-reorder-handle',
                    onEnd: (event) => {
                        const items = this.items;
                        const index = event.item.querySelector('.js-reorder-item-index').value;
                        items[index][this.sortableField] = event.newIndex + 0.5;
                        this.$emit('change', items);
                        this.$nextTick(() => {
                            this.reorder();
                        });
                    },
                    onMove: () => {
                        requestAnimationFrame(() => {
                            let counter = 1;

                            this.$el.querySelectorAll('tbody tr:not(.js-table-spinner)').forEach((item) => {
                                if (item.style.display === 'none') {
                                    return;
                                }

                                item.classList.remove('odd', 'even');
                                item.classList.add(counter % 2 === 1 ? 'odd' : 'even');
                                counter++;
                            });
                        });
                    },
                });
            }
        },

        methods: {
            update() {
                this.$emit('change', this.items);
            },

            addNewItem() {
                this.$emit('change', [...this.items, this.createNewItem()]);
                this.$nextTick(() => {
                    const lastRow = this.$el.querySelector('table tbody tr:last-of-type');
                    const inputs = lastRow.querySelectorAll('input:not([type="hidden"]), select, textarea');

                    if (inputs.length) {
                        inputs[0].focus();
                    }
                });
            },

            createNewItem() {
                const newItem = {};
                if (this.sortable) {
                    newItem[this.sortableField] = this.items.length + 1;
                }
                if (this.preprendNewToIdOnCreate) {
                    newItem.id = 'new_' + (this.items.length + 1);
                }
                return newItem;
            },

            reorder() {
                const rows = this.$el.querySelectorAll('tbody tr:not(.js-table-spinner)');
                let counter = 1;
                const items = this.items;
                rows.forEach((item) => {
                    item.classList.remove('odd', 'even');
                    item.classList.add(counter % 2 === 1 ? 'odd' : 'even');
                    const index = item.querySelector('.js-reorder-item-index').value;
                    items[index][this.sortableField] = counter;
                    counter++;
                });
                this.$emit('change', items);
            },

            removeItem(index, event) {
                this.$emit('change', this.items.slice(0, index).concat(this.items.slice(index + 1)));
                if (this.sortable) {
                    this.$nextTick(() => {
                        this.reorder();
                    });
                }
                event.currentTarget.blur();
            },

            reset() {
                if (this.resetDataUri) {
                    this.loading = true;

                    if (this.$options.previousSource) {
                        this.$options.previousSource.cancel();
                        this.$options.previousSource = null;
                    }

                    this.$options.previousSource = axios.CancelToken.source();

                    return axios
                        .get(this.resetDataUri, {
                            cancelToken: this.$options.previousSource.token,
                        })
                        .then(response => {
                            this.$emit('change', response.data.data);
                            this.loading = false;
                        })
                        .catch(error => {
                            // We can ignore cancelled requests
                            if (axios.isCancel(error)) {
                                return;
                            }

                            this.loading = false;

                            if (error.response) {
                                return DialogManager.alert(this.$t('overview.reset-failed', { error: error.response.data.message }));
                            }

                            return DialogManager.alert(this.$t('overview.reset-failed', { error: 'Unknown' }));
                        });
                } else {
                    this.$emit('change', []);
                }
            },
        },

        previousSource: null,
    };
</script>
