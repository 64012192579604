<script>
    import ModalManager from '../../services/modalManager';
    import PatchSiblings from '../Modals/PatchSiblings';
    import Form from '../Form.vue';

    const placeholderRegex = /{{.*?}}/gi;

    export default {
        extends: Form,

        props: {
            templateOutputEmail: {
                type: String,
                required: true,
            },

            templateOutputWebpage: {
                type: String,
                required: true,
            },
        },

        computed: {
            templateTypeDisabled() {
                return placeholderRegex.test(this.formData.body_as_html) ||
                    placeholderRegex.test(this.formData.header_as_html) ||
                    placeholderRegex.test(this.formData.footer_as_html) ||
                    (this.formData.template_output === this.templateOutputEmail && placeholderRegex.test(this.formData.email_subject_as_html));
            },
        },

        methods: {
            openPatchSiblingsModal(tab) {
                this.formData.tab = tab;

                ModalManager.openModal(PatchSiblings, {}, {
                    save: (data) => {
                        this.formData.patch_siblings_too = true;
                        this.formData.only_equal_output_siblings = data.only_equal_output_siblings;
                        this.formData.only_equal_institute_siblings = data.only_equal_institute_siblings;

                        this.$nextTick(() => {
                            // Unfortunately we must trigger a click as submitting the form doesn't trigger validation...
                            // I.e. `this.$refs.form.submit();`
                            this.$refs.form.querySelector('button[type="submit"]').click();
                        });
                    },
                }, 'medium');
            },
        },
    };
</script>
