<template>
    <frm-modal-component ref="modal" @save="save">
        <template #header>
            {{ header }}
        </template>

        <div v-for="(field, index) in fields" :key="index" class="form-group-vertical">
            <div class="form-group-vertical__heading">
                <label :for="'frm_input_parent_info_modal_'+ index" class="form-group-vertical__label">
                    {{ field.label }}
                </label>
                <!--Optionally show button-->
                <button v-if="field.textFromPreviousIndication" :id="'frm_button_parent_info_modal_'+ index" class="button button--narrow button--low button--secondary" @click="addTextFromPreviousIndication(field)">
                    <span>{{ $t('fysioroadmap.anamnesis.buttons.copy_from_previous_indication') }}</span>
                </button>
            </div>
            <div class="form-group-vertical__field">
                <frm-input-smart-ajax-textarea :id="'frm_input_parent_info_modal_'+ index" v-model="field.value" :placeholders-url="field.placeholdersUrl" :texts-url="field.textsUrl" />
            </div>
        </div>
        <div class="form-group-vertical">
            <div class="form-group-vertical__field">
                <div class="form-checkbox">
                    <input id="frm_input_rif_modal_show_on_main_screen" v-model="showOnMainScreen.value" class="form-checkbox__input" type="checkbox">
                    <label for="frm_input_rif_modal_show_on_main_screen" class="form-checkbox__label">{{ showOnMainScreen.label }}</label>
                </div>
            </div>
        </div>
    </frm-modal-component>
</template>

<script>
    export default {
        props: {
            header: {
                type: String,
                default: '',
            },

            fields: {
                type: Object,
                required: true,
            },

            showOnMainScreen: {
                type: Object,
                required: true,
            },
        },

        methods: {
            save() {
                const data = {
                    fields: {
                        'external-factors': this.fields['external-factors'],
                        'personal-factors': this.fields['personal-factors'],
                    },
                    showOnMainScreen: this.showOnMainScreen,
                };

                this.$emit('set-parent-info-fields', data);
                this.$refs.modal.closeModal();
            },

            addTextFromPreviousIndication(field) {
                if (field.value !== null && field.value.length > 0) {
                    field.value = field.value + '\n' + field.textFromPreviousIndication;
                } else {
                    field.value = field.textFromPreviousIndication;
                }
            },
        },
    };
</script>
