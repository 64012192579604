<script>
    import ModalManager from '../services/modalManager';

    export default {
        computed: {
            submitButtonEnabled() {
                return !this.$store.state.core.requestPoolPending;
            },

            submitButtonLoading() {
                return false;
            },
        },

        methods: {
            save() {
                ModalManager.closeModal(this.getAttributesFromItems(this.$refs.overview.items));
            },

            getAttributesFromItems(items) {
                const attributes = {};

                items.forEach((item) => {
                    if (item.field_name.includes('[') && item.field_name.endsWith(']')) {
                        // Please note, this logic doesn't work recursive and only one level deep!
                        const [object, field] = item.field_name.slice(0, -1).split('[', 2);
                        attributes[object] = attributes[object] || {};
                        attributes[object][field] = item.new_value;
                    } else {
                        attributes[item.field_name] = item.new_value;
                    }
                });

                return attributes;
            },
        },
    };
</script>
