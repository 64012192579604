<template>
    <div class="patient-card">
        <figure class="patient-card__avatar">
            <!--suppress HtmlUnknownTarget -->
            <img v-if="photo" :alt="name" :src="`/assets/attachments/thumbnails/${photo}`" class="patient-card__photo">
            <frm-icon v-else name="patient" class="patient-card__icon" />
        </figure>
        <div class="patient-card__content">
            <h3 class="patient-card__name">
                {{ name }} <span v-if="gender">({{ $t(`patient.values.gender-denotation-${gender}`) }})</span>
            </h3>
            <dl class="patient-card__details">
                <dt v-if="birthdate" class="patient-card__detail-title" v-text="$t('patient.fields.birthdate')" />
                <dd v-if="birthdate" class="patient-card__detail-description">
                    {{ $ds(birthdate, 'date') }}
                    <frm-deceased-icon v-if="deceasedDate" :date="$ds(deceasedDate, 'date')" />
                </dd>
                <dt v-if="identificationNumber" class="patient-card__detail-title" v-text="$t('patient.fields.identification_number')" />
                <dd v-if="identificationNumber" class="patient-card__detail-description" v-text="identificationNumber" />
            </dl>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: true,
            },

            birthdate: {
                type: String,
                default: null,
            },

            deceasedDate: {
                type: String,
                default: null,
            },

            gender: {
                type: String,
                default: null,
            },

            identificationNumber: {
                type: String,
                default: null,
            },

            photo: {
                type: [String, Number],
                default: null,
            },
        },
    };
</script>
