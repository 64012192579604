<script>
    import ModalManager from '../services/modalManager';

    export default {
        props: {
            url: {
                type: String,
                required: true,
            },
            size: {
                type: String,
                default: 'default',
            },
        },

        render() {
            return null;
        },

        mounted() {
            ModalManager.openModal(this.url, undefined, undefined, this.size);
        },
    };
</script>
