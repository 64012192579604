export default {
    datetime: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    },

    date: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    },

    monthLong: {
        month: 'long',
    },

    time: {
        hour: '2-digit',
        minute: '2-digit',
    },
};
