<script>
import Form from '../Form.vue';
import HandlesZorgmailAccount from '../../mixins/HandlesZorgmailAccount';

export default {
    extends: Form,

    mixins: [HandlesZorgmailAccount],

    mounted() {
        this.formData.zorgmail_account_id = this.zorgmailAccountId;
    },

    watch: {
        'formData.zorgmail_account_id'(value) {
            this.zorgmailAccountId = value;
        },
    },
};
</script>
