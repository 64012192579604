<template>
    <transition name="modal-animation" @before-enter="beforeEnter" @after-leave="afterLeave">
        <div v-show="modals.length > 0" :class="baseClassName">
            <div :class="baseClassName + '__backdrop'" :style="{ 'z-index': modals.length }" />
            <transition-group name="modal-wrapper-animation" tag="div">
                <div v-for="(modal, index) in modals" :key="modal.id" :class="baseClassName + '__wrapper'" :style="{ 'z-index': index + 1 }">
                    <div :class="[baseClassName + '__container', baseClassName + '__container--' + modal.size, {[baseClassName + '__container--iframe']: modal.url}]">
                        <button v-if="modal.closeable" :class="baseClassName + '__close-button'" @click="closeModal()" />
                        <div v-if="modal.url" :class="baseClassName + '__spinner'">
                            <div class="spinner">
                                <div class="spinner__dot" />
                                <div class="spinner__dot" />
                                <div class="spinner__dot" />
                            </div>
                        </div>
                        <iframe
                            v-if="modal.url"
                            :id="'modal-iframe-' + index"
                            ref="iframe"
                            :class="baseClassName + '__iframe'"
                            :src="modal.url"
                            @load="modal.print ? $event.target.contentWindow.print() : undefined"
                        />
                        <component
                            :is="modal.component"
                            v-if="modal.component"
                            :id="'modal-component-' + index"
                            :class="baseClassName + '__component'"
                            v-bind="modal.componentOptions"
                            v-on="modal.componentListeners"
                        />
                        <div v-if="modal.vnodes"
                             :id="'modal-vnodes-' + index"
                             :class="baseClassName + '__vnodes'"
                        >
                            <VNodes :vnodes="modal.vnodes" />
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
    </transition>
</template>

<script>
    import updateQueryStringParameter from '../services/updateQueryStringParameter';

    function getRandomIdentifier() {
        return Math.random().toString(36).substring(8);
    }

    export default {
        props: {
            dialog: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                modals: [],
            };
        },

        computed: {
            baseClassName() {
                return this.dialog ? 'dialog' : 'modal';
            },
        },

        components: {
            VNodes: {
                functional: true,
                render: (h, ctx) => ctx.props.vnodes,
            },
        },

        methods: {
            /**
             * @param {String|Object} src
             * @param {Object} [options]
             * @param {Object} [listeners]
             * @param {String} [size]
             * @param {Boolean} [closeable]
             * @param {Boolean} [print]
             *
             * @returns {Promise} Resolved when the modal is closed.
             */
            openModal(src, options = undefined, listeners = undefined, size = 'default', closeable = true, print = false) {
                let promise;

                if (options !== undefined) {
                    promise = this.openComponentInModal(src, options, listeners, size, closeable);
                } else {
                    promise = this.openUrlInModal(src, size, closeable, print);
                }

                this.$emit('modalOpened');

                return promise;
            },

            openComponentInModal(component, options, listeners, size, closeable) {
                return new Promise(resolve => {
                    this.modals.push({
                        id: getRandomIdentifier(),
                        component,
                        componentOptions: options,
                        componentListeners: listeners,
                        size,
                        closeable,
                        close: resolve,
                    });
                });
            },

            openUrlInModal(url, size, closeable, print) {
                const id = getRandomIdentifier();

                return new Promise(resolve => {
                    this.modals.push({
                        id,
                        url: updateQueryStringParameter(updateQueryStringParameter(url, 'modal', '1'), 'random', id),
                        size,
                        closeable,
                        print,
                        close: resolve,
                    });
                });
            },

            openVNodesInModal(vnodes, size, closeable) {
                return new Promise(resolve => {
                    this.modals.push({
                        id: getRandomIdentifier(),
                        vnodes,
                        size,
                        closeable,
                        close: resolve,
                    });
                });
            },

            /**
             * @param {*} [data]
             */
            closeModal(data = null) {
                const modal = this.modals.pop();

                if (!modal) {
                    return;
                }

                modal.close(data);

                this.$emit('modalClosed', data);
            },

            closeModalFromShortcut() {
                if (!this.modals.length) {
                    return;
                }

                if (this.modals[this.modals.length - 1].closeable) {
                    this.closeModal();
                }
            },

            /**
             * @param {String} size
             */
            resizeModal(size) {
                if (!this.modals.length) {
                    return;
                }

                const modal = this.modals[this.modals.length - 1];

                modal.size = size;
            },

            beforeEnter() {
                document.body.classList.add('app--modal-opened');
                this.$emit('beforeEnter');
            },

            afterLeave() {
                document.body.classList.remove('app--modal-opened');
                this.$emit('afterLeave');
            },

            recalculateModalHeight() {
                if (!this.$refs.iframe?.length) {
                    return;
                }

                this.$refs.iframe.forEach(iframe => {
                    const main = iframe.contentWindow.document.querySelector('.app__main');

                    if (!main) {
                        return;
                    }

                    const iframeHeight = main.offsetHeight + 100;

                    iframe.closest('.modal__container--iframe').style.height = `${iframeHeight}px`;
                });
            },
        },
    };
</script>
