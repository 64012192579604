// @vue/component
export default {
    computed: {
        zorgmailAccounts() {
            return window.frm.state.zorgmailAccounts;
        },

        zorgmailAccountId: {
            get() {
                const stateZorgmailAccountId = this.$store.state.core.zorgmailAccountId;

                if (stateZorgmailAccountId && this.zorgmailAccounts.some(option => option.value === stateZorgmailAccountId)) {
                    return stateZorgmailAccountId;
                }

                return this.zorgmailAccounts[0]?.value;
            },

            set(zorgmailAccountId) {
                this.$store.commit('core/setZorgmailAccountId', String(zorgmailAccountId));
            },
        },
    },
};
