<script>
    import ModalManager from '../../../services/modalManager';
    import PhysiotherapeuticWorkDiagnosis from '../../Modals/PhysiotherapeuticWorkDiagnosis.vue';
    import Default from './Default.vue';
    import HandlesInstitute from '../../../mixins/HandlesInstitute';

    export default {
        extends: Default,

        mixins: [HandlesInstitute],

        props: {
            pwdDescription: {
                type: String,
                required: true,
            },

            pwdUrl: {
                type: String,
                required: true,
            },
        },

        methods: {
            editPhysiotherapeuticWorkDiagnosis() {
                ModalManager.openModal(
                    PhysiotherapeuticWorkDiagnosis,
                    {
                        description: this.pwdDescription,
                        url: this.pwdUrl,
                        text: this.formData.anamnesis.working_diagnosis,
                    },
                    {
                        'set-physiotherapeutic-work-diagnosis': this.setPhysiotherapeuticWorkDiagnosis,
                    },
                    'medium'
                );
            },

            /**
             * @param {{physiotherapeuticWorkDiagnosis: String}} data
             */
            setPhysiotherapeuticWorkDiagnosis(data) {
                this.formData.anamnesis.working_diagnosis = data.physiotherapeuticWorkDiagnosis;
            },
        },

        mounted() {
            this.formData.institute_id = this.instituteId;
        },
    };
</script>
