<script>
    import createArrayFromNodeList from '../../../services/createArrayFromNodeList';
    import ModalManager from '../../../services/modalManager';
    import YellowFlagsModal from '../../Modals/YellowFlags';
    import Default from './Default.vue';
    import HandlesInstitute from '../../../mixins/HandlesInstitute';

    export default {
        extends: Default,

        mixins: [HandlesInstitute],

        props: {
            config: {
                type: Object,
                required: true,
            },
            yellowFlagsModalConfig: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                redFlagFound: false,
            };
        },

        mounted() {
            this.formData.institute_id = this.instituteId;
        },

        computed: {
            nextButtonEnabled() {
                return this.examinationTabEnabled;
            },

            filledYellowFlagFields() {
                let filledFields = 0;

                Object.keys(this.yellowFlagsModalConfig.fields).forEach((key) => {
                    if (this.formData.anamnesis.screening[this.yellowFlagsModalConfig.fields[key].name] !== null &&
                        this.formData.anamnesis.screening[this.yellowFlagsModalConfig.fields[key].name] !== ''
                    ) {
                        filledFields++;
                    }
                });

                return filledFields;
            },

            openYellowFlagsLabel() {
                const filled = this.filledYellowFlagFields > 0 ? ' (' + this.filledYellowFlagFields + ')' : '';

                return this.$t('fysioroadmap.anamnesis.screening.yellow-flags') + filled;
            },

            researchAllowed() {
                if (this.conclusionValue === null) {
                    return null;
                }

                return this.conclusionValue === this.config['conclusion-positive-id'] ||
                    this.formData.anamnesis.screening.decided_to_do_more_examination_after_consulting_general_practitioner === true;
            },

            showCaregiverConsultedCheckbox() {
                return this.conclusionValue !== this.config['conclusion-positive-id'] && this.conclusionValue !== null;
            },

            confirmationValue: {
                get() {
                    return Boolean(this.formData.anamnesis.screening.red_flags_discussed);
                },
                set(value) {
                    this.formData.anamnesis.screening.red_flags_discussed = Boolean(value);
                },
            },

            conclusionValue: {
                get() {
                    if (!this.formData.anamnesis.screening.conclusion_of_screening) {
                        return null;
                    }

                    return String(this.formData.anamnesis.screening.conclusion_of_screening);
                },
                set(value) {
                    this.formData.anamnesis.screening.conclusion_of_screening = String(value);
                },
            },

            researchValue: {
                get() {
                    return String(this.formData.anamnesis.screening.examine_patient);
                },
                set(value) {
                    this.formData.anamnesis.screening.examine_patient = String(value);
                },
            },
        },

        created() {
            this.fetchCaregiverPermission();
        },

        watch: {
            redFlagFound(newValue) {
                const radios = this.$el.querySelectorAll('[name="anamnesis[screening][conclusion_of_screening]"]:not([value="' + this.config['conclusion-default-negative-id'] + '"])');

                if (newValue) {
                    createArrayFromNodeList(radios).forEach((child) => {
                        child.setAttribute('disabled', 'disabled');
                    });
                    this.conclusionValue = this.config['conclusion-default-negative-id'];
                    this.confirmationValue = true;
                } else {
                    createArrayFromNodeList(radios).forEach((child) => {
                        child.removeAttribute('disabled');
                    });
                    this.conclusionValue = this.config['conclusion-positive-id'];
                }
            },

            researchAllowed: {
                immediate: true,
                handler(newValue) {
                    if (newValue) {
                        if (this.conclusionValue === null) {
                            return;
                        }
                        this.researchValue = this.config['research-yes-id'];

                        this.$nextTick(() => {
                            this.$el.querySelector('[name="anamnesis[screening][examine_patient]"][value="' + this.config['research-yes-id'] + '"]').removeAttribute('disabled');
                            this.$el.querySelector('[name="anamnesis[screening][examine_patient]"][value="' + this.config['research-no-id'] + '"]').setAttribute('disabled', 'disabled');
                        });
                    } else {
                        this.researchValue = this.config['research-no-id'];

                        this.$nextTick(() => {
                            this.$el.querySelector('[name="anamnesis[screening][examine_patient]"][value="' + this.config['research-yes-id'] + '"]').setAttribute('disabled', 'disabled');
                            this.$el.querySelector('[name="anamnesis[screening][examine_patient]"][value="' + this.config['research-no-id'] + '"]').removeAttribute('disabled');
                        });
                    }
                },
            },

            conclusionValue: {
                immediate: true,
                handler(newValue) {
                    if (newValue === this.config['conclusion-positive-id'] || newValue === null) {
                        this.formData.anamnesis.screening.decided_to_do_more_examination_after_consulting_general_practitioner = false;
                    }
                },
            },
        },

        methods: {
            openYellowFlagsModal() {
                Object.keys(this.yellowFlagsModalConfig.fields).forEach((key) => {
                    this.yellowFlagsModalConfig.fields[key].value = this.formData.anamnesis.screening[this.yellowFlagsModalConfig.fields[key].name];
                });

                ModalManager.openModal(YellowFlagsModal, this.yellowFlagsModalConfig, {
                    'set-yellow-flag-fields': this.fillYellowFlagFields,
                }, 'medium');
            },

            fillYellowFlagFields(fields) {
                Object.keys(fields).forEach((key) => {
                    this.formData.anamnesis.screening[fields[key].name] = fields[key].value;
                });
            },

            checkRedFlags(values) {
                this.redFlagFound = false;
                Object.keys(values).forEach(key => {
                    if (values[key] === true) {
                        this.redFlagFound = true;
                    }
                });
            },
        },
    };
</script>
