export default {
    name: 'location_device',
    definition: {
        active: '',
        color: '',
        description: '',
        location: {
            jsonApi: 'hasOne',
            type: 'location',
        },
        location_id: '',
    },
};
