import Shortcut from '../services/shortcut';

const install = function (Vue) {
    if (install.installed) return;
    install.installed = true;

    Object.defineProperty(Vue.prototype, '$shortcut', {
        get() {
            return Shortcut;
        },
    });
};

Shortcut.install = install;

export default Shortcut;

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(Shortcut);
}
