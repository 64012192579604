<template>
    <div class="spinner">
        <div class="spinner__dot" :class="{'spinner__dot--white': white}" />
        <div class="spinner__dot" :class="{'spinner__dot--white': white}" />
        <div class="spinner__dot" :class="{'spinner__dot--white': white}" />
    </div>
</template>

<script>

export default {
    props: {
        white: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
