<script>
    import axios from 'axios';
    import ModalManager from '../../../services/modalManager';
    import Default from './Default.vue';

    export default {
        extends: Default,

        props: {
            module: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                medicalHistory: {
                    indication: {
                        operation: null,
                        trauma: null,
                        medication: null,
                    },
                    patient: [],
                },
            };
        },

        created() {
            this.fetchMedicalHistory();
        },

        methods: {
            fetchMedicalHistory() {
                axios.get(`/fysioroadmap/${this.formData.patient_id}/${this.indicationHistoryId}/anamnesis/registration/patient-medical-histories`)
                    .then(response => {
                        this.medicalHistory = response.data;
                    });
            },

            openModal(medicalHistoryType) {
                ModalManager.openModal(`/patient/${this.formData.patient_id}/medical_history/create?indication_history_id=${this.indicationHistoryId}&medical_history_type=${medicalHistoryType}&module=${this.module}`)
                    .then(() => {
                        this.fetchMedicalHistory();
                    });
            },
        },
    };
</script>
