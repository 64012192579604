<script>
    import Form from '../../Form.vue';
    import updateQueryStringParameter from '../../../services/updateQueryStringParameter';

    export default {
        extends: Form,

        watch: {
            'formData.complaint': 'setOverviewUrl',
        },

        methods: {
            setOverviewUrl(complaintId) {
                const url = updateQueryStringParameter(this.$refs.overview.config.url, 'complaint_id', complaintId);
                this.$refs.overview.setUrl(url);
                this.$refs.overview.refresh();
            },
        },
    };
</script>
