<template>
    <component
        :is="$options.childComponent"
        v-bind="$attrs"
        :error="error"
        :links="placeholders"
        :loading="loading"
        :options="texts"
        :value="value"
        v-on="$listeners"
    />
</template>

<script>
    import axios from 'axios';

    export default {
        inheritAttrs: false,

        props: {
            textsUrl: {
                type: String,
                required: true,
            },

            placeholdersUrl: {
                type: String,
                default: null,
            },

            value: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                texts: [],
                placeholders: {},
                loading: false,
                error: false,
            };
        },

        mounted() {
            this.fetchOptions();
        },

        beforeDestroy() {
            if (this.$options.previousSource) {
                this.$options.previousSource.cancel();
            }
        },

        methods: {
            fetchOptions() {
                this.loading = true;

                if (this.$options.previousSource) {
                    this.$options.previousSource.cancel();
                    this.$options.previousSource = null;
                }

                this.$options.previousSource = axios.CancelToken.source();

                const transformRequestWithCache = (data, headers) => {
                    // In bootstrap.js we set the cache-control header to no-cache.
                    // However, in this case we actually want to cache the response.
                    delete headers.common['Cache-Control'];

                    return data;
                };

                const textsPromise = axios
                    .get(this.textsUrl, {
                        cancelToken: this.$options.previousSource.token,
                        transformRequest: transformRequestWithCache,
                    })
                    .then(result => result.data);

                let placeholdersPromise = Promise.resolve({});
                if (this.placeholdersUrl) {
                    placeholdersPromise = axios
                        .get(this.placeholdersUrl, {
                            cancelToken: this.$options.previousSource.token,
                            transformRequest: transformRequestWithCache,
                        })
                        .then(result => result.data);
                }

                Promise
                    .all([textsPromise, placeholdersPromise])
                    .then(([texts, placeholders]) => {
                        this.texts = texts;
                        this.placeholders = placeholders;
                    })
                    .catch(() => {
                        this.error = true;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },

        childComponent: 'frm-input-smart-data-text',

        previousSource: null,
    };
</script>
