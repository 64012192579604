<template>
    <frm-modal-component ref="modal" @save="save">
        <template #header>
            {{ $t('calendar.appointment.note') }}
        </template>

        <textarea v-model="noteLocal" class="form-textarea form-textarea--tall" />
    </frm-modal-component>
</template>

<script>
    export default {
        props: {
            note: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                noteLocal: this.note,
            };
        },

        methods: {
            save() {
                this.$emit('set-note', { note: this.noteLocal });
                this.$refs.modal.closeModal();
            },
        },
    };
</script>
