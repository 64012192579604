import CalendarItemWithSubscription from './CalendarItemWithSubscription';

export default {
    ...CalendarItemWithSubscription,
    name: CalendarItemWithSubscription.name + '_resource',
    definition: {
        ...CalendarItemWithSubscription.definition,
        calendar_item_participations: {
            ...CalendarItemWithSubscription.definition.calendar_item_participations,
            type: CalendarItemWithSubscription.definition.calendar_item_participations.type + '_resource',
        },
    },
};
