// @vue/component

export default {
    methods: {
        createHiddenInput(name, value, windowRef = window) {
            const input = windowRef.document.createElement('input');
            input.type = 'hidden';
            input.name = name;
            input.value = value ? String(value) : '';

            return input;
        },
    },
};
