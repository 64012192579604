import Vue from 'vue';
import Vuex from 'vuex';
import { createPersistedState } from './helpers';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            ttl: 6 * 60 * 60 * 1000,
            paths: [
                'counters',
            ],
        }),
        createPersistedState({
            key: 'vuex-institute-id',
            paths: [
                'core.instituteId',
                'core.zorgmailAccountId',
            ],
        }),
        ...modules.overview.plugins,
    ],
    state: window.frm.state || {},
    modules,
});
