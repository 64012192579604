<template>
    <frm-modal-component ref="modal" @save="$emit('force'); $refs.modal.closeModal()">
        <template #header>
            <frm-icon name="exclamation-mark" />

            {{ $t('calendar.ignorable-errors.title') }}
        </template>

        <p>
            {{ $t('calendar.ignorable-errors.information') }}
        </p>

        <ul class="groups" data-testid="ignorable-errors">
            <li v-for="(details, title) in groups" :key="title">
                <strong>{{ title }}</strong>
                <ul>
                    <li v-for="(detail, detailIdx) in details" :key="detailIdx">
                        {{ detail }}
                    </li>
                </ul>
            </li>
        </ul>
    </frm-modal-component>
</template>

<script>

export default {
    props: {
        errors: {
            type: Array,
            required: true,
        },
    },

    computed: {
        groups() {
            return this.errors.reduce((acc, error) => {
                if (!acc[error.title]) {
                    acc[error.title] = [];
                }

                acc[error.title].push(error.detail);

                return acc;
            }, {});
        },
    },
};
</script>

<style lang="scss" scoped>
.groups {
    list-style: none;
    padding: 0;

    ul {
        margin-bottom: rhythm(1);
        margin-top: rhythm(1);
        list-style-type: disc;
        padding-left: rhythm(2);
    }
}
</style>
