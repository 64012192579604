// @vue/component
import axios from 'axios';
import DialogManager from '../../services/DialogManager';
import HandlesErrorResponses from '../HandlesErrorResponses';

export default {
    mixins: [
        HandlesErrorResponses,
    ],

    props: {
        premsRecordsUrl: {
            type: String,
            required: true,
        },
    },

    methods: {
        async sendPremsRecords() {
            let data;

            try {
                data = (await axios.get(this.premsRecordsUrl)).data.data;
            } catch (error) {
                await this.handleErrorResponse(error);
            }

            if (!data.show_notification) {
                return;
            }

            await Promise.all(data.records.data.map(record => this.showPremsNotification(
                record.may_be_sent, record.messages, record.id, record.institute_name)));
        },

        async showPremsNotification(mayBeSent, messages, recordId, institute) {
            if (!mayBeSent) {
                return DialogManager.alert(`
                    <p>${this.$t('fysioroadmap.prems-notification.invalid-record.body', { institute })}</p>
                    ${this.getMessagesList(messages)}`
                );
            }

            try {
                await DialogManager.confirm(
                    this.$t('fysioroadmap.prems-notification.valid-record.body', { institute }),
                    this.$t('fysioroadmap.prems-notification.valid-record.button-send'),
                    this.$t('fysioroadmap.prems-notification.valid-record.button-cancel')
                );
            } catch (err) {
                return;
            }

            try {
                await axios.post('/statistics/prems/send', null, { params: { id: recordId } });
            } catch (error) {
                await this.handleErrorResponse(error);

                return;
            }

            return DialogManager.alert(this.$t('fysioroadmap.prems-notification.valid-record.sent-successfully', { institute }));
        },

        getMessagesList: function (messages) {
            if (!messages.length) {
                return '';
            }

            return `
                <ul>
                    <li>${messages.join('<li>')}</li>
                </ul>`;
        },
    },
};
