// @vue/component
import axios from 'axios';
import DialogManager from '../services/DialogManager';

export default {
    methods: {
        async handleErrorResponse(error) {
            if (axios.isCancel(error)) {
                return;
            }

            if (!error.response?.data) {
                return DialogManager.alert(this.$t('base.errors.server'));
            }

            let data = {};

            if (error.response.data instanceof Blob) {
                if (error.response.data.type === 'application/json') {
                    try {
                        data = JSON.parse(await error.response.data.text());
                    } catch {
                        // do nothing
                    }
                }
            } else {
                data = error.response.data;
            }

            return DialogManager.alert(data.message || this.$t('base.errors.server'));
        },
    },
};
