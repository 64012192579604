<template>
    <div class="form-group__inline-field">
        <div class="form-group__inline-field" style="flex: 0 0 40%;">
            <input
                :id="id"
                v-bind="$attrs"
                :name="name + '_code'"
                :value="code"
                class="form-input"
                :maxlength="codeLength"
                type="text"
                @change="$emit('update:code', $event.target.value)"
                @input="updateCodeIfComplete($event.target.value)"
            >
            <input :name="name" :value="value" type="hidden">
        </div>
        <div class="form-group__inline-field" style="flex: 0 0 auto;">
            <div class="form-help">
                <button :disabled="loading || validating" class="button button--icon button--secondary" data-diagnosis-code-wizard type="button" @click="openWizard">
                    <frm-icon name="dots" class="button__icon" />
                </button>
            </div>
        </div>
        <div class="form-group__inline-field">
            <div v-if="loading || validating" class="form-help" data-diagnosis-code-busy>
                <frm-icon name="small-loading" class="form-help__icon" />
            </div>
            <div v-else-if="invalid" :aria-label="errorText" class="form-help hint--left" data-diagnosis-code-error>
                <frm-icon name="exclamation-mark" class="form-help__icon form-help__icon--error" />
            </div>
            <div v-else-if="particularities" :aria-label="particularities" class="form-help hint--left" data-diagnosis-code-particularities>
                <frm-icon name="exclamation-mark" class="form-help__icon" />
            </div>
        </div>
    </div>
</template>

<script>
    import ModalManager from '../../services/modalManager';
    import Wizard from '../Modals/DiagnosisCodeWizard.vue';
    import axios from 'axios';

    const cache = {};

    export default {
        model: {
            prop: 'value',
            event: 'change',
        },

        props: {
            value: {
                type: [String, Number],
                default: '',
            },

            name: {
                type: String,
                default: null,
            },

            indicationHistoryId: {
                type: [String, Number],
                default() {
                    return window.frm.state.indicationId;
                },
            },

            id: {
                type: String,
                default: null,
            },

            code: {
                type: String,
                default: '',
            },

            isChronic: {
                type: Boolean,
                default: false,
            },

            isFirstIllness: {
                type: Boolean,
                default: false,
            },

            validityStart: {
                type: String,
                default: '',
            },

            validityUntil: {
                type: String,
                default: '',
            },

            chronicValidityPeriodInMonths: {
                type: Number,
                default: null,
            },

            diagnosisCodeIsChronic: {
                type: Boolean,
                default: null,
            },

            codelist: {
                type: [String, Number],
                required: true,
            },

            informationUri: {
                type: String,
                required: true,
            },

            validationUri: {
                type: String,
                required: true,
            },

            wizardCodelistsUri: {
                type: String,
                required: true,
            },

            wizardCodelistsNodesUri: {
                type: String,
                required: true,
            },

            codeLength: {
                type: Number,
                default: 6,
            },
        },

        data() {
            return {
                errorText: '',
                particularities: '',
                loading: false,
                validating: false,
                invalid: false,
            };
        },

        watch: {
            value(newValue) {
                if (newValue) {
                    this.setCodeById(newValue);
                } else {
                    this.reset();
                }
            },

            code(newValue) {
                if (newValue) {
                    this.validate(true);
                } else {
                    this.reset();
                }
            },

            isChronic() {
                if (this.code) {
                    this.validate(false);
                }
            },
        },

        created() {
            if (this.value) {
                if (this.code) {
                    this.addToCache(this.value, this.code, this.diagnosisCodeIsChronic);
                    this.validate(false);
                } else {
                    this.setCodeById(this.value);
                }
            }
        },

        methods: {
            /**
             * @param {String|Number} id
             * @param {String}        code
             * @param {Boolean}       diagnosisCodeIsChronic
             */
            addToCache(id, code, diagnosisCodeIsChronic) {
                cache[id] = { code, diagnosisCodeIsChronic };
            },

            openWizard() {
                ModalManager.openModal(
                    Wizard,
                    {
                        wizardCodelistsUri: this.wizardCodelistsUri,
                        wizardCodelistsNodesUri: this.wizardCodelistsNodesUri,
                        diagnosisCodeCodelist: this.codelist,
                        diagnosisCode: this.value,
                        diagnosisCodeCode: this.code,
                    },
                    {
                        'set-diagnosis_code': this.setDiagnosisCode,
                    }
                );
            },

            /**
             * @param {{diagnosis_code_codelist: String, diagnosis_code: String, diagnosis_code_code: String}} data
             */
            setDiagnosisCode(data) {
                this.$emit('update:codelist', data.diagnosis_code_codelist);
                this.$emit('change', data.diagnosis_code);
                this.$emit('update:code', data.diagnosis_code_code);
            },

            reset() {
                this.$emit('change', null);
                this.$emit('update:code', null);

                this.invalid = false;
                this.errorText = '';
                this.particularities = '';
            },

            /**
             * @param {String|Number} id
             *
             * @return {Promise}
             */
            setCodeById(id) {
                this.loading = true;

                return Promise.resolve()
                    .then(() => {
                        if (cache[id]) {
                            return cache[id];
                        }

                        if (this.$options.previousInformationSource) {
                            this.$options.previousInformationSource.cancel();
                            this.$options.previousInformationSource = null;
                        }

                        this.$options.previousInformationSource = axios.CancelToken.source();

                        this.loading = true;

                        return axios
                            .get(this.informationUri.replace(':id', id), {
                                cancelToken: this.$options.previousInformationSource.token,
                            })
                            .then(response => {
                                this.addToCache(id, response.data.data.code, !!response.data.data.is_chronic_condition);

                                return { code: response.data.data.code, diagnosisCodeIsChronic: !!response.data.data.is_chronic_condition };
                            });
                    })
                    .then((data) => {
                        this.$emit('update:code', data.code);
                    })
                    .catch(() => {
                        // TODO: Do something on failure
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            /**
             * @param {String} code
             */
            updateCodeIfComplete(code) {
                if (code.length === this.codeLength) {
                    this.$emit('update:code', code);
                }
            },

            /**
             * @param {Boolean} updateRelatedAttributes
             *
             * @return {Promise}
             */
            validate(updateRelatedAttributes) {
                this.validating = true;

                const params = {
                    code: this.code,
                    is_chronic: this.isChronic,
                    indication_history_id: this.indicationHistoryId,
                };

                if (this.$options.previousValidationSource) {
                    this.$options.previousValidationSource.cancel();
                    this.$options.previousValidationSource = null;
                }

                this.$options.previousValidationSource = axios.CancelToken.source();

                this.validating = true;

                return axios
                    .get(this.validationUri.replace(':id', this.codelist), {
                        params,
                        cancelToken: this.$options.previousValidationSource.token,
                    })
                    .then(response => response.data.data)
                    .then(data => {
                        if (data.diagnosis_code_id) {
                            this.addToCache(data.diagnosis_code_id, params.code, !!data.diagnosis_code.data.is_chronic_condition);
                            this.$emit('change', data.diagnosis_code_id);
                        }

                        this.invalid = data.diagnosis_code_is_invalid;
                        this.errorText = data.error;
                        this.particularities = data.diagnosis_code.data.is_chronic_condition ? data.diagnosis_code.data.particularities : '';

                        if (updateRelatedAttributes && !this.invalid) {
                            this.$emit('update:isChronic', data.is_chronic);
                            this.$emit('update:validityStart', data.validity_start);
                            this.$emit('update:validityUntil', data.validity_end);
                            this.$emit('update:isFirstIllness', data.is_first_illness);
                            this.$emit('update:chronicValidityPeriodInMonths', data.diagnosis_code.data.chronic_validity_period_in_months);
                            this.$emit('update:diagnosis-code-is-chronic', data.diagnosis_code.data.is_chronic_condition);
                        }
                    })
                    .catch(() => {
                        // TODO: Do something on failure
                    })
                    .finally(() => {
                        this.validating = false;
                    });
            },
        },

        previousInformationSource: null,

        previousValidationSource: null,
    };
</script>
