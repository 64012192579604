export default {
    currency: {
        style: 'currency',
        currency: 'EUR',
    },

    decimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    },

    percent: {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    },
};
