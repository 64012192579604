<script>
    import InlineChildOverview from '../InlineChildOverview.vue';

    export default {
        extends: InlineChildOverview,

        props: {
            formData: {
                type: Object,
                required: true,
            },
        },

        methods: {
            setAllPermissionsOk(event) {
                event.currentTarget.blur();
                this.items
                    .filter(item => item.requires_explicit_answering === false)
                    .forEach(item => {
                        let answer = true;

                        if (item.permission_or_no_objection === 'no_objection') {
                            answer = false;
                        }

                        item.parent_1.answer = item.parent_2.answer = item.patient.answer = answer;
                    });
            },
        },
    };
</script>
