<template>
    <section class="modal-component">
        <header class="modal-component__header">
            <h1 class="modal-component__title">
                {{ header }}
            </h1>
        </header>

        <div class="modal-component__content">
            <div v-if="shouldShowRecoveringInfluencingFields">
                <div v-for="(field, index) in fields" :key="index" class="form-group-vertical">
                    <template v-if="field.value">
                        <div class="form-group-vertical__heading">
                            <label class="form-group-vertical__label form-group-vertical__label--static form-group-vertical__label--strong">
                                {{ field.label }}
                            </label>
                        </div>
                        <div class="form-group-vertical__field" v-html="field.value" />
                    </template>
                </div>
            </div>
            <div v-if="shouldShowParentFields && shouldShowRecoveringInfluencingFields" class="modal-component__separator" />
            <div v-if="shouldShowParentFields">
                <h3>{{ $i18n.t('fysioroadmap.anamnesis.fields.parent_info') }}</h3>
                <div v-for="(field, index) in parentFields" :key="index" class="form-group-vertical">
                    <template v-if="field.value">
                        <div class="form-group-vertical__heading">
                            <label class="form-group-vertical__label form-group-vertical__label--static form-group-vertical__label--strong">
                                {{ field.label }}
                            </label>
                        </div>
                        <div class="form-group-vertical__field" v-html="field.value" />
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: {
            header: {
                type: String,
                default: '',
            },

            shouldShowRecoveringInfluencingFields: {
                type: Boolean,
                default: false,
            },

            fields: {
                type: Array,
                required: true,
            },

            shouldShowParentFields: {
                type: Boolean,
                default: false,
            },

            parentFields: {
                type: Array,
                default: () => [],
                required: false,
            },
        },
    };
</script>
