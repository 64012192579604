import de from 'element-ui/lib/locale/lang/de';
import en from 'element-ui/lib/locale/lang/en';
import es from 'element-ui/lib/locale/lang/es';
import fr from 'element-ui/lib/locale/lang/fr';
import nl from 'element-ui/lib/locale/lang/nl';
import zhCN from 'element-ui/lib/locale/lang/zh-CN';

export default {
    de: de,
    'en-GB': en,
    es: es,
    fr: fr,
    nl: nl,
    'zh-CN': zhCN,
};
