<template>
    <button type="button" class="button button--icon button--secondary" @click="refresh">
        <frm-icon
            :class="{'frm-icon--rotating-reverse': isLoading}"
            class="button__icon"
            name="refresh-arrows"
        />
    </button>
</template>

<script>
    import axios from 'axios';

    export default {
        props: {
            url: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                isLoading: false,
            };
        },

        methods: {
            refresh() {
              // Start spinning button
                this.isLoading = true;

                if (this.$options.previousSource) {
                    this.$options.previousSource.cancel();
                    this.$options.previousSource = null;
                }

                this.$options.previousSource = axios.CancelToken.source();

                axios
                  .get(this.url, {
                      cancelToken: this.$options.previousSource.token,
                  })
                  .then(response => {
                      this.isLoading = false;
                      this.$emit('refreshed', response.data);
                  }, (result) => {
                      this.isLoading = false;
                      // TODO: display the result in a dialog
                  });
            },
        },

        previousSource: null,
    };
</script>
