// @vue/component
export default {
    methods: {
        actionIsRestricted(action, selectedRows) {
            if (!action.restriction && !action.restrictOnColumn) {
                return false;
            }

            if (action.restrictOnColumn && selectedRows.some(row => Boolean(row[action.restrictOnColumn]))) {
                return true;
            }

            if (action.restriction === 'action_only_selected') {
                return selectedRows.length === 0;
            }

            if (action.restriction === 'action_only_selected_1') {
                return (selectedRows.length !== 1);
            }

            if (action.restriction === 'action_only_none_selected') {
                return (selectedRows.length > 0);
            }

            if (action.restrictOnColumn === 'readonly') {
                return (selectedRows.some(rowData => rowData.readonly));
            }

            return true;
        },
    },
};
