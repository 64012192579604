<template>
    <article class="patient-search-teaser" :class="{'patient-search-teaser--fixed-height': fixedHeight}">
        <figure class="patient-search-teaser__avatar">
            <img v-if="patient.photo_path" :src="patient.photo_path" class="patient-search-teaser__photo">
            <frm-icon v-else class="patient-search-teaser__icon" name="patient" />
        </figure>
        <header class="patient-search-teaser__name-address">
            <h3 class="patient-search-teaser__name">
                {{ patient.name.fullname }}
            </h3>
            <p class="patient-search-teaser__address" :class="{'patient-search-teaser__address--fixed-height': fixedHeight}" v-html="address" />
        </header>
        <p v-if="patient.birthdate || patient.identification_number" class="patient-search-teaser__dob-id">
            <span v-if="patient.birthdate" class="patient-search-teaser__dob">
                {{ $ds(patient.birthdate, 'date') }}
                <frm-deceased-icon v-if="patient.deceased" :date="$ds(patient.deceased_date, 'date')" />
            </span>
            <span v-if="patient.identification_number" class="patient-search-teaser__id">{{ patient.identification_number }}</span>
        </p>
    </article>
</template>

<script>
    export default {
        props: {
            patient: {
                type: Object,
                required: true,
            },
            showExtendedAddress: {
                type: Boolean,
                default: false,
            },
            fixedHeight: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            address() {
                return this.showExtendedAddress ? this.patient.address_extended_formatted : this.patient.address_formatted;
            },
        },
    };
</script>
