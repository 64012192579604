<template>
    <frm-modal-component ref="modal" :save-disabled="saveDisabled" @save="save">
        <template #header>
            {{ $t('fysioroadmap.diagnosis_code.wizard-header') }}
        </template>

        <div class="diagnosis-code-wizard" :class="{'diagnosis-code-wizard--initialized': initialized}">
            <div class="form-group">
                <label class="form-group__label" for="frm_input_diagnosis_code_wizard_modal_diagnosis_code_list">
                    {{ $t('fysioroadmap.anamnesis.fields.diagnosis_code_list') }}
                </label>
                <div class="form-group__field">
                    <div class="form-select">
                        <frm-input-ajax-select
                            id="frm_input_diagnosis_code_wizard_modal_diagnosis_code_list"
                            v-model="diagnosisCodeCodelistLocal"
                            :uri="wizardCodelistsUri"
                            class="form-select__field"
                        />
                    </div>
                </div>
            </div>

            <div class="form-group diagnosis-code-wizard__content">
                <div v-if="loading" class="diagnosis-code-wizard__loader">
                    <div class="spinner">
                        <div class="spinner__dot" />
                        <div class="spinner__dot" />
                        <div class="spinner__dot" />
                    </div>
                </div>
                <div v-else class="diagnosis-code-wizard__nodes">
                    <div v-for="node in nodeList" :key="node.id" class="diagnosis-code-wizard__nodes-item" @click="chooseNode(node)">
                        <span class="diagnosis-code-wizard__nodes-item-code">{{ node.partial_code }}</span>{{ node.description }}
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label class="form-group__label" for="frm_input_diagnosis_code_wizard_modal_diagnosis_code_code">
                    {{ $t('fysioroadmap.anamnesis.fields.diagnosis_code') }}
                </label>
                <div class="form-group__field form-group__field--small">
                    <input
                        id="frm_input_diagnosis_code_wizard_modal_diagnosis_code_code"
                        v-model="diagnosisCodeCodeLocal"
                        :maxlength="$options.shortCodeLength"
                        class="form-input"
                        type="text"
                    >
                </div>
            </div>
        </div>
    </frm-modal-component>
</template>

<script>
    import axios from 'axios';

    const cache = {};

    export default {
        props: {
            diagnosisCodeCodelist: {
                type: [String, Number],
                default: null,
            },

            diagnosisCode: {
                type: [String, Number],
                default: null,
            },

            diagnosisCodeCode: {
                type: String,
                default: '',
            },

            wizardCodelistsUri: {
                type: String,
                required: true,
            },

            wizardCodelistsNodesUri: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                diagnosisCodeCodelistLocal: this.diagnosisCodeCodelist,
                diagnosisCodeLocal: this.diagnosisCode,
                diagnosisCodeCodeLocal: this.diagnosisCodeCode?.substr(this.$options.shortCodeLength * -1) || '',
                initialized: false,
                loading: false,
                cache,
            };
        },

        computed: {
            nodeList() {
                return (this.cache[this.diagnosisCodeCodelistLocal] || {})[this.diagnosisCodeCodeLocal] || [];
            },

            saveDisabled() {
                return this.loading || this.diagnosisCodeCodeLocal.length < this.$options.shortCodeLength;
            },
        },

        watch: {
            diagnosisCodeCodelistLocal(newValue) {
                if (this.diagnosisCodeCodeLocal) {
                    this.diagnosisCodeCodeLocal = '';
                } else {
                    this.fetchNodes(newValue);
                }
            },

            diagnosisCodeCodeLocal(newValue) {
                this.fetchNodes(this.diagnosisCodeCodelistLocal, newValue)
                    .then(() => {
                        const node = this.cache[this.diagnosisCodeCodelistLocal][newValue].find(node => node.code_start === newValue);

                        if (node && node.diagnosis_code_id) {
                            this.diagnosisCodeLocal = node.diagnosis_code_id;
                        }
                    });
            },
        },

        created() {
            if (this.diagnosisCodeCodelistLocal) {
                // Load entire code
                this.fetchNodes(this.diagnosisCodeCodelistLocal, this.diagnosisCodeCodeLocal)
                    .then(() => {
                        // Now also preload all partial codes
                        const promises = Array.from(Array(this.diagnosisCodeCodeLocal.length).keys())
                            .reverse()
                            .map(index => this.fetchNodes(this.diagnosisCodeCodelistLocal, this.diagnosisCodeCodeLocal.substr(0, index)));

                        return Promise.all(promises);
                    })
                    .then(() => {
                        this.initialized = true;
                    });
            }
        },

        methods: {
            /**
             * @param {String} codelistId
             * @param {String} code
             */
            fetchNodes(codelistId, code = '') {
                return Promise.resolve()
                    .then(() => {
                        if (!this.cache[codelistId]) {
                            this.$set(this.cache, codelistId, {});
                        }

                        if (!this.cache[codelistId][code]) {
                            if (code === this.diagnosisCodeCodeLocal) {
                                this.loading = true;
                            }

                            return axios.get(this.wizardCodelistsNodesUri.replace(':id', codelistId), { params: { code } })
                                .then(response => {
                                    this.$set(this.cache[codelistId], code, response.data.data);
                                });
                        }

                        return Promise.resolve();
                    })
                    .catch(() => {
                        // TODO: Do something on failure
                    })
                    .finally(() => {
                        if (code === this.diagnosisCodeCodeLocal) {
                            this.loading = false;
                        }
                    });
            },

            /**
             * @param {{code_start: String}} node
             */
            chooseNode(node) {
                this.diagnosisCodeCodeLocal = node.code_start;
            },

            save() {
                this.$emit('set-diagnosis_code', {
                    diagnosis_code_codelist: this.diagnosisCodeCodelistLocal,
                    diagnosis_code: this.diagnosisCodeLocal,
                    diagnosis_code_code: this.diagnosisCodeCodeLocal.padStart(this.$options.fullCodeLength, '0'),
                });
                this.$refs.modal.closeModal();
            },
        },

        shortCodeLength: 4,

        fullCodeLength: 6,
    };
</script>
