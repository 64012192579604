<template>
    <div class="tabs">
        <div class="tabs__header">
            <ul class="tabs__nav">
                <li v-for="tab in tabsSorted" :key="tab.slug" class="tabs__nav-item">
                    <a
                        :aria-label="tab.label"
                        :class="{'tabs__nav-link--active': tab.active, 'tabs__nav-link--disabled': tab.disabled, 'hint--bottom': tab.label}"
                        :href="'#' + tab.slug"
                        class="tabs__nav-link"
                        @click.prevent="!tab.disabled && select(tab.slug, true, $event)"
                    >
                        <frm-icon v-if="tab.icon" :name="tab.icon" class="tabs__nav-icon" />
                        {{ tab.title }}
                    </a>
                </li>
            </ul>
            <div v-if="$slots.actions" class="tabs__actions">
                <slot name="actions" />
            </div>
        </div>
        <div class="tabs__content">
            <slot />
        </div>
    </div>
</template>

<script>
    import ModalManager from '../services/modalManager';

    export default {
        data() {
            return {
                tabs: [],
            };
        },

        computed: {
            tabsSorted() {
                return this.tabs.slice()
                    .filter(tab => tab.$options.name === 'TabbedContentTab')
                    .sort((tabA, tabB) => tabA.order - tabB.order);
            },
        },

        mounted() {
            this.tabs = this.$children;

            // Select tab from hash
            if (window.location.hash) {
                this.selectTabFromHash();
            }

            // Activate first tab if none is active
            const activeTab = this.tabs.some(tab => tab.active);

            if (!activeTab) {
                this.selectFirstTab();
            }

            // Listen to hash change event
            window.addEventListener('hashchange', (e) => {
                this.selectTabFromHash();
            }, false);
        },

        methods: {
            /**
             * @param {String} slug
             * @param {Boolean} [updateHash]
             * @param {Event} [event]
             * @param {Boolean} [force]
             *
             * @returns {Boolean}
             */
            select(slug, updateHash = true, event = null, force = false) {
                const targetTab = this.tabs.find((tab) => {
                    return tab.slug === slug;
                });

                // Blur the clicked tab so it won't stay visually active
                if (event) {
                    event.target.blur();
                }

                if (targetTab && targetTab.saveAndSwitch && !force) {
                    this.$emit('submit-and-redirect', targetTab.saveAndSwitch);

                    return false;
                }

                if (!targetTab || targetTab.disabled) {
                    return false;
                }

                this.tabs.forEach((tab) => {
                    tab.active = (tab.slug === slug);
                });

                if (updateHash) {
                    window.history.pushState({}, null, window.location.toString().split('#')[0] + '#' + slug);
                }

                // We dispatch a resize event here because the table headers columns need to be
                // adjusted, if we don't do this, datatables will set a width of 100px to the header columns...
                window.dispatchEvent(new Event('resize'));

                this.$emit('change', slug);

                this.$nextTick(() => ModalManager.recalculateModalHeight());

                return true;
            },

            selectTabFromHash() {
                const hash = window.location.hash.substr(1);

                if (!hash) {
                    this.selectFirstTab();

                    return;
                }

                const hashParts = hash.split(':');
                let hashPartsIterator = hashParts.length;
                let found = false;

                while (hashPartsIterator--) {
                    found = this.select(hashParts.slice(0, hashPartsIterator + 1).join(':'), false, null, true);

                    if (found) {
                        break;
                    }
                }

                if (!found) {
                    this.selectFirstTab();
                }
            },

            selectFirstTab() {
                if (!this.tabsSorted.length) {
                    return;
                }

                const firstTab = this.tabsSorted[0];
                this.tabs.find(tab => tab === firstTab).active = true;
            },

            /**
             * @return {Boolean}
             */
            hasPrevious() {
                const child = this.getCurrentChild();

                return (child && child.hasPrevious()) || this.getCurrentIndex() > 0;
            },

            selectPrevious() {
                if (this.hasPrevious() === false) {
                    return;
                }

                const child = this.getCurrentChild();
                if (child && child.hasPrevious()) {
                    child.selectPrevious();
                } else {
                    const previousTab = this.tabsSorted[this.getCurrentIndex() - 1];
                    this.select(previousTab.slug);
                }
            },

            /**
             * @return {Boolean}
             */
            hasNext() {
                const child = this.getCurrentChild();

                return (child && child.hasNext()) || this.getCurrentIndex() < this.tabsSorted.length - 1;
            },

            selectNext() {
                if (this.hasNext() === false) {
                    return;
                }

                const child = this.getCurrentChild();
                if (child && child.hasNext()) {
                    child.selectNext();
                } else {
                    const nextTab = this.tabsSorted[this.getCurrentIndex() + 1];
                    this.select(nextTab.slug);
                }
            },

            /**
             * @returns {Vue}
             */
            getCurrent() {
                return this.tabsSorted[this.getCurrentIndex()];
            },

            /**
             * @returns {Vue|undefined}
             */
            getCurrentChild() {
                return this.getCurrent().$children.find(child => child.$options.name === this.$options.name);
            },

            /**
             * @returns {Number}
             */
            getCurrentIndex() {
                return this.tabsSorted.findIndex(tab => tab.active);
            },
        },
    };
</script>
