<script>
    import Form from '../../Form.vue';

    export default {
        extends: Form,

        props: {
            fullPage: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                tabbedContentFound: false,
                tabbedContentLastChange: null,
                observerIntersectingTarget: null,
            };
        },

        mounted() {
            this.$options.tabbedContent = this.$children.find(child => child.$options.name === 'frm-tabbed-content') || null;

            if (this.$options.tabbedContent !== null) {
                this.tabbedContentFound = true;
                this.tabbedContentLastChange = (new Date()).getTime();

                this.$options.tabbedContent.$on('change', () => {
                    this.tabbedContentLastChange = (new Date()).getTime();
                });
            }

            if (this.fullPage && this.$refs.questions.children.length) {
                const clientRect = this.$refs.questions.children[0].getBoundingClientRect();
                const options = {
                    rootMargin: `${clientRect.top * -1}px ${(window.innerWidth - clientRect.right) * -1}px ${(window.innerHeight - clientRect.bottom) * -1}px ${clientRect.left * -1}px`,
                    threshold: [0.5],
                };
                this.$options.observer = new IntersectionObserver(this.onIntersect.bind(this), options);
                Array.from(this.$refs.questions.children).forEach(child => {
                    this.$options.observer.observe(child);
                });
            }
        },

        beforeDestroy() {
            if (this.$options.observer) {
                this.$options.observer.disconnect();
                this.$options.observer = null;
            }
        },

        computed: {
            previousButtonEnabled() {
                if (this.observerIntersectingTarget) {
                    return Boolean(this.observerIntersectingTarget.previousElementSibling);
                }

                if (!this.tabbedContentFound) {
                    return false;
                }

                // N.B. We use tabbedContentLastChange in this method to make the method behind it reactive.
                return Boolean(this.tabbedContentLastChange) && this.$options.tabbedContent.hasPrevious();
            },
        },

        methods: {
            previousButtonClick(e) {
                if (this.observerIntersectingTarget && this.observerIntersectingTarget.previousElementSibling) {
                    e.preventDefault();
                    this.observerIntersectingTarget.previousElementSibling.scrollIntoView({ behavior: 'smooth' });
                } else if (this.tabbedContentFound && this.$options.tabbedContent.hasPrevious()) {
                    e.preventDefault();
                    this.$options.tabbedContent.selectPrevious();
                }
            },

            nextButtonClick(e) {
                if (this.observerIntersectingTarget && this.observerIntersectingTarget.nextElementSibling) {
                    e.preventDefault();
                    this.observerIntersectingTarget.nextElementSibling.scrollIntoView({ behavior: 'smooth' });
                } else if (this.tabbedContentFound && this.$options.tabbedContent.hasNext()) {
                    e.preventDefault();
                    this.$options.tabbedContent.selectNext();
                }
            },

            onIntersect(entries, observer) {
                // Use isIntersecting if possible because browsers can report isIntersecting as true,
                // but intersectionRatio as 0, when something very slowly enters the viewport.
                const entry = entries.find(e => e.isIntersecting && e.intersectionRatio >= observer.thresholds[0]);

                if (entry) {
                    this.observerIntersectingTarget = entry.target;
                }
            },
        },

        tabbedContent: null,

        observer: null,
    };
</script>
