// @vue/component
import HandlesInstitute from '../HandlesInstitute';

export default {
    mixins: [
        HandlesInstitute,
    ],

    computed: {
        hasInstituteFilter() {
            return !!this.instituteFilter;
        },

        instituteFilter() {
            return this.config.filters.find(filter => filter.type === 'institute-select');
        },
    },
};
