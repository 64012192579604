import JsonApi from 'devour-client';
import originalErrorsMiddlewareFactory from 'devour-client/lib/middleware/json-api/res-errors';
import serialize from 'devour-client/lib/middleware/json-api/_serialize';
import serializeIncluded from './_serialize-included';
import definitions from './definitions';

const apiToken = document.head.querySelector('meta[name="api-token"]');
const apiUrl = document.head.querySelector('meta[name="api-url"]');

let apiRefreshTokenTried = false;

let jsonApi = null;

/**
 * Here we create an middleware which will retrieve the cancel token from
 * the params object (if it is present) to make an single request cancelable...
 */
const cancelTokenMiddleware = {
    name: 'axios-cancel-token-middleware',
    req: payload => {
        if (payload.req.params && payload.req.params._cancelToken) {
            Object.assign(payload.req, {
                cancelToken: payload.req.params._cancelToken,
            });
            delete payload.req.params._cancelToken;
        }

        return payload;
    },
};

/**
 * This middleware extends the default errors middleware. It directly returns the payload
 * if the request was cancelled, so we can check this in the catch handler.
 */
const originalErrorsMiddleware = originalErrorsMiddlewareFactory.getMiddleware({
    errorBuilder: error => ({
        title: error.title,
        detail: error.detail,
        meta: error.meta,
    }),
});

const errorsMiddleware = {
    name: 'errors_patched',
    error: payload => {
        if (jsonApi.axios.isCancel(payload)) {
            return payload;
        }

        return originalErrorsMiddleware.error(payload);
    },
};

/**
 * This middleware will add all data to the included-array as well,
 * that way relationships to items in the original "data" collection
 * will work, without fixes in devour itself.
 *
 * This is a temporary fix until https://github.com/twg/devour/issues/155 is fixed
 */
const addDataToIncludedMiddleware = {
    name: 'add-data-to-included-middleware',
    res: payload => {
        if (!payload.res.data || !payload.res.data.data) {
            return payload;
        }

        if (!payload.res.data.included) {
            payload.res.data.included = [];
        }

        if (Array.isArray(payload.res.data.data)) {
            // collection
            payload.res.data.included = payload.res.data.included.concat(payload.res.data.data.map(item => {
                const newItem = { ...item };
                delete newItem.relationships;

                return newItem;
            }));
        } else {
            // item
            const newItem = { ...payload.res.data.data };
            delete newItem.relationships;

            payload.res.data.included.push(newItem);
        }

        return payload;
    },
};

/**
 * This middleware will add included items to POST requests.
 * It is a copy of the original POST middleware because that can't be monkey patched.
 */
const postMiddleware = {
    name: 'POST_patched',
    req: (payload) => {
        const jsonApi = payload.jsonApi;

        if (payload.req.method === 'POST') {
            payload.req.headers = {
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
            };
            if (payload.req.data.constructor === Array) {
                payload.req.data = {
                    data: serialize.collection.call(jsonApi, payload.req.model, payload.req.data),
                    included: serializeIncluded.collection.call(jsonApi, payload.req.model, payload.req.data),
                    meta: payload.req.meta,
                };
            } else {
                payload.req.data = {
                    data: serialize.resource.call(jsonApi, payload.req.model, payload.req.data),
                    included: serializeIncluded.resource.call(jsonApi, payload.req.model, payload.req.data),
                    meta: payload.req.meta,
                };
            }
        }

        return payload;
    },
};

/**
 * This middleware will add included items to PATCH requests.
 * It is a copy of the original PATCH middleware because that can't be monkey patched.
 */
const patchMiddleware = {
    name: 'PATCH_patched',
    req: (payload) => {
        const jsonApi = payload.jsonApi;

        if (payload.req.method === 'PATCH') {
            payload.req.headers = {
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
            };
            if (payload.req.data.constructor === Array) {
                payload.req.data = {
                    data: serialize.collection.call(jsonApi, payload.req.model, payload.req.data),
                    included: serializeIncluded.collection.call(jsonApi, payload.req.model, payload.req.data),
                    meta: payload.req.meta,
                };
            } else {
                payload.req.data = {
                    data: serialize.resource.call(jsonApi, payload.req.model, payload.req.data),
                    included: serializeIncluded.resource.call(jsonApi, payload.req.model, payload.req.data),
                    meta: payload.req.meta,
                };
            }
        }

        return payload;
    },
};

if (apiUrl) {
    jsonApi = new JsonApi({
        apiUrl: apiUrl.content,
        pluralize: false,
        logger: process.env.NODE_ENV !== 'production' && !window.location.hostname.startsWith('testing.'),
    });

    jsonApi.insertMiddlewareBefore('axios-request', cancelTokenMiddleware);
    jsonApi.insertMiddlewareBefore('response', addDataToIncludedMiddleware);
    jsonApi.replaceMiddleware('POST', postMiddleware);
    jsonApi.replaceMiddleware('PATCH', patchMiddleware);
    jsonApi.replaceMiddleware('errors', errorsMiddleware);

    /**
     * We'll register an interceptor that will check if the API token we
     * use is still valid. If it isn't and we receive an 401 from the server,
     * we'll try to retrieve a new token from our own endpoint. If the refresh
     * is unsuccessful, we'll reload the page, forcing the user to be redirected
     * to the login page.
     */
    jsonApi.axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response && error.response.status && error.response.status === 401) {
            if (apiRefreshTokenTried) {
                throw new Error('Access token invalid and attempt to refresh failed.');
            }

            return jsonApi.axios.post('/auth/refresh').then(response => {
                if (!response.data.success) {
                    window.location.reload();
                    throw new Error('Access token invalid and attempt to refresh failed.');
                }

                apiRefreshTokenTried = true;

                /**
                 * Set the api token in which we use in the header (html), set the api token for the
                 * json api client and set the api token for the previous request.
                 */
                apiToken.content = response.data.token;
                jsonApi.axios.defaults.headers.Authorization = `Bearer ${apiToken.content}`;
                error.config.headers.Authorization = `Bearer ${apiToken.content}`;

                setTimeout(() => {
                    apiRefreshTokenTried = false;
                }, 2 * 60 * 1000);

                // Retry the previous request...
                return jsonApi.axios.request(error.config);
            });
        }

        return Promise.reject(error);
    });

    definitions.forEach(definition => {
        jsonApi.define(definition.name, definition.definition, definition.options || {});
    });

    jsonApi.axios.defaults.headers.Authorization = `Bearer ${apiToken.content}`;
    jsonApi.axios.defaults.headers.Accept = 'application/vnd.api+json';
}

export default jsonApi;
