<template>
    <section class="modal-component">
        <header class="modal-component__header">
            <h1 class="modal-component__title">
                {{ header }}
            </h1>
        </header>

        <div class="modal-component__content">
            <div class="message-content" v-html="content" />
        </div>
    </section>
</template>

<script>
    import S from 'string';

    export default {
        props: {
            header: {
                type: String,
                default: '',
            },

            notes: {
                type: String,
                default: '',
            },
        },

        computed: {
            content() {
                return S(this.notes).escapeHTML().toString().replace(/(\r\n|\n\r|\r|\n)/g, '<br>$1');
            },
        },
    };
</script>
