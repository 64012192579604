<script>
    import ModalManager from '../../../services/modalManager';
    import RecoveringInfluencingFactors from '../../Modals/RecoveringInfluencingFactors.vue';
    import DomainsForm from '../../Modals/Domains.vue';
    import ParentInfoForm from '../../Modals/ParentInfo.vue';
    import Default from './Default.vue';
    import axios from 'axios';

    export default {
        extends: Default,

        props: {
            rifModalConfig: {
                type: Object,
                required: true,
            },

            domainsModalConfig: {
                type: Object,
                required: true,
            },

            parentInfoModalConfig: {
                type: Object,
                required: true,
            },

            autofillRoute: {
                type: String,
                required: true,
            },

            hypothesesRoute: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                VASDisabled: false,
                NPRSDisabled: false,
                PSKDisabled: false,
                hasHypotheses: false,
                hypothesesCount: 0,
            };
        },

        computed: {
            filledFields() {
                let filledFields = 0;

                Object.keys(this.rifModalConfig.fields).forEach((key) => {
                    if (this.formData.anamnesis[this.rifModalConfig.fields[key].name] !== null &&
                        this.formData.anamnesis[this.rifModalConfig.fields[key].name] !== '' &&
                        // remove this line after FEBS-505 has been fixed
                        this.formData.anamnesis[this.rifModalConfig.fields[key].name] !== '\n'
                    ) {
                        filledFields++;
                    }
                });

                return filledFields;
            },

            hasFilledFields() {
                return this.filledFields > 0;
            },

            parentFilledFields() {
                let parentFields = 0;

                Object.keys(this.parentInfoModalConfig.fields).forEach((key) => {
                    if (this.formData.anamnesis[this.parentInfoModalConfig.fields[key].name] !== null &&
                        this.formData.anamnesis[this.parentInfoModalConfig.fields[key].name] !== '' &&
                        // remove this line after FEBS-505 has been fixed
                        this.formData.anamnesis[this.parentInfoModalConfig.fields[key].name] !== '\n'
                    ) {
                        parentFields++;
                    }
                });

                return parentFields;
            },

            hasParentFilledFields() {
                return this.parentFilledFields > 0;
            },

            isChildrensPhysicalTherapy() {
                return this.formData.module === 'childrens_physical_therapy';
            },
        },

        mounted() {
            this.checkHypotheses();
        },

        methods: {
            fillVASComments(content) {
                this.fillAnamnesisField(this.isChildrensPhysicalTherapy ? 'functions' : 'disorders', content);
                this.VASDisabled = true;
            },

            fillNPRSComments(content) {
                this.fillAnamnesisField(this.isChildrensPhysicalTherapy ? 'functions' : 'disorders', content);
                this.NPRSDisabled = true;
            },

            fillPSKComments(content) {
                this.fillAnamnesisField(this.isChildrensPhysicalTherapy ? 'activities' : 'constraints', content);
                this.PSKDisabled = true;
            },

            fillAnamnesisField(field, content) {
                this.formData.anamnesis[field] = this.formData.anamnesis[field] ? this.formData.anamnesis[field] + '\n' + content : content;
            },

            autofillSideAndComplaint(event) {
                if (!this.formData.involved_side && !this.formData.complaint) {
                    axios.post(this.autofillRoute, {
                        module: this.formData.module,
                        id: this.formData.anamnesis_id,
                        text: this.formData.anamnesis.patient_needs,
                    }).then(response => {
                        this.formData.involved_side = response.data.involved_side;
                        this.formData.complaint = response.data.complaint_id;
                    });
                }
            },

            openRecoveringInfluencingFactorsModal() {
                Object.keys(this.rifModalConfig.fields).forEach((key) => {
                    this.rifModalConfig.fields[key].value = this.formData.anamnesis[this.rifModalConfig.fields[key].name];
                });
                this.rifModalConfig['factors-not-present'].value = this.formData.anamnesis[this.rifModalConfig['factors-not-present'].name];
                this.rifModalConfig['show-on-main-screen'].value = this.formData.anamnesis[this.rifModalConfig['show-on-main-screen'].name];

                ModalManager.openModal(RecoveringInfluencingFactors, this.rifModalConfig, {
                    'set-rif-fields': this.fillRecoveringInfluencingFactorsFields,
                }, 'medium');
            },

            fillRecoveringInfluencingFactorsFields({ fields, showOnMainScreen, factorsNotPresent }) {
                Object.keys(fields).forEach((key) => {
                    this.formData.anamnesis[fields[key].name] = fields[key].value;
                });

                this.formData.anamnesis[this.rifModalConfig['click-name']] = this.hasFilledFields;
                this.formData.anamnesis[factorsNotPresent.name] = factorsNotPresent.value;
                this.formData.anamnesis[showOnMainScreen.name] = showOnMainScreen.value;
            },

            openDomainsModal() {
                Object.keys(this.domainsModalConfig.fields).forEach((key) => {
                    this.domainsModalConfig.fields[key].value = this.formData.anamnesis[this.domainsModalConfig.fields[key].name];
                });

                ModalManager.openModal(DomainsForm, this.domainsModalConfig, {
                    'set-domains-fields': this.fillDomainsFields,
                });
            },

            fillDomainsFields({ fields }) {
                Object.keys(fields).forEach((key) => {
                    this.formData.anamnesis[fields[key].name] = fields[key].value;
                });
            },

            openParentInfoModal() {
                Object.keys(this.parentInfoModalConfig.fields).forEach((key) => {
                    this.parentInfoModalConfig.fields[key].value = this.formData.anamnesis[this.parentInfoModalConfig.fields[key].name];
                });
                this.parentInfoModalConfig['show-on-main-screen'].value = this.formData.anamnesis[this.parentInfoModalConfig['show-on-main-screen'].name];

                ModalManager.openModal(ParentInfoForm, this.parentInfoModalConfig, {
                    'set-parent-info-fields': this.fillParentInfoFields,
                });
            },

            fillParentInfoFields({ fields, showOnMainScreen }) {
                Object.keys(fields).forEach((key) => {
                    this.formData.anamnesis[fields[key].name] = fields[key].value;
                });

                this.formData.anamnesis[showOnMainScreen.name] = showOnMainScreen.value;
            },

            openHypothesisModal() {
                ModalManager.openModal('/fysioroadmap/' + this.formData.patient_id + '/' + this.indicationHistoryId + '/hypothesis')
                    .then(() => {
                        this.checkHypotheses();
                    });
            },

            checkHypotheses() {
                if (this.isChildrensPhysicalTherapy) {
                    axios.get(this.hypothesesRoute).then(response => {
                        this.hypothesesCount = response.data.data.length;
                        if (this.hypothesesCount > 0) {
                            this.hasHypotheses = true;
                        }
                    });
                }
            },
        },
    };
</script>
