export default {
    name: 'prems_record',
    definition: {
        error_message: '',
        indication_history_id: '',
        insurer_description: '',
        insurer_uzovi: '',
        is_sufficient: '',
        may_be_sent: '',
        messages: [],
        patient_address_is_sufficient: '',
        patient_already_invited: '',
        patient_email_is_sufficient: '',
        patient_id: '',
        patient_is_sufficient: '',
        patient_name: '',
        permission_is_granted: '',
        sent: '',
        sent_date: '',
        therapist_id: '',
        therapist_name: '',
    },
    options: {
        collectionPath: 'prems_records',
    },
};
