// @vue/component
export default {
    computed: {
        instituteOptions() {
            return window.frm.state.institutes;
        },

        instituteSelectFeatureFlag() {
            return window.frm.state.institutesSelectFeatureFlag;
        },

        instituteId: {
            get() {
                const stateInstituteId = this.$store.state.core.instituteId;

                if (stateInstituteId && this.instituteOptions.some(option => option.value === stateInstituteId)) {
                    return stateInstituteId;
                }

                return this.instituteOptions[0].value;
            },

            set(instituteId) {
                this.$store.commit('core/setInstituteId', String(instituteId));
            },
        },
    },
};
