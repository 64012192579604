<template>
    <div class="dropdown">
        <button :class="{'tool-button--active': opened}" :disabled="disabled" class="tool-button tool-button--block" type="button" @click="opened = !opened">
            <span class="tool-button__icon-group">
                <frm-icon :name="icon" class="tool-button__icon" />
                <span class="tool-button__toggleable-label">
                    {{ label }}
                    <frm-icon name="caret-down" class="tool-button__toggle-icon" />
                </span>
            </span>
        </button>
        <div v-if="opened" class="dropdown__content">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true,
            },

            icon: {
                type: String,
                required: true,
            },

            disabled: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                opened: false,
            };
        },

        mounted() {
            window.addEventListener('click', (event) => {
                if (this.opened && !this.$el.contains(event.target)) {
                    this.opened = false;
                }
            });
        },
    };
</script>
