import ModalManager from './modalManager';
import IgnorableErrorsModal from '../components/Calendar/IgnorableErrorsModal.vue';
import DialogManager from './DialogManager';

export const confirmIgnorableErrors = (errors) => {
    return new Promise(resolve => {
        ModalManager.openModal(IgnorableErrorsModal, {
            errors,
        }, {
            force: () => resolve(true),
        }, 'medium').then(() => resolve(false));
    });
};

export const handleIgnorableErrorsResponse = async(response, forceCallback, cancelCallback) => {
    if (!response || typeof response !== 'object') {
        throw response;
    }

    const errors = Object.values(response);
    // eslint-disable-next-line camelcase
    const allIgnorable = errors.every(error => error.meta?.can_be_ignored);

    if (!allIgnorable) {
        throw response;
    }

    const force = await confirmIgnorableErrors(errors);

    if (force) {
        try {
            forceCallback();
        } catch (err) {
            await DialogManager.errors(err);
        }

        return;
    }

    if (cancelCallback) {
        cancelCallback();
    }
};
