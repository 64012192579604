<script>
    import { mapState } from '../../../store/helpers';
    import ModalManager from '../../../services/modalManager';
    import InlineChildOverview from '../../InlineChildOverview.vue';

    export default {
        extends: InlineChildOverview,

        props: {
            origin: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                extraClinimetrics: null,
            };
        },

        computed: mapState([
            'patientId',
            'indicationId',
        ]),

        watch: {
            async extraClinimetrics(clinimetricsId) {
                if (clinimetricsId) {
                    const url = `/fysioroadmap/${this.patientId}/${this.indicationId}/clinimetrics/create/${clinimetricsId}?origin=${this.origin}`;
                    await ModalManager.openModal(url, undefined, undefined, 'full');
                    this.extraClinimetrics = null;
                }
            },
        },

        methods: {
            updateItem(item) {
                item.is_present = true;
            },
        },
    };
</script>
