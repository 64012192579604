<script>
    import priorityNav from 'priority-nav';
    import breakpointValue from '../services/breakpointValue';

    export default {
        mounted() {
            priorityNav.init({
                mainNavWrapper: '.primary-nav',
                mainNav: '.primary-nav__list',
                navDropdownClassName: 'primary-nav__dropdown',
                navDropdownToggleClassName: 'primary-nav__dropdown-toggle',
                navDropdownLabel: '&bull;&bull;&bull;',
                navDropdownBreakpointLabel: this.$t('primary-nav.menu'),
                breakPoint: breakpointValue('screen-sm'),
                count: false,
            });
        },
    };
</script>
