import CalendarItemWithSubscriptionParticipation from './CalendarItemWithSubscriptionParticipation';

export default {
    name: 'subscription_participation_payment_status_resource',
    definition: {
        ...CalendarItemWithSubscriptionParticipation.definition,
        subscription_item_data_start: '',
        subscription_item_data_end: '',
        subscription_category: '',
        invoice_method: '',
    },
};
