<script>
import Form from '../Form.vue';
import HandlePremsRecordsNotifications from '../../mixins/Fysioroadmap/HandlePremsRecordsNotifications';

export default {
    extends: Form,

    mixins: [
        HandlePremsRecordsNotifications,
    ],

    methods: {
        async handleEndEvaluationResponse(response) {
            if (!response) {
                return;
            }

            await this.sendPremsRecords();
        },

        async handleActionResult(result) {
            if (!result || result.action.name !== 'end_evaluation_done' || result.data == null) {
                return;
            }

            await this.sendPremsRecords();
        },
    },
};
</script>
