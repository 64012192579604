<script>

const expandedClass = 'expandable-content--expanded';

export default {
    props: {
        initiallyExpanded: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            expanded: false,
        };
    },

    mounted() {
        this.expanded = this.initiallyExpanded;
    },

    watch: {
        expanded() {
            if (this.expanded) {
                this.$el.classList.add(expandedClass);
            } else {
                this.$el.classList.remove(expandedClass);
            }
        },
    },

    methods: {
        toggleExpanded() {
            this.expanded = !this.expanded;
        },
    },
};
</script>
