<template>
    <div style="flex: 0 0 70%; margin-bottom: 15px;">
        <div v-for="(item, idx) in value" :key="idx" style="display: flex; align-items: center; margin-bottom: 5px;">
            <frm-input-smart-data-select
                :disabled="!performanceCodes.length"
                :value="item"
                :data="availablePerformanceCodes[idx]"
                :name="name"
                :required="required"
                @input="updatePerformanceCode(idx, $event)"
            />

            <button class="button button--small"
                    style="margin-left: 10px;"
                    data-testid="delete_performance_code"
                    @click.prevent="deletePerformanceCode(item)"
            >
                <span class="button__icon-group">
                    <frm-icon class="button__icon" name="general-delete" />
                </span>
            </button>
        </div>

        <hr v-if="value.length" class="form-separator">

        <frm-input-smart-data-select
            :key="value.length"
            v-model="addPerformanceCodeId"
            :disabled="!performanceCodes.length"
            :data="allAvailablePerformanceCodes"
            :placeholder="$t('masterfiles.messages.add_performance_code')"
            data-testid="add_performance_code"
        />
    </div>
</template>

<script>

import axios from 'axios';

export default {
    model: {
        prop: 'value',
        event: 'change',
    },

    data() {
        return {
            performanceCodes: [],
            addPerformanceCodeId: null,
        };
    },

    async mounted() {
        this.performanceCodes = (await axios.get(this.performanceCodesRoute)).data.data;
    },

    props: {
        value: {
            type: Array,
            default: () => [],
        },

        name: {
            type: String,
            default: null,
        },

        required: {
            type: Boolean,
            default: false,
        },

        performanceCodesRoute: {
            type: String,
            required: true,
        },
    },

    watch: {
        addPerformanceCodeId(id) {
            if (!id) {
                return;
            }

            this.$emit('change', [...this.value, id]);
            this.addPerformanceCodeId = null;
        },
    },

    computed: {
        availablePerformanceCodes() {
            return this.value.map(id => this.performanceCodes
                .filter(performanceCode => performanceCode.value === id || !this.value.includes(performanceCode.value)));
        },

        allAvailablePerformanceCodes() {
            return this.performanceCodes.filter(performanceCode => !this.value.includes(performanceCode.value));
        },
    },

    methods: {
        deletePerformanceCode(id) {
            this.$emit('change', this.value.filter(valueId => valueId !== id));
        },

        updatePerformanceCode(idx, id) {
            if (!id) {
                return;
            }

            const value = [...this.value];
            value[idx] = id;

            this.$emit('change', value);
        },
    },
};
</script>
