<script>
    import axios from 'axios';
    import SidebarAccordion from './SidebarAccordion.vue';
    import closest from '../services/closest';
    import breakpoint from '../services/breakpoint';
    import debounce from '../services/debounce';

    export default {
        components: {
            'frm-sidebar-accordion': SidebarAccordion,
        },

        props: {
            initiallyOpened: {
                type: Boolean,
                default: false,
            },

            storeStateUri: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                opened: this.initiallyOpened && breakpoint('screen-l'),
                stateStored: null,
                ignoreNextResize: false,
            };
        },

        watch: {
            opened() {
                this.storeState();

                if (this.opened) {
                    this.appHeader.classList.add('app__header--opened');
                    this.appSidebar.classList.add('app__sidebar--opened');
                    this.appMain.classList.add('app__main--opened');
                } else {
                    this.appHeader.classList.remove('app__header--opened');
                    this.appSidebar.classList.remove('app__sidebar--opened');
                    this.appSidebar.classList.remove('app__sidebar--initially-opened');
                    this.appMain.classList.remove('app__main--opened');
                }

                // We dispatch a resize event here because the table headers columns need to be
                // adjusted, if we don't do this, datatables will set a width of 100px to the header columns...
                window.dispatchEvent(new Event('resize'));
            },
        },

        mounted() {
            // Fetch some DOM elements for later use
            this.appHeader = document.querySelector('.app__header');
            this.appSidebar = document.querySelector('.app__sidebar');
            this.appMain = document.querySelector('.app__main');

            if (this.initiallyOpened) {
                this.$el.classList.remove('sidebar--initially-opened');
            }

            if (!breakpoint('screen-l')) {
                this.opened = false;
                this.appSidebar.classList.remove('app__sidebar--initially-opened');
                this.appMain.classList.remove('app__main--opened');
            }

            window.addEventListener('resize', debounce(() => {
                if (this.ignoreNextResize) {
                    this.ignoreNextResize = false;

                    return;
                }

                if (!breakpoint('screen-l')) {
                    this.opened = false;
                }
            }));

            // Close the sidebar (if it's opened) when clicked on body
            window.addEventListener('click', (event) => {
                if (this.opened && !breakpoint('screen-l') && !closest(event.target, '.sidebar')) {
                    this.opened = false;
                }
            });
        },

        updated() {
            if (this.initiallyOpened) {
                this.$el.classList.remove('sidebar--initially-opened');
            }
        },

        methods: {
            /**
             * @param {Event} [event]
             */
            toggle(event) {
                this.ignoreNextResize = true;
                this.opened = !this.opened;

                if (event) {
                    event.currentTarget.blur();
                }
            },

            openAndFocus(id) {
                this.ignoreNextResize = true;
                this.opened = true;

                if (id) {
                    this.$nextTick(() => {
                        document.getElementById(id).focus();
                    });
                }
            },

            /**
             * @param {Boolean} [opened]
             */
            storeState(opened = this.opened) {
                if (!breakpoint('screen-l')) {
                    return;
                }

                this.stateStored = null;

                if (this.$options.previousSource) {
                    this.$options.previousSource.cancel();
                    this.$options.previousSource = null;
                }

                this.$options.previousSource = axios.CancelToken.source();

                axios.post(this.storeStateUri, {
                    opened: opened,
                }, {
                    cancelToken: this.$options.previousSource.token,
                }).then(() => {
                    this.stateStored = true;
                });
            },
        },

        previousSource: null,
    };
</script>
