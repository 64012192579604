export default {
    name: 'calendar_item_without_patient',
    definition: {
        created_by_id: '',
        end: '',
        institute_id: '',
        is_editable: '',
        has_recurring_appointments: '',
        location_id: '',
        start: '',
        title: '',
        note: '',
        appointment_subject: {
            jsonApi: 'hasOne',
            type: 'appointment_subject',
        },
        employees: {
            jsonApi: 'hasMany',
            type: 'employee',
        },
        location: {
            jsonApi: 'hasOne',
            type: 'location',
        },
        location_rooms: {
            jsonApi: 'hasMany',
            type: 'location_room',
        },
        location_devices: {
            jsonApi: 'hasMany',
            type: 'location_device',
        },
    },
    options: {
        collectionPath: 'calendar_items',
    },
};
