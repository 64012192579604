<template>
    <frm-panel class="panel"
               :class="{'panel--attention': isSourcePatient || value, 'panel--grayed-out': isSourcePatient, 'matched-patient-card--selectable': selectable}"
               @click.native.stop="$emit('selected')"
    >
        <template #title>
            <div v-if="selectable" class="form-radio">
                <input :id="'target-'+patientIndex"
                       :checked="value"
                       type="radio"
                       class="form-radio__input"
                       name="targetPatient"
                       @change="$emit('selected')"
                >
                <label class="form-radio__label" :for="'target-'+patientIndex" />
            </div>

            {{ title }}
        </template>
        <div>
            <dl class="definition-list">
                <div class="definition-list__group">
                    <dt class="definition-list__term">
                        {{ $t('patient.fields.identification_number') }}
                    </dt>
                    <dd class="definition-list__definition">
                        {{ identificationNumber }}
                    </dd>
                </div>
                <div class="definition-list__group">
                    <dt class="definition-list__term">
                        {{ $t('patient.fields.birthdate') }}
                    </dt>
                    <dd class="definition-list__definition">
                        {{ birthdateFormatted }}
                    </dd>
                </div>
                <div class="definition-list__group">
                    <dt class="definition-list__term">
                        {{ $t('patient.fields.gender') }}
                    </dt>
                    <dd class="definition-list__definition">
                        {{ $t(`patient.values.gender-denotation-${gender}`) }}
                    </dd>
                </div>
            </dl>

            <hr class="form-separator">

            <div class="form-group-collection">
                <div class="form-group-collection__group">
                    <dl class="definition-list">
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('patient.fields.name-lastname') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ name.lastname_prefix }} {{ name.lastname }}
                            </dd>
                        </div>
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('patient.fields.name-firstname') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ name.firstname }}
                            </dd>
                        </div>
                        <div v-if="name.partner_name_prefix || name.partner_name" class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('patient.fields.name-partner_name-short') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ name.partner_name_prefix }} {{ name.partner_name }}
                            </dd>
                        </div>
                    </dl>
                </div>
                <div class="form-group-collection__group">
                    <dl class="definition-list">
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('patient.fields.name-initials') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ name.initials }}
                            </dd>
                        </div>
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('patient.fields.name-call_name') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ name.call_name }}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <hr class="form-separator">

            <div class="form-group-collection">
                <div class="form-group-collection__group">
                    <dl class="definition-list">
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('masterfiles.fields.street') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ address.street }}
                            </dd>
                        </div>
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('masterfiles.fields.postal_code') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ address.postal_code }}
                            </dd>
                        </div>
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('masterfiles.fields.country') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ country }}
                            </dd>
                        </div>
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('masterfiles.fields.phone') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ address.phone_private }}
                            </dd>
                        </div>
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('masterfiles.fields.phone_work') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ address.phone_work }}
                            </dd>
                        </div>
                    </dl>
                </div>
                <div class="form-group-collection__group">
                    <dl class="definition-list">
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('masterfiles.fields.house_number_only') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ address.house_number }} {{ address.house_number_suffix }}
                            </dd>
                        </div>
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('masterfiles.fields.city') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ address.city }}
                            </dd>
                        </div>
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('masterfiles.fields.email') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ address.email }}
                            </dd>
                        </div>
                        <div class="definition-list__group">
                            <dt class="definition-list__term definition-list__term--smaller">
                                {{ $t('masterfiles.fields.phone_mobile') }}
                            </dt>
                            <dd class="definition-list__definition">
                                {{ address.phone_mobile }}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    </frm-panel>
</template>

<script>
import dateFormatter from '../i18n/dateFormatter';
import dateParser from '../i18n/dateParser';

export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },

        selectable: {
            type: Boolean,
            default: false,
        },

        isOnlyTarget: {
            type: Boolean,
            default: false,
        },

        isSourcePatient: {
            type: Boolean,
            default: false,
        },

        patientIndex: {
            type: Number,
            default: null,
        },

        address: {
            type: Object,
            required: true,
        },

        birthdate: {
            type: String,
            required: true,
        },

        gender: {
            type: String,
            required: true,
        },

        identificationNumber: {
            type: String,
            default: null,
        },

        name: {
            type: Object,
            required: true,
        },

        country: {
            type: String,
            default: null,
        },
    },

    computed: {
        birthdateFormatted() {
            return dateFormatter(dateParser(this.birthdate), 'YYYY-MM-DD');
        },

        title() {
            if (this.isSourcePatient) {
                return `${this.$t('patient.matched_patients.source_patient')}: ${this.name.fullname}`;
            }

            if (this.isOnlyTarget) {
                return `${this.$tc('patient.matched_patients.found_patients', 1)}: ${this.name.fullname}`;
            }

            return `${this.$t('patient.fields.patient')} ${this.patientIndex}: ${this.name.fullname}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.matched-patient-card--selectable {
    cursor: pointer;
}
</style>
