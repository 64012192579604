export default {
    name: 'calendar_location_device_schedule',
    definition: {
        color_location: '',
        device_id: '',
        end: '',
        interval: '',
        location_id: '',
        start: '',
    },
    options: {
        collectionPath: 'calendar/location_device_schedules',
    },
};
