import i18n from '../../i18n';
import DialogManager from '../../services/DialogManager';
import ModalManager from '../../services/modalManager';
import updateQueryStringParameter from '../../services/updateQueryStringParameter';
import UrlQueryHelper from '../../services/UrlQueryHelper';
import axios from 'axios';
import { saveAs } from 'file-saver';
import HandlesActionRestrictions from './HandlesActionRestrictions';
import HandlesErrorResponses from './../HandlesErrorResponses';
import HandlesInstituteFilter from './HandlesInstituteFilter';

const token = document.head.querySelector('meta[name="csrf-token"]');

// @vue/component
export default {
    mixins: [HandlesActionRestrictions, HandlesErrorResponses, HandlesInstituteFilter],
    computed: {
        actions() {
            let actions = this.config.buttons.concat(this.config.contextMenuItems);

            if (this.selectedRowsData.length === 1 && this.selectedRowsData[0].context_menu_items && this.selectedRowsData[0].context_menu_items.data) {
                actions = this.selectedRowsData[0].context_menu_items.data.concat(actions);
            }

            return actions;
        },

        createAction() {
            return this.actions.find(action => action.name === 'create');
        },

        openAction() {
            return this.actions.find(action => action.name === 'open');
        },

        editAction() {
            return this.actions.find(action => action.name === 'edit');
        },

        deleteAction() {
            return this.actions.find(action => action.name === 'delete');
        },
    },

    data() {
        return {
            actionIsLoading: false,
        };
    },

    created() {
        // listen for pageshow event so buttons are enabled again after using back button,
        // since in that case, Vue lifecycle hooks are not triggered.
        window.addEventListener('pageshow', () => {
            this.actionIsLoading = false;
        });
    },

    methods: {
        /**
         * @param {Object} action
         * @param {Event} [event]
         *
         * @returns {*}
         */
        handleAction(action, event) {
            if (this.actionIsLoading || this.actionIsRestricted(action, this.selectedRowsData)) {
                return;
            }

            this.actionIsLoading = true;

            if (this.getActionType(action) === 'close-modal') {
                return ModalManager.closeModal({ data: this.selectedRowsData, action: action.name });
            }

            let target = '_self';
            if (event && (event.ctrlKey === true || event.button === 1)) {
                target = '_blank';
            }

            let handler;

            switch (action.name) {
                case 'create':
                    handler = this.handleCreateAction(action, target);
                    break;
                case 'edit':
                    handler = this.handleEditAction(action, target);
                    break;
                case 'delete':
                    handler = this.handleDeleteAction(action, target);
                    break;
                default:
                    handler = this.handleDefaultAction(action, target);
                    break;
            }

            return handler.then(data => {
                this.$emit('action', { action, data });
            }).finally(() => {
                this.actionIsLoading = false;
            });
        },

        /**
         * @param {Object} action
         * @param {string} target
         *
         * @returns {*}
         */
        handleCreateAction(action, target) {
            // Nothing special for now!
            return this.handleDefaultAction(action, target);
        },

        /**
         * @param {Object} action
         * @param {string} target
         *
         * @returns {*}
         */
        handleEditAction(action, target) {
            if (this.selectedIds.length === 1 && this.readonlyIds.length !== 1) {
                return this.handleDefaultAction(action, target);
            }
        },

        /**
         * @param {Object} action
         * @param {string} target
         *
         * @returns {*}
         */
        handleDeleteAction(action, target) {
            if (this.selectedIds.length > 0 && this.readonlyIds.length === 0) {
                return DialogManager.confirm(i18n.tc('overview.delete-confirmation', this.selectedIds.length))
                    .then(() => {
                        let url = this.parseUrl(action.url);

                        if (this.getActionType(action) === 'regular') {
                            url = updateQueryStringParameter(url, 'redirect_to', window.encodeURIComponent(window.location.toString()));
                        }

                        return this.handleUrl(url, this.getActionType(action), action.httpAction, target);
                    }, () => {
                        // User canceled, all ok
                    });
            }
        },

        /**
         * @param {Object} action
         * @param {string} target
         *
         * @returns {*}
         */
        handleDefaultAction(action, target) {
            return this.handleUrl(this.parseUrl(action.url), this.getActionType(action), action.httpAction, target);
        },

        /**
         * @param {string} url
         * @param {string} type
         * @param {string} method
         * @param {string} target
         *
         * @returns {*}
         */
        handleUrl(url, type, method, target) {
            if (!url) {
                return;
            }

            let modalSize = 'default';

            if (type.startsWith('modal:')) {
                modalSize = type.substring(6);
                type = 'modal';
            }

            if (type === 'modal' && target !== '_self') {
                type = 'regular';
            }

            switch (type) {
                default:
                case 'regular':
                    if (method !== 'GET') {
                        return this.handlePost(url, target, method);
                    } else {
                        return this.openUrl(url, target);
                    }
                case 'modal':
                    return this.openModal(url, modalSize);
                case 'ajax':
                    return this.makeAjaxRequest(url, method);
                case 'download':
                    return this.handleDownload(url, method);
                case 'print':
                    return this.handlePrint(url);
            }
        },

        /**
         * @param {string} url
         *
         * @returns {string}
         */
        parseUrl(url) {
            // Laravel URL encodes special characters so we must first decode the url
            url = window.decodeURIComponent(url);

            // Add selected ids to the url
            url = url.replace(':id', this.selectedIds.join(','));

            // Add row data to the url
            const groupedData = {};
            this.selectedRowsData.forEach(row => {
                Object.keys(row).forEach(key => {
                    groupedData[key] = groupedData[key] || new Set();
                    groupedData[key].add(row[key]);
                });
            });
            Object.keys(groupedData)
                // Sort the keys descending by length to avoid conflicts e.g. ':data.indication_history_id' being replaced with ':data.indication'
                .sort((a, b) => b.length - a.length)
                .forEach(key => {
                    url = url.replace(':data.' + key, Array.from(groupedData[key]).join(','));
                });

            // remove all empty :data.* from url
            url = url.replace(/:data\.[a-zA-Z_]+/g, '');

            if (this.hasInstituteFilter) {
                url = url.replace(`:filter.${this.instituteFilter.attribute}`, this.instituteId);
            }

            // Add active filters to the url
            this.activeFilters
                // Sort the keys descending by length to avoid conflicts e.g. ':data.indication_history_id' being replaced with ':data.indication'
                .sort((a, b) => b.attribute.length - a.attribute.length)
                .forEach(filter => {
                    url = url.replace(':filter.' + filter.attribute, filter.value);
                });
            // Remove other (inactive) filters from the url
            url = url.replace(/:filter\.[a-z_\-0-9]+/g, '');

            // Add parent relationship parameters to url query
            if (this.hasParentRelationship()) {
                url = updateQueryStringParameter(url, 'parent_name', this.config.parentRelationship.name);
                url = updateQueryStringParameter(url, 'parent_id', this.config.parentRelationship.id);
            }

            return url;
        },

        /**
         * @param {string} url
         * @param {string} target
         */
        openUrl(url, target) {
            if (target !== '_self') {
                window.open(url, target);
            } else {
                window.location.href = url;
            }

            // Return pending promise since the page will close
            return new Promise(() => {
            });
        },

        /**
         * @param {string} url
         * @param {string} size
         */
        openModal(url, size) {
            return ModalManager.openModal(url, undefined, undefined, size)
                .then((data) => {
                    this.refresh(() => {
                        if (data && data.id && this.selectedIds.indexOf(data.id) === -1) {
                            this.selectRow(data.id);
                        }
                    });
                    return data;
                });
        },

        /**
         * @param {string} url
         * @param {string} method
         */
        makeAjaxRequest(url, method) {
            return axios({
                method: method.toLowerCase(),
                url,
            }).then(response => {
                if (response.data.message) {
                    DialogManager.alert(response.data.message).then();
                }

                this.refresh();
            }).catch(error => this.handleErrorResponse(error));
        },

        /**
         * @param {Object} action
         *
         * @returns {string}
         */
        getActionType(action) {
            const query = new UrlQueryHelper();
            if (query.isModal()) return action.typeWhenChild;

            return this.config.isChildOverview ? action.typeWhenChild : action.type;
        },

        /**
         * @returns {boolean}
         */
        hasParentRelationship() {
            return this.config.parentRelationship &&
                this.config.parentRelationship.name &&
                this.config.parentRelationship.id;
        },

        /**
         * @param {string} url
         * @param {string} target
         * @param {string} method
         */
        handlePost(url, target, method) {
            const form = document.createElement('form');
            form.method = 'POST';
            form.target = target;
            form.action = url;

            if (method !== 'POST') {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = '_method';
                input.value = method;
                form.appendChild(input);
            }

            if (token) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = '_token';
                input.value = token.content;
                form.appendChild(input);
            }

            // Move query parameters to the body to prevent too long URLs
            const urlObject = new URL(url);
            urlObject.searchParams.forEach((value, key, searchParams) => {
                if (['parent_name', 'parent_id'].includes(key)) {
                    // We want these parameters to stay in the URL
                    return;
                }

                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = value;
                form.appendChild(input);
                searchParams.delete(key);
            });
            form.action = urlObject.toString();

            document.body.appendChild(form);
            form.submit();

            return new Promise(() => {
            });
        },

        async handleDownload(url, method, data) {
            let response;

            try {
                response = await axios({
                    method,
                    url,
                    data,
                    responseType: 'blob',
                });
            } catch (error) {
                return this.handleErrorResponse(error);
            }

            let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0];

            const splitFilename = filename.split('"');

            if (splitFilename.length > 1) {
                filename = splitFilename[1];
            }

            saveAs(response.data, filename);
        },

        async handlePrint(url) {
            window.open(url, '_blank');
        },
    },
};
