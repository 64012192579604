import CalendarItemWithService from './CalendarItemWithService';

export default {
    ...CalendarItemWithService,
    name: CalendarItemWithService.name + '_resource',
    definition: {
        ...CalendarItemWithService.definition,
        calendar_item_participations: {
            ...CalendarItemWithService.definition.calendar_item_participations,
            type: CalendarItemWithService.definition.calendar_item_participations.type + '_resource',
        },
    },
};
