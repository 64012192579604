export default function breakpointValue(breakpoint) {
    const breakpoints = require('../../sass/variables/_breakpoints.json');
    let value = breakpoints[breakpoint];

    if (value && value.startsWith('$')) {
        value = breakpoints[value.substring(1)];
    }

    if (!value) {
        throw new Error('Breakpoint ' + breakpoint + ' not found');
    }

    return parseInt(value.replace('px', ''), 10);
}
