export default {
    name: 'calendar_item_with_service',
    definition: {
        created_by_id: '',
        end: '',
        institute_id: '',
        is_editable: '',
        has_recurring_appointments: '',
        location_id: '',
        service_id: '',
        start: '',
        send_confirmation: '',
        send_reminder: '',
        note: '',
        calendar_item_participations: {
            jsonApi: 'hasMany',
            type: 'calendar_item_with_service_participation',
            include: true,
        },
        employees: {
            jsonApi: 'hasMany',
            type: 'employee',
        },
        location: {
            jsonApi: 'hasOne',
            type: 'location',
        },
        location_rooms: {
            jsonApi: 'hasMany',
            type: 'location_room',
        },
        location_devices: {
            jsonApi: 'hasMany',
            type: 'location_device',
        },
        service: {
            jsonApi: 'hasOne',
            type: 'service',
        },
    },
    options: {
        collectionPath: 'calendar_items',
    },
};
