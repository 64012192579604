import Vue from 'vue';
import VueI18n from 'vue-i18n';
import merge from 'deepmerge';
import moment from 'moment';
import './locales/moment';
import ElementLocale from 'element-ui/lib/locale';
import ElementLocaleSrc from 'element-ui/src/locale';
import elementUILocales from './locales/element-ui';
import dateParser from './dateParser';
import dateTimeFormats from './dateTimeFormats';
import numberFormats from './numberFormats';
import translations from './translations';

Vue.use(VueI18n);

/**
 * All supported locales and their mapping from the html
 * lang attribute to the format used in JS.
 */
const LOCALE_MAPPING = {
    de: 'de',
    en: 'en-GB',
    es: 'es',
    fr: 'fr',
    nl: 'nl',
    zh: 'zh-CN',
};

const locale = LOCALE_MAPPING[document.documentElement.lang];

moment.locale(locale.toLowerCase());

const i18n = new VueI18n({
    locale,
    dateTimeFormats: {
        [locale]: dateTimeFormats,
    },
    numberFormats: {
        [locale]: numberFormats,
    },
    messages: merge(
        elementUILocales,
        {
            [locale]: translations,
        }
    ),
});

// Add vue-i18n compatibility to element-ui
// N.B. components/Calendar/InlineDatePicker.vue uses element-ui source files so we also need to make that compatible...
ElementLocale.i18n((key, value) => i18n.t(key, value));
ElementLocaleSrc.i18n((key, value) => i18n.t(key, value));

// VueI18n can only format Date objects and we use strings so we register a custom helper
// which uses the dateParser to convert our strings to dates before formatting.
Object.defineProperty(i18n, 'ds', {
    get() {
        return function (date, ...options) {
            return i18n.d(dateParser(date), ...options);
        };
    },
});
Object.defineProperty(Vue.prototype, '$ds', {
    get() {
        return i18n.ds;
    },
});

const measurementMapping = {
    years: 'y',
    year: 'y',
    months: 'M',
    month: 'M',
    weeks: 'w',
    week: 'w',
    days: 'd',
    day: 'd',
    hours: 'h',
    hour: 'h',
    minutes: 'm',
    minute: 'm',
    seconds: 's',
    second: 's',
};

// Helper function to return a relative date string from a date string
Object.defineProperty(i18n, 'dr', {
    get() {
        return function (date, measurement, withoutSuffix) {
            date = moment(dateParser(date));

            if (!date.isValid()) {
                return date.localeData().invalidDate();
            }

            if (!measurement) {
                return date.fromNow(withoutSuffix);
            }

            const key = measurementMapping[measurement] || measurement;
            const now = moment();

            // If measurement is not based on time, copy the time of the date to round the difference to days.
            if (!['h', 'm', 's'].includes(key)) {
                now.hour(date.hour());
                now.minute(date.minute());
                now.second(date.second());
                now.millisecond(date.millisecond());
            }

            const locale = date.localeData();
            const diff = date.diff(now);
            const number = Math.abs(date.diff(now, measurement));

            let output = locale.relativeTime(number, !!withoutSuffix, number > 0 && number <= 1 ? key : key + key, diff > 0);

            if (!withoutSuffix) {
                output = locale.pastFuture(diff, output);
            }

            return locale.postformat(output);
        };
    },
});

Object.defineProperty(Vue.prototype, '$dr', {
    get() {
        return i18n.dr;
    },
});

// Add shortcut methods for a currency formatter based on cents
Object.defineProperty(i18n, 'c', {
    get() {
        return function (amount) {
            return i18n.n(amount / 100, 'currency');
        };
    },
});
Object.defineProperty(Vue.prototype, '$c', {
    get() {
        return i18n.c;
    },
});

export default i18n;
