<script>
import axios from 'axios';
import Form from '../Form.vue';
import DialogManager from '../../services/DialogManager';

export default {
    extends: Form,

    methods: {
        async deleteCertificate() {
            try {
                await DialogManager.confirm(this.$t('masterfiles.institute.certificates.delete-confirmation'));
            } catch (error) {
                return;
            }

            await axios.delete(`/settings/institutes/certificates/${this.formData.uzi_certificate_id}`);
            this.formData.uzi_certificate_id = null;
        },
    },
};
</script>
