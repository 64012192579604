<script>
    import Form from '../Form.vue';

    export default {
        extends: Form,

        props: {
            existingDigitarContracts: {
                type: Array,
                required: true,
            },
        },

        computed: {
            digitarContractExists() {
                return this.existingDigitarContracts.includes(this.formData.digitar_contract);
            },
        },
    };
</script>
