<script>
import Form from '../Form.vue';
import ModalManager from '../../services/modalManager';
import axios from 'axios';

export default {
    extends: Form,

    props: {
        phone: {
            type: String,
            default: null,
        },
    },

    computed: {
        patient() {
            return [{
                label: this.formData.patient_name,
                value: this.formData.patient_id,
            }];
        },
    },

    watch: {
        'formData.patient_id'(newValue, oldValue) {
            if (newValue !== oldValue && newValue) {
                this.setPatientMobileInfo(newValue);
            }
        },
    },

    methods: {
        async showCreatePatientModal(query = '') {
            const patient = await ModalManager.openModal(`/patient/create?query=${query}`, undefined, undefined, 'full');

            this.formData.patient_id = patient.id;
            this.formData.patient_name = patient.name.fullname;
        },

        async setPatientMobileInfo(patientId) {
            const patientInfo = (await axios.get('/calendar/contact-requests/patient-info', { params: { patient_id: patientId } })).data;

            if (!this.phone && patientInfo.phone) {
                this.formData.phone = patientInfo.phone;
            } else if (!this.phone && !patientInfo.phone) {
                this.formData.phone = null;
            }

            this.formData.patient_name = patientInfo.name;
        },
    },
};
</script>
