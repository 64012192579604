<template>
    <div class="frm-input-note-textarea">
        <textarea
            ref="input"
            :disabled="disabled"
            :name="name"
            :required="required"
            :value="value"
            :class="textareaClass"
            class="form-textarea"
            autocomplete="off"
            @change="emitNewValue($event.target.value)"
        />
        <button
            :disabled="disabled"
            class="frm-input-note-textarea__button"
            type="button"
            @click="insertNote()"
        >
            <frm-icon
                name="stopwatch"
                class="frm-input-note-textarea__button-icon"
            />
        </button>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    inheritAttrs: false,

    props: {
        name: {
            type: String,
            default: null,
        },

        currentEmployee: {
            type: String,
            required: true,
        },

        value: {
            type: String,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        required: {
            type: Boolean,
            default: false,
        },

        textareaClass: {
            type: String,
            default: '',
        },

        autofocus: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        value(newValue) {
            this.setValue(newValue);
        },
    },

    mounted() {
        this.init();

        if (this.autofocus) {
            this.focus();
        }
    },

    methods: {
        init() {
            if (this.value) {
                this.setValue(this.value);
            }
        },

        insertNote() {
            const content = `${this.currentEmployee}, ${moment(new Date()).format('DD-MM-YYYY HH:mm:ss')}`;

            let currentValue = this.value;

            if (currentValue && !currentValue.trim()) {
                currentValue = null;
            }

            const dashes = '-'.repeat(content.length);

            const newValue = [currentValue, currentValue ? `\n${content}\n\n${dashes}\n` : `${content}\n\n${dashes}\n`].filter(Boolean).join('');
            this.emitNewValue(newValue);

            setTimeout(() => {
                this.$refs.input.focus();
                this.$refs.input.setSelectionRange(this.$refs.input.value.length, this.$refs.input.value.length);
            });
        },

        setValue(content) {
            this.$refs.input.value = content;
        },

        emitNewValue(value) {
            this.$emit('input', value);
            this.$emit('change', value);
        },

        focus($event) {
            this.$refs.input.focus();
            this.$emit('focus', $event);
        },

        blur($event) {
            this.$refs.input.blur();
            this.$emit('blur', $event);
        },
    },
};
</script>
