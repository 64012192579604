<template>
    <div>
        <slot :items="items" :change="change" />
    </div>
</template>

<script>
    export default {
        props: {
            initialItems: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                items: JSON.parse(this.initialItems).data,
            };
        },

        methods: {
            change(items) {
                this.items = items;
            },
        },
    };
</script>
