export default {
    name: 'indication_history_overview_dto',
    definition: {
        body_location: '',
        caregiver_agb_code: '',
        consult_conclusion: '',
        consult_conclusion_fixed_id: '',
        diagnosis_code: '',
        employee_id: '',
        employee_name: {},
        general_practitioner_id: '',
        general_practitioner_name: {},
        in_treatment: '',
        indication_date: '',
        indication_description: '',
        indication_id: '',
        insurer_name: '',
        insurer_uzovi: '',
        is_chronic: '',
        is_finished: '',
        is_finished_reason: '',
        number_of_treatments: '',
        pathology_code: '',
        patient_age: '',
        patient_birthdate: '',
        patient_gender: '',
        patient_id: '',
        patient_name: {},
        referrer_id: '',
        referrer_name: {},
        screening_conclusion: '',
        screening_conclusion_fixed_id: '',
        treatment_origin: '',
        treatment_plan_description: '',
        treatment_plan_id: '',
    },
    options: {
        collectionPath: 'indication_histories_overview',
    },
};
