<script>
    import DialogManager from '../../../services/DialogManager';
    import ModalManager from '../../../services/modalManager';
    import updateQueryStringParameter from '../../../services/updateQueryStringParameter';
    import Form from '../../Form.vue';
    import axios from 'axios';
    import moment from 'moment';
    import merge from 'deepmerge';
    import i18n from '../../../i18n';
    import MatchedPatients from '../../MatchedPatients.vue';

    export default {
        extends: Form,

        props: {
            currentDate: {
                type: String,
                required: true,
            },

            countryNetherlandsId: {
                type: String,
                required: true,
            },

            matchedPatients: {
                type: Array,
                default: () => [],
            },

            mergePatientsUrl: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                initialFinancialRelationNumber: null,
            };
        },

        mounted() {
            this.initialFinancialRelationNumber = this.formData.patient_financial.financial_relation_number;

            if (this.matchedPatients.length) {
                this.showMatchedPatientsModal();
            }
        },

        computed: {
            age() {
                const { birthdate, deceased } = this.formData;

                if (!birthdate) {
                    return null;
                }

                const start = new Date(birthdate);
                const end = new Date(deceased ? this.formData.deceased_date : this.currentDate);

                const duration = moment.duration(moment(end).diff(start));

                const years = duration.years();
                const months = duration.months();

                const yearText = i18n.t('patient.age.terms.year');
                const monthText = months === 1 ? i18n.t('patient.age.terms.month') : i18n.t('patient.age.terms.months');

                if (years < 2) {
                    return `${years} ${yearText} ${months} ${monthText}`;
                } else {
                    return `${years} ${yearText}`;
                }
            },

            countryIsNetherlands() {
                return String(this.formData.address.country_id) === this.countryNetherlandsId;
            },

            enableRequestPersonalData() {
                return Boolean(this.formData.identification_number) ||
                    Boolean(this.formData.name.lastname && this.formData.address.postal_code && this.formData.address.house_number && this.formData.gender && this.formData.birthdate);
            },

            enableBsnVerification() {
                return this.formData.identification_number && this.formData.name.lastname && this.formData.birthdate && this.formData.gender;
            },

            enableIdentificationDataVerification() {
                return this.formData.identification_number && this.formData.identification_data.document_type && this.formData.identification_data.document_number;
            },

            showFinancialRelationNumberChangedWarning() {
                if (!this.initialFinancialRelationNumber) {
                    return false;
                }

                return this.initialFinancialRelationNumber !== this.formData.patient_financial.financial_relation_number;
            },
        },

        watch: {
            'formData.name.lastname'(value) {
                this.formData.name.lastname = this.capitalizeFirst(value);
            },
            'formData.name.firstname'(value) {
                this.formData.name.firstname = this.capitalizeFirst(value);
            },
            'formData.name.call_name'(value) {
                this.formData.name.call_name = this.capitalizeFirst(value);
            },
            'formData.name.initials'(value) {
                this.formData.name.initials = this.capitalizeAll(value);
            },
            'formData.name.partner_name'(value) {
                this.formData.name.partner_name = this.capitalizeFirst(value);
            },
            'formData.address.street'(value) {
                this.formData.address.street = this.capitalizeFirst(value);
            },
            'formData.address.postal_code'(value) {
                this.formData.address.postal_code = this.capitalizeAll(value);
            },
            'formData.address.city'(value) {
                this.formData.address.city = this.capitalizeFirst(value);
            },
        },

        methods: {
            showMatchedPatientsModal() {
                ModalManager.openModal(
                    MatchedPatients,
                    {
                        sourcePatient: this.formData,
                        matchedPatients: this.matchedPatients,
                        mergePatientsUrl: this.mergePatientsUrl,

                    },
                    undefined,
                    this.matchedPatients.length === 1 ? 'large' : 'full',
                    false
                );
            },

            resetFinancialRelationNumber() {
                this.formData.patient_financial.financial_relation_number = this.initialFinancialRelationNumber;
            },

            requestPersonalData(event) {
                if (!this.enableRequestPersonalData) {
                    return DialogManager.alert(this.$t('patient.sbvz.request-personal-data.required-fields-missing'));
                }

                let url = event.currentTarget.dataset.url;
                this.formData.identification_number && (url = updateQueryStringParameter(url, 'identification_number', this.formData.identification_number));
                this.formData.name.lastname && (url = updateQueryStringParameter(url, 'name[lastname]', this.formData.name.lastname));
                this.formData.name.lastname_prefix && (url = updateQueryStringParameter(url, 'name[lastname_prefix]', this.formData.name.lastname_prefix));
                this.formData.name.initials && (url = updateQueryStringParameter(url, 'name[initials]', this.formData.name.initials));
                this.formData.name.firstname && (url = updateQueryStringParameter(url, 'name[firstname]', this.formData.name.firstname));
                this.formData.name.call_name && (url = updateQueryStringParameter(url, 'name[call_name]', this.formData.name.call_name));
                this.formData.address.street && (url = updateQueryStringParameter(url, 'address[street]', this.formData.address.street));
                this.formData.address.house_number && (url = updateQueryStringParameter(url, 'address[house_number]', this.formData.address.house_number));
                this.formData.address.house_number_suffix && (url = updateQueryStringParameter(url, 'address[house_number_suffix]', this.formData.address.house_number_suffix));
                this.formData.address.postal_code && (url = updateQueryStringParameter(url, 'address[postal_code]', this.formData.address.postal_code));
                this.formData.address.city && (url = updateQueryStringParameter(url, 'address[city]', this.formData.address.city));
                this.formData.birthdate && (url = updateQueryStringParameter(url, 'birthdate', this.formData.birthdate));
                this.formData.gender && (url = updateQueryStringParameter(url, 'gender', this.formData.gender));

                return ModalManager.openModal(url)
                    .then((data) => {
                        if (data !== null && typeof data === 'object') {
                            this.formData = merge(this.formData, data);
                            this.formData.cov_check = true;
                        }
                    });
            },

            verifyBsn(event) {
                if (!this.enableBsnVerification) {
                    return DialogManager.alert(this.$t('patient.sbvz.bsn-verification.required-fields-missing'));
                }

                const url = event.currentTarget.dataset.url;
                const data = {
                    bsn: this.formData.identification_number,
                    lastname: this.formData.name.lastname,
                    birthdate: this.formData.birthdate,
                    gender: this.formData.gender,
                };

                return this.verifyData(url, data)
                    .then((valid) => {
                        if (valid) {
                            return DialogManager.alert(this.$t('patient.sbvz.bsn-verification.valid'));
                        }

                        return DialogManager.alert(this.$t('patient.sbvz.bsn-verification.invalid'));
                    })
                    .catch(() => {
                        return DialogManager.alert(this.$t('base.errors.general'));
                    });
            },

            verifyIdentificationData(event) {
                if (!this.enableIdentificationDataVerification) {
                    return DialogManager.alert(this.$t('patient.sbvz.wid-check.required-fields-missing'));
                }

                const url = event.currentTarget.dataset.url;
                const data = {
                    bsn: this.formData.identification_number,
                    document_type: this.formData.identification_data.document_type,
                    document_number: this.formData.identification_data.document_number,
                };

                return this.verifyData(url, data)
                    .then((valid) => {
                        if (valid) {
                            return DialogManager.alert(this.$t('patient.sbvz.wid-check.valid'));
                        }

                        return DialogManager.alert(this.$t('patient.sbvz.wid-check.invalid'));
                    })
                    .catch(() => {
                        return DialogManager.alert(this.$t('base.errors.general'));
                    });
            },

            verifyData(url, data) {
                if (this.$options.previousSource) {
                    this.$options.previousSource.cancel();
                    this.$options.previousSource = null;
                }

                this.$options.previousSource = axios.CancelToken.source();

                return axios
                    .post(url, data, {
                        cancelToken: this.$options.previousSource.token,
                    })
                    .then(response => {
                        return response.data.data.result === 'G';
                    });
            },

            capitalizeFirst(value) {
                if (value === '') {
                    return value;
                }

                const capitalizedFirst = value[0].toUpperCase();
                const rest = value.slice(1);

                return capitalizedFirst + rest;
            },

            capitalizeAll(value) {
                return value.toUpperCase();
            },
        },

        previousSource: null,
    };
</script>
