import createArrayFromNodeList from '../services/createArrayFromNodeList';

/**
 * @param {HTMLInputElement} el
 */
export const bind = function (el) {
    if (!(el instanceof HTMLInputElement) || el.type !== 'checkbox') {
        throw new Error('This directive can only be used on checkboxes.');
    }
};

/**
 * @param {HTMLInputElement} el
 * @param {{arg: String}} binding
 */
export const inserted = function (el, binding) {
    update(el, binding);
};

/**
 * @param {HTMLInputElement} el
 * @param {{arg: String}} binding
 */
export const update = function (el, binding) {
    const elements = document.getElementsByName(el.name);
    const checkedElements = createArrayFromNodeList(elements).filter(el => el.checked);

    el.required = (checkedElements.length < (binding.arg || 1));
};
