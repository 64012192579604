import Vue from 'vue';
import breakpoint from '../../services/breakpoint';
import ModalManager from '../../services/modalManager';

// @vue/component
export default {
    inheritAttrs: false,

    props: {
        actionOverview: {
            type: Vue,
            default: null,
        },

        actionOverviewConfig: {
            type: Object,
            default() {
                return {};
            },
        },

        actionRecipientOverview: {
            type: Vue,
            default: null,
        },

        actionRecipientOverviewConfig: {
            type: Object,
            default() {
                return {};
            },
        },

        messageRecipientOverview: {
            type: Vue,
            default: null,
        },

        messageRecipientOverviewConfig: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    data() {
        return {
            actionTab: 'received',
            zorgMailTab: 'zorgmail_received',
        };
    },

    computed: {
        actionCount() {
            return this.$store.state.counters.dashboard.actions;
        },

        actionOverviewConfigEnriched() {
            return {
                ...this.actionOverviewConfig,
                height: this.getOverviewHeight,
            };
        },

        actionRecipientOverviewConfigEnriched() {
            return {
                ...this.actionRecipientOverviewConfig,
                height: this.getOverviewHeight,
            };
        },

        messageCount() {
            return this.$store.state.counters.dashboard.messages;
        },

        messageRecipientOverviewConfigEnriched() {
            return {
                ...this.messageRecipientOverviewConfig,
                height: this.getOverviewHeight,
            };
        },
    },

    methods: {
        setActionTab(actionTab) {
            this.actionTab = actionTab;
        },

        setZorgMailTab(zorgMailTab) {
            this.zorgMailTab = zorgMailTab;
        },

        createAction() {
            ModalManager.openModal('/dashboard/sent-actions/create')
                .then(() => {
                    this.actionOverview.refresh();
                    this.actionRecipientOverview.refresh();
                });
        },

        createMessage() {
            ModalManager.openModal('/dashboard/sent-messages/create')
                .then(() => {
                    this.messageRecipientOverview.refresh();
                });
        },

        refreshCounters() {
            this.$store.dispatch('counters/updateBundle', 'dashboard');
        },

        getOverviewHeight() {
            if (!breakpoint('screen-l')) {
                return 300; // N.B. Should be a multiple of row height (60)
            }

            return document.querySelector('.dashboard__section').offsetHeight - 40;
        },
    },
};
