import * as shvl from 'shvl';
import md5 from 'md5';
import { createPersistedState } from '../../helpers';

const PATHS = [
    'activeFilters',
    'dataTablesState',
    'fieldValueQuery',
    'searchQuery',
    'showFilters',
    'showOnlyActive',
    'showOnlyCurrent',
];

export default {
    namespaced: true,
    plugins: [
        createPersistedState({
            key: `vuex-overview-${md5(window.location.pathname + window.location.search)}`,
            ttl: 6 * 60 * 60 * 1000,
            reducer(state) {
                return {
                    overview: Object.keys(state.overview || {}).reduce((substate, key) => {
                        return shvl.set(substate, key, PATHS.reduce((overviewSubstate, path) => {
                            return shvl.set(overviewSubstate, path, shvl.get(state.overview[key], path));
                        }, {}));
                    }, {}),
                };
            },
        }),
    ],
};
