import axios from 'axios';

export const updateAll = (context) => {
    return Promise.all(Object.keys(context.state).map(bundle => context.dispatch('updateBundle', bundle)));
};

export const updateBundle = (context, bundle) => {
    return axios.get(`/${bundle}/counters`)
        .then(response => {
            context.commit('updateBundle', { bundle, counters: response.data });
        })
        .catch(() => {
            // TODO: Do something with the error.
        });
};
