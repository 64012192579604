<template>
    <div class="form-avatar">
        <input
            :id="id"
            :name="name"
            :required="required"
            :value="value"
            type="hidden"
        >
        <div class="form-avatar__wrapper">
            <figure class="form-avatar__avatar">
                <div v-if="loading" class="spinner form-avatar__spinner">
                    <div class="spinner__dot" />
                    <div class="spinner__dot" />
                    <div class="spinner__dot" />
                </div>
                <img v-else-if="value" :src="previewUrl" class="form-avatar__photo">
                <frm-icon v-else class="form-avatar__icon" name="patient" />
            </figure>
            <button
                v-if="value"
                :title="$t('form.avatar.delete')"
                class="form-avatar__reset"
                type="button"
                @click="reset"
            >
                <frm-icon class="form-avatar__reset-icon" name="cross" />
            </button>
            <input
                :id="inputId"
                :class="{'form-avatar__input--focus': inputFocused}"
                accept="image/*"
                class="form-avatar__input"
                type="file"
                @blur="onBlur"
                @change="onChange"
                @focus="onFocus"
            >
            <label :for="inputId" class="button button--small button--secondary form-avatar__button">
                {{ buttonLabel }}
            </label>
        </div>
    </div>
</template>

<script>
    import DialogManager from '../../services/DialogManager';
    import axios from 'axios';

    let identifier = 1;

    export default {
        props: {
            name: {
                type: String,
                default: null,
            },

            id: {
                type: String,
                default: 'avatar_input_' + identifier++,
            },

            required: {
                type: Boolean,
                default: false,
            },

            value: {
                type: [String, Number],
                default: '',
            },
        },

        data() {
            return {
                inputFocused: false,
                loading: false,
            };
        },

        computed: {
            buttonLabel() {
                return this.value ? this.$t('form.avatar.change') : this.$t('form.avatar.add');
            },

            inputId() {
                return this.id + '_file_input';
            },

            previewUrl() {
                return this.value ? this.getPreviewUrl(this.value) : null;
            },
        },

        methods: {
            /**
             * @param {Event} e
             */
            onChange(e) {
                // Do we have a file?
                if (!e.target.files || !e.target.files[0]) {
                    this.$emit('input', '');
                    this.$emit('change', '');

                    return;
                }

                // Is that file an image?
                if (!e.target.files[0].type.match('image/.*')) {
                    return DialogManager.alert(this.$t('form.avatar.file-not-supported'));
                }

                this.loading = true;

                const data = new FormData();
                data.append('category', 'photos');
                data.append('file', e.target.files[0]);

                if (this.$options.previousSource) {
                    this.$options.previousSource.cancel();
                    this.$options.previousSource = null;
                }

                this.$options.previousSource = axios.CancelToken.source();

                return axios
                    .post('/assets/attachments', data, {
                        cancelToken: this.$options.previousSource.token,
                    })
                    .then(response => {
                        this.$emit('input', response.data.id);
                        this.$emit('change', response.data.id);

                        // Preload the image to prevent a "Flash Of Missing Avatar"™
                        return new Promise((resolve, reject) => {
                            const img = new Image();
                            img.onload = resolve;
                            img.onerror = reject;
                            img.src = this.getPreviewUrl(response.data.id);
                        });
                    })
                    .catch(error => {
                        if (error.response) {
                            return DialogManager.alert(this.$t('form.avatar.upload-failed', { error: error.response.data.message }));
                        }

                        return DialogManager.alert(this.$t('form.avatar.upload-failed', { error: 'Unknown' }));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            onFocus() {
                this.inputFocused = true;
            },

            onBlur() {
                this.inputFocused = false;
            },

            reset() {
                this.$emit('input', '');
                this.$emit('change', '');
            },

            /**
             * @param {String|Number} id
             *
             * @returns {String}
             */
            getPreviewUrl(id) {
                return '/assets/attachments/thumbnails/' + id;
            },
        },

        previousSource: null,
    };
</script>
