<template>
    <section class="modal-component">
        <header v-if="$slots.header" class="modal-component__header">
            <h1 class="modal-component__title">
                <slot name="header" />
            </h1>
        </header>

        <div class="modal-component__content">
            <slot />
        </div>

        <div class="modal-component__buttons modal-component__buttons--sticky">
            <div class="button-group">
                <button :disabled="saveDisabled || loading" class="button button--grouped" type="button" @click="$emit('save')">
                    <span class="button__icon-group">
                        <frm-spinner v-if="loading" white />
                        <frm-icon v-else class="button__icon" name="check" />
                        <span>{{ saveLabel || $t('masterfiles.actions.save') }}</span>
                    </span>
                </button>
                <a class="button button--text button--grouped" @click.prevent="closeModal">
                    {{ $t('masterfiles.actions.cancel') }}
                </a>
            </div>
        </div>
    </section>
</template>

<script>
    import ModalManager from '../services/modalManager';

    export default {
        props: {
            saveDisabled: {
                type: Boolean,
                default: false,
            },

            saveLabel: {
                type: String,
                default: null,
            },

            loading: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            closeModal() {
                ModalManager.closeModal();
            },
        },
    };
</script>
