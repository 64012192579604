<template>
    <frm-modal-component
        :loading="loading"
        :save-disabled="!targetPatientId"
        :save-label="$t('patient.matched_patients.merge')"
        @save="submit()"
    >
        <div class="form-group-collection__group form-group-collection__info">
            <div class="information-list__info">
                <frm-icon class="information-list__icon" name="info" />
                <span data-testid="matched-patients:message" v-html="$tc('patient.matched_patients.message', matchedPatients.length)" />
            </div>
        </div>

        <div class="matched-patients" :class="{'matched-patients--multiple-targets': !hasSingleTarget}">
            <div class="matched-patients__source">
                <h3 v-if="!hasSingleTarget">
                    {{ $t('patient.matched_patients.source_patient') }}
                </h3>
                <MatchedPatientCard
                    class="matched-patients__source-card"
                    v-bind="sourcePatient"
                    :identification-number="sourcePatient.identification_number"
                    is-source-patient
                    data-testid="matched-patients:source-patient"
                />
            </div>

            <div class="matched-patients__targets">
                <h3 v-if="!hasSingleTarget">
                    {{ $tc('patient.matched_patients.found_patients', matchedPatients.length) }}
                </h3>
                <div class="matched-patients__list">
                    <MatchedPatientCard
                        v-for="(patient, patientIdx) in matchedPatients"
                        :key="patientIdx"
                        :value="targetPatientId === patient.id"
                        :selectable="!hasSingleTarget"
                        :is-only-target="hasSingleTarget"
                        :identification-number="patient.identification_number"
                        :patient-index="patientIdx + 1"
                        class="matched-patients__list-card"
                        v-bind="patient"
                        data-testid="matched-patients:target-patient"
                        @selected="targetPatientId = patient.id"
                    />
                </div>
            </div>
        </div>
    </frm-modal-component>
</template>

<script>
import MatchedPatientCard from './MatchedPatientCard.vue';
import axios from 'axios';
import HandlesErrorResponses from '../mixins/HandlesErrorResponses';
import UrlQueryHelper from '../services/UrlQueryHelper';
import ModalManager from '../services/modalManager';

export default {
    components: {
        MatchedPatientCard,
    },

    mixins: [
        HandlesErrorResponses,
    ],

    data() {
        return {
            targetPatientId: this.matchedPatients.length === 1 ? this.matchedPatients[0].id : null,
            loading: false,
        };
    },

    computed: {
        hasSingleTarget() {
            return this.matchedPatients.length === 1;
        },
    },

    props: {
        sourcePatient: {
            type: Object,
            required: true,
        },

        matchedPatients: {
            type: Array,
            required: true,
        },

        mergePatientsUrl: {
            type: String,
            required: true,
        },
    },

    methods: {
        async submit() {
            this.loading = true;

            try {
                const response = await axios.post(this.mergePatientsUrl, {
                    target_patient_id: this.targetPatientId,
                    modal: (new UrlQueryHelper()).isModal(),
                });

                ModalManager.closeModal();

                window.location.assign(response.data.redirect_url);
            } catch (err) {
                await this.handleErrorResponse(err);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.matched-patients {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: rhythm(2);
    justify-content: space-between;

    &--multiple-targets {
        @include breakpoint($screen-l) {
            flex-direction: row;
        }
    }
}

.matched-patients__source,
.matched-patients__targets {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.matched-patients__list-card {
    &:not(:last-child) {
        margin-bottom: rhythm(1);
    }
}

.matched-patients__source-card {
    @include breakpoint($screen-l) {
        position: sticky;
        top: 20px;
    }
}

</style>
