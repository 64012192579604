<script>
import Form from '../Form.vue';
import HandlesZorgmailAccount from '../../mixins/HandlesZorgmailAccount';
import HandlesInstitute from '../../mixins/HandlesInstitute';
import handlesErrorResponses from '../../mixins/HandlesErrorResponses';
import axios from 'axios';

export default {
        extends: Form,

        mixins: [
            HandlesInstitute,
            HandlesZorgmailAccount,
            handlesErrorResponses,
        ],

        props: {
            isNew: {
                type: Boolean,
                required: true,
            },

            instituteChosenByClient: {
                type: Boolean,
                required: true,
            },

            templatesUrl: {
                type: String,
                required: true,
            },

            initialTemplates: {
                type: Array,
                required: true,
            },

            recipients: {
                type: Array,
                required: true,
            },
        },

        computed: {
            template() {
                return this.templates.find(template => String(template.id) === String(this.formData.template_id));
            },

            templateType() {
                return this.template ? this.template.template_type : null;
            },

            recipientFromTemplate() {
                if (!this.template) {
                    return undefined;
                }

                if (!this.template.recipient_type) {
                    return undefined;
                }

                return this.recipients.find(recipient => recipient.recipient_role === this.template.recipient_type);
            },
        },

        data() {
            return {
                fetchingTemplates: false,
                templates: [],
            };
        },

        methods: {
            async updateTemplates() {
                this.fetchingTemplates = true;
                try {
                    this.templates = (await axios.get(this.templatesUrl, {
                        params: {
                            institute_id: this.formData.institute_id,
                        },
                    })).data.data;

                    this.formData.template_id = '';
                } catch (error) {
                    this.formData.template_id = '';

                    await this.handleErrorResponse(error);
                } finally {
                    this.fetchingTemplates = false;
                }
            },
        },

        mounted() {
            if (this.isNew && !this.instituteChosenByClient &&
                String(this.instituteId) !== String(this.formData.institute_id)
            ) {
                this.formData.institute_id = this.instituteId;
            }

            this.formData.send_using_zorgmail_account_id = this.zorgmailAccountId;
            this.templates = this.initialTemplates;
        },

        watch: {
            'formData.institute_id'() {
                this.instituteId = this.formData.institute_id;
                this.updateTemplates();
            },
            'formData.template_id': {
                handler(newValue, oldValue) {
                    if (String(newValue) === String(oldValue)) {
                        return;
                    }

                    if (this.recipientFromTemplate) {
                        this.formData.recipient_id = this.recipientFromTemplate.value;
                    }
                },
                immediate: true,
            },
            'formData.send_using_zorgmail_account_id'(value) {
                this.zorgmailAccountId = value;
            },
        },
    };
</script>
