<template>
    <select
        :id="id"
        :disabled="disabled || loading"
        :name="name"
        :value="value"
        @change="change($event)"
    >
        <option v-if="loading" :value="null">
            {{ $t('form.ajaxselect.loading') }}
        </option>
        <option v-if="placeholder && !value && !loading" :value="null" disabled>
            {{ placeholder }}
        </option>
        <template v-if="!loading">
            <option v-if="nullable" :value="null" />
            <option v-for="(option, index) in options" :key="index" :value="option.value">
                {{ option.label }}
            </option>
        </template>
    </select>
</template>

<script>
    import axios from 'axios';

    export default {
        props: {
            name: {
                type: String,
                default: null,
            },

            id: {
                type: String,
                default: null,
            },

            value: {
                type: [Number, String],
                default: null,
            },

            placeholder: {
                type: String,
                default: '',
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            nullable: {
                type: Boolean,
                default: false,
            },

            uri: {
                type: String,
                required: true,
            },

            dependencies: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                options: [],
                loading: false,
            };
        },

        computed: {
            allDependenciesHaveAValue() {
                return Object.values(this.dependencies).every(Boolean);
            },
        },

        watch: {
            dependencies: {
                immediate: true,
                handler(newValue, oldValue) {
                    if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
                        return;
                    }

                    if (this.allDependenciesHaveAValue) {
                        this.fetchOptions();
                    } else {
                        this.options = [];
                    }
                },
            },

            options(newOptions) {
                if (!Array.isArray(newOptions)) {
                    return;
                }

                const exists = Boolean(newOptions.find(option => String(option.value) === String(this.value)));

                if (exists) {
                    this.$nextTick(() => {
                        this.$el.value = this.value;
                    });
                } else {
                    this.$emit('input', null);
                    this.$emit('change', null);

                    this.$nextTick(() => {
                        this.$el.selectedIndex = -1;
                    });
                }
            },
        },

        methods: {
            fetchOptions() {
                this.loading = true;

                if (this.$options.previousSource) {
                    this.$options.previousSource.cancel();
                    this.$options.previousSource = null;
                }

                this.$options.previousSource = axios.CancelToken.source();

                return axios
                    .get(this.uri, {
                        params: this.dependencies,
                        cancelToken: this.$options.previousSource.token,
                    })
                    .then(response => {
                        this.options = response.data;
                        this.loading = false;
                    }).catch(() => {
                        // TODO: Do something on failure
                    });
            },

            change($event) {
                this.$emit('input', $event.target.value);
                this.$emit('change', $event.target.value);

                const currentOption = this.options.find(option => option.value === $event.target.value);
                if (currentOption && currentOption.extra_data) {
                    this.$emit('extra-data', currentOption.extra_data);
                }
            },

            refresh() {
                this.fetchOptions();
            },
        },

        previousSource: null,
    };
</script>
