export default {
    name: 'employee_group',
    definition: {
        active: '',
        description: '',
        employees: {
            jsonApi: 'hasMany',
            type: 'employee',
        },
    },
};
