<script>
    import Form from '../../Form.vue';
    import axios from 'axios';
    import HandlesErrorResponses from '../../../mixins/HandlesErrorResponses';

    const TARGET_AMOUNT_EXCL_VAT = 'total_amount[excl_vat]';
    const TARGET_AMOUNT_INCL_VAT = 'total_amount[incl_vat]';

    export default {
        extends: Form,

        mixins: [
            HandlesErrorResponses,
        ],

        props: {
            vats: {
                type: Array,
                required: true,
            },

            activeRateUrl: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                focussedTarget: null,
                lastManuallyChangedTarget: TARGET_AMOUNT_EXCL_VAT,
                rates: [],
            };
        },

        computed: {
            vat() {
                return this.vats.find((vat) => {
                    return vat.id === this.formData.vat_id;
                });
            },

            vatFraction() {
                return this.vat.attributes.promille / 1000;
            },

            activeRate() {
                return this.rates.find((rate) =>
                    rate.articleId === this.formData.article_id &&
                    rate.locationId === this.formData.location_id)?.rate;
            },
        },

        watch: {
            async 'formData.article_id'(value) {
                if (value && this.formData.location_id) {
                    await this.fetchActiveRate(this.formData.location_id, value);
                }
            },

            'formData.number_of_items'() {
                this.fieldChanged();
            },

            'formData.total_amount.excl_vat'() {
                this.onFieldExclChange();
            },

            'formData.total_amount.incl_vat'() {
                this.onFieldInclChange();
            },

            async 'formData.location_id'(value) {
                if (this.activeRate) {
                    return;
                }

                if (this.formData.article_id && value) {
                    await this.fetchActiveRate(value, this.formData.article_id);
                }
            },

            activeRate() {
                if (!this.activeRate) {
                    this.$set(this.formData, 'vat_id', null);
                    this.$set(this.formData, 'original_amount', null);
                    this.$set(this.formData.total_amount, 'vat_promille', null);
                    this.$set(this.formData.total_amount, 'excl_vat', null);
                    this.$set(this.formData.total_amount, 'incl_vat', null);
                    this.$set(this.formData.total_amount, 'vat', null);
                    return;
                }

                this.$set(this.formData, 'vat_id', this.activeRate.vat_id);
                this.$set(this.formData, 'original_amount', this.activeRate.amount.excl_vat);
                this.$set(this.formData.total_amount, 'vat_promille', this.activeRate.amount.vat_promille);
                this.$set(this.formData.total_amount, 'excl_vat', this.activeRate.amount.excl_vat);
                this.$set(this.formData.total_amount, 'incl_vat', this.activeRate.amount.incl_vat);
                this.$set(this.formData.total_amount, 'vat', this.activeRate.amount.vat);

                this.fieldChanged();
            },
        },

        methods: {
            fieldFocussed(target) {
                this.focussedTarget = target;
            },

            fieldBlurred(target) {
                if (this.focussedTarget === target) {
                    this.focussedTarget = null;
                }
            },

            async fetchActiveRate(locationId, articleId) {
                try {
                    const rate = (await axios.get(this.activeRateUrl, {
                        params: {
                            locationId,
                            articleId,
                        },
                    })).data?.data;

                    this.rates.push({
                        articleId,
                        locationId,
                        rate,
                    });
                } catch (err) {
                    await this.handleErrorResponse(err);
                }
            },

            fieldChanged() {
                let amountExclVat = this.formData.original_amount;
                let amountInclVat = 0;
                let amountVat = 0;
                let numberOfItems = this.formData.number_of_items;
                const vatPromille = this.formData.total_amount.vat_promille;

                if (numberOfItems < 1) {
                    numberOfItems = 1;
                    this.$set(this.formData, 'number_of_items', numberOfItems);
                }

                // set amount excl
                amountExclVat = amountExclVat * numberOfItems;
                this.$set(this.formData.total_amount, 'excl_vat', amountExclVat);

                // set amount vat
                amountVat = Math.round(amountExclVat * (vatPromille / 1000));
                this.$set(this.formData.total_amount, 'vat', amountVat);

                // set amount incl
                amountInclVat = Math.round(amountExclVat + amountVat);
                this.$set(this.formData.total_amount, 'incl_vat', amountInclVat);
            },

            onFieldExclChange() {
                if (this.focussedTarget === TARGET_AMOUNT_EXCL_VAT) {
                    const amountExclVat = this.formData.total_amount.excl_vat;
                    let amountInclVat = 0;
                    let amountVat = 0;
                    const vatPromille = this.formData.total_amount.vat_promille;

                    // set amount vat
                    amountVat = Math.round(amountExclVat * (vatPromille / 1000));
                    this.$set(this.formData.total_amount, 'vat', amountVat);

                    // set amount incl
                    amountInclVat = Math.round(amountExclVat + amountVat);
                    this.$set(this.formData.total_amount, 'incl_vat', amountInclVat);

                    // set original amount
                    const numberOfItems = this.formData.number_of_items;
                    this.$set(this.formData, 'original_amount', Math.round(amountExclVat / numberOfItems));
                }
           },

            onFieldInclChange() {
                if (this.focussedTarget === TARGET_AMOUNT_INCL_VAT) {
                    let amountExclVat = 0;
                    const amountInclVat = this.formData.total_amount.incl_vat;
                    let amountVat = 0;
                    const vatPromille = this.formData.total_amount.vat_promille;

                    // set amount vat
                    amountVat = Math.round(amountInclVat * vatPromille / (1000 + vatPromille));
                    this.$set(this.formData.total_amount, 'vat', amountVat);

                    // set amount excl
                    amountExclVat = Math.round(amountInclVat - amountVat);
                    this.$set(this.formData.total_amount, 'excl_vat', amountExclVat);

                    // set original amount
                    const numberOfItems = this.formData.number_of_items;
                    this.$set(this.formData, 'original_amount', Math.round(amountExclVat / numberOfItems));
                }
            },
        },
    };
</script>
