<template>
    <form class="cov-check" @submit.prevent="submit()">
        <button type="submit" class="button cov-check__button" :disabled="loading">
            <span class="button__icon-group">
                <frm-icon v-if="loading" name="small-loading" class="button__icon" />
                <span>
                    {{ $t('patient.cov-check.check') }}
                </span>
            </span>
        </button>
        <button type="button" class="cov-check__button-dropdown" :disabled="loading" @click="openPopup">
            &#9660;
        </button>
        <template v-if="!loading">
            <span v-if="lastCheckDate"
                  class="cov-check__text"
                  v-text="$t('patient.cov-check.last-cov-check-at', { date: $ds(lastCheckDate, 'date') })"
            >
                {{ $t('patient.cov-check.last-cov-check-at', {date: lastCheckDate}) }}
            </span>
            <span v-else class="cov-check__text">
                {{ $t('patient.cov-check.last-cov-check-unknown') }}
            </span>
        </template>

        <div v-if="popupOpen" class="cov-check-popup">
            <div class="cov-check-popup__content">
                <div class="form-group">
                    <label for="frm_input_date" class="form-group__label cov-check-popup__label">
                        {{ $t('patient.fields.reference_date') }}
                    </label>
                    <div class="form-group__field">
                        <frm-input-date-picker v-model="date" />
                    </div>
                </div>
            </div>

            <div class="form-button-group form-button-group--framed">
                <div class="form-button-group__subgroup">
                    <button type="submit" class="button button--grouped">
                        {{ $t('patient.cov-check.check') }}
                    </button>
                    <button type="button" class="button button--grouped button--text" @click="closePopup">
                        {{ $t('masterfiles.actions.cancel') }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import dateFormatter from '../i18n/dateFormatter';
import closest from '../services/closest';
import doCovCheck from '../services/covCheck';

export default {
    props: {
        initialLastCheckDate: {
            type: String,
            default: null,
        },
        patientId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            popupOpen: false,
            date: dateFormatter(new Date()),
            loading: false,
            lastCheckDate: this.initialLastCheckDate,
        };
    },

    methods: {
        clickHandler(e) {
            if (closest(e.target, '.cov-check-popup')) {
                return;
            }

            this.closePopup();
        },

        closePopup() {
            this.popupOpen = false;

            document.removeEventListener('click', this.clickHandler);
        },

        openPopup() {
            this.popupOpen = true;

            setTimeout(() => {
                document.addEventListener('click', this.clickHandler);
            });
        },

        async submit() {
            if (this.popupOpen) {
                this.closePopup();
            }

            this.loading = true;
            await doCovCheck(this.patientId, this.date);
            this.loading = false;
            this.lastCheckDate = dateFormatter(new Date());

            this.$emit('refresh');
        },
    },
};
</script>
