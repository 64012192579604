export const ADMINISTRATOR = -100;

export const MASTER_FILES = -200;

export const MASTER_FILES_GENERAL = -201;

export const MASTER_FILES_FYSIOROADMAP = -202;

export const MASTER_FILES_EXERCISES = -203;

export const MASTER_FILES_CALENDAR = -204;

export const MASTER_FILES_FINANCIAL = -205;

export const PATIENT = -300;

export const PATIENT_GENERAL = -301;

export const PATIENT_ADDITIONAL = -302;

export const PATIENT_FINANCIAL = -303;

export const PATIENT_INSURER_POLICIES = -304;

export const PATIENT_PERMISSIONS = -305;

export const PATIENT_MEDICAL_HISTORIES = -306;

export const PATIENT_SUBSCRIPTIONS = -307;

export const PATIENT_INDICATIONS = -308;

export const PATIENT_REMOVE_PATIENT = -309;

export const PATIENT_CREATE_PATIENT = -310;

export const PATIENT_MANAGEMENT = -311;

export const FYSIOROADMAP = -400;

export const FYSIOROADMAP_ANAMNESIS = -401;

export const FYSIOROADMAP_INDICATION_GENERAL = -402;

export const FYSIOROADMAP_INDICATION_FINANCIAL = -418;

export const FYSIOROADMAP_SUBGOALS = -403;

export const FYSIOROADMAP_EXERCISES = -404;

export const FYSIOROADMAP_CONTACT_MOMENTS = -405;

export const FYSIOROADMAP_PHONE_CONSULTATION = -406;

export const FYSIOROADMAP_NOTES = -407;

export const FYSIOROADMAP_CLINIMETRICS = -408;

export const FYSIOROADMAP_DOCUMENTS = -409;

export const FYSIOROADMAP_COMPLICATION_DATA = -410;

export const FYSIOROADMAP_REPORTS = -411;

export const FYSIOROADMAP_END_EVALUATION = -412;

export const FYSIOROADMAP_REMOVE_INDICATION_HISTORY = -413;

export const FYSIOROADMAP_REMOVE_CONTACT_MOMENTS = -414;

export const FYSIOROADMAP_REMOVE_CLINIMETRICS = -415;

export const FYSIOROADMAP_PERMISSIONS = -416;

export const FYSIOROADMAP_ONLINE_QUESTIONNAIRES = -419;

export const STATISTICS_AND_OVERVIEWS = -500;

export const STATISTICS_AND_OVERVIEWS_STATISTICS = -501;

export const STATISTICS_AND_OVERVIEWS_OVERVIEWS = -502;

export const STATISTICS_AND_OVERVIEWS_PHYSIOTHERAPISTS = -503;

export const STATISTICS_AND_OVERVIEWS_INSURERS_AND_DATA_DELIVERY = -504;

export const SETTINGS = -600;

export const SETTINGS_APPLICATION = -601;

export const SETTINGS_EXTERNAL_INTERFACES = -602;

export const SETTINGS_INSTITUTE_MANAGEMENT = -603;

export const SETTINGS_USER_MANAGEMENT = -604;

export const SETTINGS_USER_GROUPS = -605;

export const SETTINGS_EXPORTS = -606;

export const QUALITY_MANAGER = -700;

export const CALENDAR = -800;

export const CALENDAR_PASSWORD_WHEN_CHANGING_INSTITUTE = -801;

export const DECLARATIONS = -900;

export const DECLARATIONS_DECLARATION_STATISTICS = -901;

export const DECLARATIONS_DECLARATION_OVERVIEWS = -902;

export const ADMINISTRATION = -1000;

export const MRSINTERACTIVE = -1100;

export const REGULAR_PHYSICAL_THERAPY = -1200;

export const CHILD_PHYSICAL_THERAPY = -1300;

export const PELVIC_PHYSICAL_THERAPY = -1400;

export const ARTICLE_DATA = -1500;

export const SUBSCRIPTION_DATA = -1600;

export const SERVICE_DATA = -1700;

export const DASHBOARD = -1800;

export const DASHBOARD_MESSAGING = -417;
