export default {
    name: 'employee',
    definition: {
        active: '',
        address: {
            city: '',
            country_id: '',
            email: '',
            house_number: '',
            house_number_suffix: '',
            phone: '',
            phone_mobile: '',
            phone_private: '',
            phone_work: '',
            po_box: '',
            postal_code: '',
            province: '',
            state: '',
            street: '',
        },
        agb: '',
        age: '',
        appointments_per_month: '',
        appointments_per_week: '',
        big_nummer: '',
        birthdate: '',
        blocked: '',
        caregiver_specification: {
            jsonApi: 'hasOne',
            type: 'caregiver_specification',
        },
        caregiver_specification_id: '',
        ckr_nummer: '',
        color: '',
        deceased: '',
        deceased_date: '',
        employee_groups: {
            jsonApi: 'hasMany',
            type: 'employee_group',
        },
        function: '',
        gender: '',
        identification_number: '',
        keurmerk_nummer: '',
        mentor_id: '',
        mentor: {
            jsonApi: 'hasMany',
            type: 'employee',
        },
        name: {
            addressing: '',
            firstname: '',
            fullname: '',
            initials: '',
            lastname: '',
            lastname_prefix: '',
            middlename: '',
            partner_name: '',
            partner_name_prefix: '',
            salutation: '',
            use_partner_name: '',
        },
        photo_id: '',
        registration_code: '',
        remuneration_method: '',
        remuneration_permyriad: '',
        revenue_per_month: '',
        revenue_per_week: '',
        role: {
            jsonApi: 'hasOne',
            type: 'role',
        },
        role_id: '',
        specialization: '',
        target_id: '',
        target_number_of_appointments: '',
        due_date: '',
        corrected_age_in_months: '',
    },
};
