export default {
    name: 'institute',
    definition: {
        appointments_per_week: '',
        revenue_per_week: '',
        appointments_per_month: '',
        revenue_per_month: '',
        name: '',
        active: '',
        email_declaration: '',
        password: '',
        agb: '',
        vat_number: '',
        chamber_of_commerce_number: '',
        plus_praktijk: '',
        logo_id: '',
        target_id: '',
        address: {
            street: '',
            house_number: '',
            house_number_suffix: '',
            postal_code: '',
            city: '',
            province: '',
            country: '',
            phone: '',
            email: '',
        },
    },
};
