<script>
    import InlineChildOverview from '../InlineChildOverview.vue';

    export default {
        extends: InlineChildOverview,

        props: {
            formData: {
                type: Object,
                required: true,
            },
        },

        computed: {
            addDisabled() {
                if (this.items.length === 0) {
                    return false;
                }

                return this.items[this.items.length - 1].max_value >= this.maxValue;
            },

            minValue() {
                return Number(this.formData.min_value);
            },

            maxValue() {
                return Number(this.formData.max_value);
            },

            stepSize() {
                return Math.min(...this.items.map(item => this.calculateStepSizeFromNumberOfDecimals(
                    this.getNumberOfDecimals(item.max_value)
                )));
            },
        },

        watch: {
            minValue: {
                handler() {
                    this.setFirstItemMinValue();
                },
                immediate: true,
            },

            maxValue: {
                handler() {
                    this.checkLastItemMaxValue();
                },
                immediate: true,
            },
        },

        methods: {
            getNumberOfDecimals(value) {
                if (!value) {
                    return 1;
                }

                const parts = value.toString().split('.');

                if (parts.length === 1) {
                    return 0;
                }

                return Math.min(parts[1].length, 2);
            },

            createNewItem() {
                const newItem = InlineChildOverview.methods.createNewItem.call(this);

                newItem.max_value = null;

                if (this.items.length === 0) {
                    newItem.min_value = this.minValue;
                } else {
                    if (this.items[this.items.length - 1].max_value) {
                        newItem.min_value = Number((Number(this.items[this.items.length - 1].max_value) + this.stepSize).toFixed(2));
                    }
                    if (newItem.min_value === this.maxValue) {
                        newItem.max_value = this.maxValue;
                    }
                }

                return newItem;
            },

            setFirstItemMinValue() {
                if (this.items.length === 0) {
                    return;
                }

                this.items[0].min_value = this.minValue;
            },

            checkLastItemMaxValue() {
                if (this.items.length === 0) {
                    return;
                }

                if (this.items[this.items.length - 1].max_value > this.maxValue) {
                    this.items[this.items.length - 1].max_value = this.maxValue;
                }
            },

            fixItems() {
                this.setFirstItemMinValue();

                let previousMaxValue = null;
                const decimals = this.getNumberOfDecimals(this.stepSize);

                this.items.forEach((item) => {
                    if (previousMaxValue !== null) {
                        item.min_value = Number((Number(previousMaxValue) + this.stepSize).toFixed(decimals));
                    }

                    if (item.max_value !== null && item.max_value !== '' && item.min_value >= item.max_value) {
                        item.max_value = Number(item.min_value);
                    }

                    previousMaxValue = item.max_value;
                });
            },

            calculateStepSizeFromNumberOfDecimals(numberOfDecimals) {
                switch (numberOfDecimals) {
                    case 0:
                        return 1;
                    case 1:
                        return 0.1;
                    default:
                        return 0.01;
                }
            },
        },
    };
</script>
