<script>
import Form from '../Form.vue';
import axios from 'axios';
import HandlesErrorResponses from '../../mixins/HandlesErrorResponses';
import HandlesInstitute from '../../mixins/HandlesInstitute';

export default {
    extends: Form,

    props: {
        emailTemplatesUrl: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            fetchingEmailTemplates: false,
        };
    },

    mixins: [
        HandlesInstitute,
        HandlesErrorResponses,
    ],

    methods: {
        async updateEmailTemplates() {
            this.fetchingEmailTemplates = true;
            try {
                const emailTemplate = (await axios.get(this.emailTemplatesUrl, {
                    params: {
                        institute_id: this.formData.institute_id,
                    },
                })).data.data;

                this.formData.email_subject = emailTemplate.email_subject;
                this.formData.email_body = emailTemplate.email_body;
            } catch (error) {
                this.formData.email_subject = '';
                this.formData.email_body = '';

                await this.handleErrorResponse(error);
            } finally {
                this.fetchingEmailTemplates = false;
            }
        },
    },

    mounted() {
        this.formData.institute_id = this.instituteId;
    },

    watch: {
        'formData.institute_id'() {
            this.instituteId = this.formData.institute_id;
            this.updateEmailTemplates();
        },
    },
};
</script>
