import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/nl';
import 'moment/locale/zh-cn';

/* German */
moment.updateLocale('de', {
    relativeTime: {
        w: 'eine Woche',
        ww: '%d Wochen',
    },
});

/* English/UK */
moment.updateLocale('en-gb', {
    relativeTime: {
        w: 'a week',
        ww: '%d weeks',
    },
});

/* Spanish */
moment.updateLocale('es', {
    relativeTime: {
        w: 'una semana',
        ww: '%d semanas',
    },
});

/* French */
moment.updateLocale('fr', {
    relativeTime: {
        w: 'une semaine',
        ww: '%d semaines',
    },
});

/* Dutch */
moment.updateLocale('nl', {
    relativeTime: {
        w: 'één week',
        ww: '%d weken',
    },
});

/* Simplified Chinese/China */
moment.updateLocale('zh-cn', {
    relativeTime: {
        w: '一周',
        ww: '%d周',
    },
});
