import store from '../store';

export default class AccessRights {
    static hasNoAccessTo(accessRight) {
        return !AccessRights.hasAccessTo(accessRight);
    }

    static hasAccessTo(accessRight) {
        return (store.state.accessRights || []).includes(accessRight);
    }
}
