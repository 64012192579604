export default {
    name: 'service',
    definition: {
        description: '',
        rates: {
            jsonApi: 'hasMany',
            type: 'rate',
        },
    },
};
