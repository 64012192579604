<script>
import Form from '../Form.vue';
import axios from 'axios';

export default {
    extends: Form,

    props: {
        physitrackCreateTokenUrl: {
            type: String,
            default: null,
        },
        physitrackDeleteTokenUrl: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            physitrackTokenCopied: false,
            physitrackTokenLoading: false,
        };
    },

    methods: {
        async physitrackCopyToken() {
            await navigator.clipboard.writeText(this.formData.physitrack_token);
            this.physitrackTokenCopied = true;
            setTimeout(() => (this.physitrackTokenCopied = false), 5 * 1000);
        },

        async physitrackGenerateToken() {
            this.physitrackTokenLoading = true;

            this.formData.physitrack_token = (await axios.post(this.physitrackCreateTokenUrl)).data;

            this.physitrackTokenCopied = false;
            this.physitrackTokenLoading = false;
        },

        async physitrackDeleteToken() {
            this.physitrackTokenLoading = true;

            await axios.delete(this.physitrackDeleteTokenUrl);

            this.formData.physitrack_token = '';
            this.physitrackTokenCopied = false;
            this.physitrackTokenLoading = false;
        },
    },
};
</script>
