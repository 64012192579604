<template>
    <div class="multi-select">
        <div class="multi-select__available">
            <div class="checkbox-list">
                <h3 class="checkbox-list__header">
                    {{ $t('form.multiselect.available', {count: availableOptions.length}) }}
                </h3>
                <div class="checkbox-list__search">
                    <div class="form-icon-field">
                        <input :id="'frm_multi_select_search_' + name" v-model="searchQuery" :placeholder="searchPlaceholder" autocomplete="off" class="form-input form-icon-field__field">
                        <button type="button" class="form-icon-field__button" @click="searchQuery = ''">
                            <frm-icon :name="searchQuery ? 'cross-2' : 'magnifying-glass'" class="form-icon-field__icon" />
                        </button>
                    </div>
                </div>
                <div class="checkbox-list__items-container checkbox-list__items-container--searchable">
                    <div v-for="option in filteredOptions" :key="option.value" class="checkbox-list__item">
                        <div class="form-checkbox form-checkbox--in-checkbox-list">
                            <input
                                :id="'frm_multi_select_' + name + '_' + option.value"
                                v-model="selected"
                                :name="name"
                                :value="option.value"
                                class="form-checkbox__input"
                                type="checkbox"
                            >
                            <label
                                :for="'frm_multi_select_' + name + '_' + option.value"
                                class="form-checkbox__label"
                            >{{ option.label }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="multi-select__selected">
            <div class="checkbox-list">
                <h3 class="checkbox-list__header">
                    {{ $t('form.multiselect.selected', {count: selectedOptions.length}) }}
                </h3>
                <div :class="{'checkbox-list__items-container--required': required}" class="checkbox-list__items-container">
                    <div v-for="option in selectedOptions" :key="option.value" class="checkbox-list__item">
                        <div class="form-checkbox form-checkbox--in-checkbox-list">
                            <input
                                :id="'frm_multi_select_' + name + '_' + option.value"
                                v-model="selected"
                                :name="name"
                                :value="option.value"
                                class="form-checkbox__input"
                                type="checkbox"
                            >
                            <label
                                :for="'frm_multi_select_' + name + '_' + option.value"
                                class="form-checkbox__label"
                            >{{ option.label }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <select :required="required" class="multi-select__input" multiple tabindex="-1">
            <option v-for="option in options" :key="option.value" :selected="value.includes(option.value)" :value="option.value">
                {{ option.label }}
            </option>
        </select>
    </div>
</template>

<script>
    import i18n from '../../i18n';

    export default {
        model: {
            prop: 'value',
            event: 'change',
        },

        props: {
            options: {
                type: Array,
                required: true,
            },

            name: {
                type: String,
                required: true,
            },

            required: {
                type: Boolean,
                default: false,
            },

            searchPlaceholder: {
                type: String,
                default: i18n.t('form.multiselect.search-placeholder'),
            },

            value: {
                type: Array,
                default() {
                    return [];
                },
            },
        },

        data() {
            return {
                searchQuery: '',
            };
        },

        computed: {
            selected: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('change', value);
                },
            },

            availableOptions() {
                return this.options.filter(option => !this.value.includes(option.value));
            },

            filteredOptions() {
                return this.availableOptions.filter(option => option.label.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1);
            },

            selectedOptions() {
                return this.options.filter(option => this.value.includes(option.value));
            },
        },
    };
</script>
