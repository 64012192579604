<template>
    <frm-modal-component ref="modal" @save="save">
        <template #header>
            {{ header }}
        </template>

        <div v-for="(fieldsChunk, chunkIndex) in chunkedFields" :key="chunkIndex" class="form-group-collection">
            <div v-for="(field, index) in fieldsChunk" :key="index" class="form-group-collection__group">
                <div class="form-group-vertical__heading">
                    <label :for="'frm_input_domains_modal_'+ (++chunkIndex + index)" class="form-group-vertical__label">
                        {{ field.label }}
                    </label>
                    <!--Optionally show button-->
                    <button v-if="field.textFromPreviousIndication" :id="'frm_button_domains_modal_'+ (++chunkIndex + index)" class="button button--narrow button--low button--secondary" @click="addTextFromPreviousIndication(field)">
                        <span>{{ $t('fysioroadmap.anamnesis.buttons.copy_from_previous_indication') }}</span>
                    </button>
                </div>
                <div class="form-group-vertical__field">
                    <frm-input-smart-ajax-textarea :id="'frm_input_domains_modal_'+ (++chunkIndex + index)" v-model="field.value" :placeholders-url="field.placeholdersUrl" :texts-url="field.textsUrl" />
                </div>
            </div>
        </div>
    </frm-modal-component>
</template>

<script>
    export default {
        props: {
            header: {
                type: String,
                default: '',
            },

            fields: {
                type: Object,
                required: true,
            },
        },

        computed: {
            chunkedFields() {
                const fields = [];
                const chunkSize = 2;

                const objectToArray = Object.keys(this.fields).map((k) => this.fields[k]);

                for (let i = 0; i < objectToArray.length; i += chunkSize) {
                    fields.push(objectToArray.slice(i, i + chunkSize));
                }

                return fields;
            },
        },

        methods: {
            save() {
                const data = {
                    fields: {
                        'lwk-pelvic-hip': this.fields['lwk-pelvic-hip'],
                        urology: this.fields.urology,
                        gynecology: this.fields.gynecology,
                        proctology: this.fields.proctology,
                        sexology: this.fields.sexology,
                        pain: this.fields.pain,
                    },
                };

                this.$emit('set-domains-fields', data);
                this.$refs.modal.closeModal();
            },

            addTextFromPreviousIndication(field) {
                if (field.value !== null && field.value.length > 0) {
                    field.value = field.value + '\n' + field.textFromPreviousIndication;
                } else {
                    field.value = field.textFromPreviousIndication;
                }
            },
        },
    };
</script>
