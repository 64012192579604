<template>
    <button :class="classes" :disabled="disabled" type="button" @click="click">
        <slot />
    </button>
</template>

<script>
    import { mapState } from '../store/helpers';
    import ModalManager from '../services/modalManager';

    export default {
        props: {
            clinimetricsId: {
                type: [Number, String],
                default: null,
            },

            clinimetricsDataId: {
                type: [Number, String],
                default: null,
            },

            showSummary: {
                type: Boolean,
                default: false,
            },

            origin: {
                type: String,
                default: null,
            },

            classes: {
                type: String,
                default: 'button button--narrow button--low button--secondary',
            },

            disabled: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState([
                'patientId',
                'indicationId',
            ]),

            url() {
                let url = `/fysioroadmap/${this.patientId}/${this.indicationId}/clinimetrics`;

                if (this.clinimetricsDataId) {
                    url += `/${this.clinimetricsDataId}`;

                    if (this.showSummary) {
                        url += '/summary';
                    }

                    url += '/edit';
                } else {
                    url += `/create/${this.clinimetricsId}`;

                    if (this.origin) {
                        url += `?origin=${this.origin}`;
                    }
                }

                return url;
            },
        },

        methods: {
            click() {
                ModalManager.openModal(this.url, undefined, undefined, 'full')
                    .then((data) => {
                        if (data !== null && typeof data === 'object') {
                            this.$emit('clinimetrics-created', data);
                        }
                    });
            },
        },
    };
</script>
