<template>
    <frm-modal-component ref="modal" @save="save">
        <template #header>
            {{ $t('fysioroadmap.anamnesis.physiotherapeutic-work-diagnosis') }}
        </template>

        <div class="form-group-vertical">
            <div class="form-group-vertical__heading">
                <label for="text" class="form-group-vertical__label">{{ description }}</label>
                <frm-refresh-button :url="url" data-refresh-fysiotherapeutic-work-diagnosis @refreshed="textLocal = $event" />
            </div>
            <div class="form-group-vertical__field">
                <textarea id="text" v-model="textLocal" class="form-textarea form-textarea--tall" />
            </div>
        </div>
    </frm-modal-component>
</template>

<script>
    export default {
        props: {
            description: {
                type: String,
                required: true,
            },

            url: {
                type: String,
                required: true,
            },

            text: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                textLocal: this.text,
            };
        },

        methods: {
            save() {
                this.$emit('set-physiotherapeutic-work-diagnosis', {
                    physiotherapeuticWorkDiagnosis: this.textLocal,
                });
                this.$refs.modal.closeModal();
            },
        },
    };
</script>
