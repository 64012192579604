/**
 * Creates Date from string in ISO-8601 (without timezone) format e.g. 2017-01-01T00:00:00
 *
 * @param {string} date
 * @returns {Date}
 */
export default function dateParser(date) {
    const year = parseInt(date.substr(0, 4), 10);
    const month = parseInt(date.substr(5, 2), 10) - 1;
    const day = parseInt(date.substr(8, 2), 10);
    const hour = parseInt(date.substr(11, 2), 10);
    const minutes = parseInt(date.substr(14, 2), 10);
    const seconds = parseInt(date.substr(17, 2), 10);

    return new Date(year, month, day, hour, minutes, seconds);
}
