<script>
    import Form from '../Form.vue';

    export default {
        extends: Form,

        methods: {
            setExtraData(options) {
                if (options) {
                    this.formData.treatable_parameter = options.treatable_parameter_id;
                    this.formData.description = options.subgoal_description;
                }
            },
        },
    };
</script>
