<script>
    import Default from './Default.vue';

    export default {
        extends: Default,

        mounted() {
            this.$refs.form.addEventListener('submit', event => {
                event.preventDefault();

                this.formData.clinimetrics_item_data[9].attachment_raw = this.$refs.popq.toPng();

                this.$nextTick(() => {
                    this.$refs.form.submit();
                });
            });
        },

        computed: {
            stage() {
                const Aa = this.formData.clinimetrics_item_data[0].value_as_double;
                const Ba = this.formData.clinimetrics_item_data[1].value_as_double;
                const C = this.formData.clinimetrics_item_data[2].value_as_double;
                const tvl = this.formData.clinimetrics_item_data[5].value_as_double;
                const Ap = this.formData.clinimetrics_item_data[6].value_as_double;
                const Bp = this.formData.clinimetrics_item_data[7].value_as_double;
                const D = this.formData.clinimetrics_item_data[8].value_as_double;

                let stage = 0;

                if ((Aa === null || Aa === '') && (Ba === null || Ba === '') && (C === null || C === '') && (tvl === null || tvl === '') && (Ap === null || Ap === '') && (Bp === null || Bp === '') && (D === null || D === '')) {
                    stage = '';
                } else if (
                    (Aa === -3 && Ba === -3 && Ap === -3 && Bp === -3) && (
                    ((Math.abs(C) < Math.abs(tvl)) && (Math.abs(C) > (Math.abs(tvl) - 2))) ||
                    ((Math.abs(D) < Math.abs(tvl)) && (Math.abs(D) > (Math.abs(tvl) - 2))))
                ) {
                    stage = 1;
                } else if (Math.max(Aa, Ba, Ap, Bp) < -1) {
                    stage = 2;
                } else if (Math.max(Aa, Ba, Ap, Bp) <= 1) {
                    stage = 3;
                } else if (Math.max(Aa, Ba, Ap, Bp) > 1 && Math.max(Aa, Ba, Ap, Bp) < (Math.abs(tvl) - 2)) {
                    stage = 4;
                } else if (Math.max(Aa, Ba, Ap, Bp) >= (Math.abs(tvl) - 2)) {
                    stage = 5;
                }

                return stage;
            },
        },
    };
</script>
