import Vue from 'vue';

/**
 * Render an Icon component to an HTML string
 *
 * @param {string} name
 * @param {string} [className]
 * @param {string} [title]
 *
 * @returns {string}
 */
export default function iconRenderer(name, className, title) {
    // we need to wrap the custom element in a div because otherwise IE thinks it's XML markup
    const templateWrapper = document.createElement('div');
    const icon = document.createElement('frm-icon');
    icon.setAttribute('name', name);

    if (className) {
        icon.className = className;
    }

    if (title) {
        icon.title = title;
    }

    templateWrapper.appendChild(icon);

    // we cannot directly output svg.outerHTML because IE doesnt think SVG has outerHTML. We need to wrap it first.
    const outputWrapper = document.createElement('div');
    outputWrapper.appendChild(new Vue({ template: templateWrapper.innerHTML }).$mount().$el);

    return outputWrapper.innerHTML;
}
