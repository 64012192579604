<script>

import HandlePremsRecordsNotifications from '../mixins/Fysioroadmap/HandlePremsRecordsNotifications';

export default {
    mixins: [
        HandlePremsRecordsNotifications,
    ],

    render() {
        return null;
    },

    mounted() {
        this.sendPremsRecords();
    },
};
</script>
