export default {
    name: 'revenue_overview_resource',
    definition: {
        revenue_lines: '',
        expand_with: '',
        group_by: '',
        detail_line: '',
        date_filter: '',
        employee_filter: '',
        insurer_filter: '',
        location_filter: '',
    },
    options: {
        collectionPath: 'revenue_resources',
    },
};
