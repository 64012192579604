<script>
    import Form from '../Form.vue';

    export default {
        extends: Form,

        props: {
            testClinimetrics: {
                type: Array,
                required: true,
            },
        },

        computed: {
            clinimetrics() {
                return this.formData.clinimetrics;
            },

            isTestClinimetrics() {
                return this.testClinimetrics.includes(this.clinimetrics);
            },
        },
    };
</script>
