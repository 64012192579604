<template>
    <frm-modal-component ref="modal" @save="save">
        <template #header>
            {{ header }}
        </template>

        <div v-for="(field, index) in fields" :key="index" class="form-group-vertical">
            <div class="form-group-vertical__heading">
                <label :for="'frm_input_yellow_flags_modal_'+ index" class="form-group-vertical__label">
                    {{ field.label }}
                </label>
            </div>
            <div class="form-group-vertical__field">
                <textarea :id="'frm_input_yellow_flags_modal_'+ index" v-model="field.value" class="form-textarea" />
            </div>
        </div>
    </frm-modal-component>
</template>

<script>
    export default {
        props: {
            header: {
                type: String,
                default: '',
            },

            fields: {
                type: Object,
                required: true,
            },
        },

        methods: {
            save() {
                const fields = {
                    healthAndIllness: this.fields['health-and-illness'],
                    behavioralFactors: this.fields['behavioral-factors'],
                    emotionalFactors: this.fields['emotional-factors'],
                };

                this.$emit('set-yellow-flag-fields', fields);
                this.$refs.modal.closeModal();
            },
        },
    };
</script>
