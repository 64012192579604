export default {
    name: 'calendar_item',
    definition: {
        description: '',
        start: '',
        end: '',
        employees: [],
        location_id: '',
        location_rooms: [],
        location_devices: [],
        color_location: '',
        color: '',
        is_editable: '',
        is_unfulfillable: '',
        is_unfulfilled: '',
        invoicing_status: '',
        has_note: '',
        note: '',
    },
    options: {
        collectionPath: 'calendar_items',
    },
};
