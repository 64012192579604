import Vue from 'vue';
import Mousetrap from 'mousetrap';

export default new Vue({
    data() {
        return {
            bindings: {
                'modal.close': 'escape',
                'overview.all': 'ctrl+a',
                'overview.create': 'c',
                'overview.delete': 'del',
                'overview.edit': 'enter',
                'overview.next': 'down',
                'overview.previous': 'up',
            },
        };
    },
    created() {
        Object.keys(this.bindings).forEach((key) => this.bind(this.bindings[key]));
    },
    beforeDestroy() {
        Mousetrap.reset();
    },
    methods: {
        /**
         * @param {string | Array<string>} command
         */
        bind(command) {
            Mousetrap.bind(command, (event, command) => this.handle(event, command));
        },
        /**
         * @param {Event} event
         * @param {string} command
         */
        handle(event, command) {
            Object.keys(this.bindings).forEach((key) => {
                if (command === this.bindings[key]) {
                    this.$emit(key, event);
                }
            });
        },
    },
});
