<template>
    <div data-testid="patient-dashboard">
        <slot
            :indicationHistoryAmount="indicationHistoryAmount"
            :openNotesModal="openNotesModal"
            :setIndicationHistoryAmount="setIndicationHistoryAmount"
        />
    </div>
</template>

<script>
    import ModalManager from '../../services/modalManager';
    import PatientNoteModal from '../Modals/PatientNote.vue';

    export default {
        props: {
            patientName: {
                type: String,
                required: true,
            },

            notes: {
                type: String,
                default: '',
            },

            indicationHistoryOverview: {
                type: Object,
                default() {
                    return {};
                },
            },
        },

        data() {
            return {
                indicationHistoryAmount: 0,
            };
        },

        methods: {
            openNotesModal() {
                ModalManager.openModal(
                    PatientNoteModal,
                    {
                        header: `${this.patientName} / ${this.$t('patient.dashboard.terms.notes')}`,
                        notes: this.notes,
                    },
                    {},
                    'medium'
                );
            },

            setIndicationHistoryAmount() {
                this.indicationHistoryAmount = this.indicationHistoryOverview ? this.indicationHistoryOverview.getRowCount() : 0;
            },
        },
    };
</script>
