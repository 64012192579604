<script>
    import Form from '../Form.vue';
    import axios from 'axios';

    export default {
        extends: Form,

        props: {
            sorturl: {
                type: String,
                required: true,
            },

            isNew: {
                type: Boolean,
                required: true,
            },

            initialPhaseId: {
                type: Number,
                required: true,
            },

            initialNumber: {
                type: Number,
                required: true,
            },

            clinimetricPhases: {
                type: Array,
                required: true,
            },

            testClinimetrics: {
                type: Array,
                required: true,
            },
        },

        computed: {
            clinimetrics() {
                return this.formData.clinimetrics;
            },

            isTestClinimetrics() {
                return this.testClinimetrics.includes(this.clinimetrics);
            },

            phase() {
                return this.formData.treatment_plan_phase_id;
            },

            isClinimetricPhase() {
                return this.clinimetricPhases.includes(this.phase);
            },
        },

        watch: {
            phase: {
                immediate: true,
                handler(phaseId) {
                    this.fetchNumber(phaseId);
                },
            },
        },

        methods: {
            fetchNumber(phaseId) {
                if (!this.isNew && (parseInt(phaseId) === this.initialPhaseId)) {
                    this.formData.number = this.initialNumber;
                    return;
                }

                if (this.$options.previousSource) {
                    this.$options.previousSource.cancel();
                    this.$options.previousSource = null;
                }

                this.$options.previousSource = axios.CancelToken.source();

                return axios.get(this.sorturl, {
                    params: {
                        treatment_plan_phase_id: phaseId,
                    },
                    cancelToken: this.$options.previousSource.token,
                })
                .then((result) => {
                    this.formData.number = result.data;
                }).catch(() => {
                    this.formData.number = null;
                });
            },
        },

        previousSource: null,
    };
</script>
