<template>
    <frm-modal-component ref="modal" @save="save">
        <template #header>
            {{ $t('fysioroadmap.diagnosis_code.validity-header') }}
        </template>

        <div class="form-group">
            <label class="form-group__label" for="frm_input_diagnosis_code_modal_valid_from">
                {{ $t('fysioroadmap.anamnesis.fields.valid_from') }}
            </label>
            <div class="form-group__field">
                <frm-input-date-picker id="frm_input_diagnosis_code_modal_valid_from" v-model="validFromLocal" />
            </div>
        </div>

        <div class="form-group">
            <label class="form-group__label" for="frm_input_diagnosis_code_modal_valid_until">
                {{ $t('fysioroadmap.anamnesis.fields.valid_until') }}
            </label>
            <div class="form-group__field">
                <frm-input-date-picker id="frm_input_diagnosis_code_modal_valid_until" v-model="validUntilLocal" />
            </div>
        </div>
    </frm-modal-component>
</template>

<script>
    export default {
        props: {
            validFrom: {
                type: String,
                default: null,
            },

            validUntil: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                validFromLocal: this.validFrom,
                validUntilLocal: this.validUntil,
            };
        },

        methods: {
            save() {
                this.$emit('set-validity', {
                    valid_from: this.validFromLocal,
                    valid_until: this.validUntilLocal,
                });
                this.$refs.modal.closeModal();
            },
        },
    };
</script>
