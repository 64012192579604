<template>
    <div class="dashboard">
        <slot
            :actionCount="actionCount"
            :actionOverviewConfig="actionOverviewConfigEnriched"
            :actionRecipientOverviewConfig="actionRecipientOverviewConfigEnriched"
            :actionTab="actionTab"
            :calendarCount="calendarCount"
            :calendarDate="calendarDate"
            :calendarIntakesCount="calendarIntakesCount"
            :calendarOverviewConfig="calendarOverviewConfigEnriched"
            :createAction="createAction"
            :createMessage="createMessage"
            :messageCount="messageCount"
            :messageRecipientOverviewConfig="messageRecipientOverviewConfigEnriched"
            :nextCalendarDate="nextCalendarDate"
            :notes="localNotes"
            :previousCalendarDate="previousCalendarDate"
            :refreshCounters="refreshCounters"
            :setActionTab="setActionTab"
            :updateNotes="updateNotes"
        />
    </div>
</template>

<script>
    import Vue from 'vue';
    import axios from 'axios';
    import debounce from '../services/debounce';
    import Messaging from '../mixins/Dashboards/Messaging';
    import updateQueryStringParameter from '../services/updateQueryStringParameter';
    import HandlesErrorResponses from '../mixins/HandlesErrorResponses';

    export default {
        mixins: [Messaging, HandlesErrorResponses],

        props: {
            calendarOverview: {
                type: Vue,
                default: null,
            },

            calendarOverviewConfig: {
                type: Object,
                default() {
                    return {};
                },
            },

            calendarOverviewData: {
                type: Array,
                default() {
                    return [];
                },
            },

            notes: {
                type: String,
                default: '',
            },

            notesUrl: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                localNotes: this.notes,
                calendarDate: new Date(),
            };
        },

        computed: {
            calendarCount() {
                return this.calendarOverviewData.filter(item => (item.calendar_item_participation_id > 0)).length;
            },

            calendarIntakesCount() {
                return this.calendarOverviewData.filter(item => item.is_intake).length;
            },

            calendarOverviewConfigEnriched() {
                return {
                    ...this.calendarOverviewConfig,
                    height: this.getOverviewHeight,
                };
            },
        },

        watch: {
            localNotes: debounce(
                function (value) {
                    this.saveNotes(value);
                },
                500
            ),

            calendarDate: debounce(
                function (value) {
                    this.calendarOverview.setUrl(updateQueryStringParameter(this.calendarOverviewConfig.url, 'date', value.toISOString().slice(0, 10)));
                    this.calendarOverview.refresh();
                },
                250
            ),
        },

        methods: {
            updateNotes(value) {
                this.localNotes = value;
            },

            saveNotes(notes) {
                if (this.$options.previousSource) {
                    this.$options.previousSource.cancel();
                    this.$options.previousSource = null;
                }

                this.$options.previousSource = axios.CancelToken.source();

                return axios
                    .put(this.notesUrl, { notes })
                    .catch(err => this.handleErrorResponse(err));
            },

            previousCalendarDate() {
                this.calendarDate = new Date(this.calendarDate.setDate(this.calendarDate.getDate() - 1));
            },

            nextCalendarDate() {
                this.calendarDate = new Date(this.calendarDate.setDate(this.calendarDate.getDate() + 1));
            },
        },

        previousSource: null,
    };
</script>
