<template>
    <frm-modal-component ref="modal" @save="save">
        <template #header>
            {{ $t('calendar.appointment.proposals') }}
        </template>

        <p>
            {{ $t('calendar.appointment.select-proposals') }}
        </p>

        <div class="proposals-table-container">
            <frm-scrollable-table-wrapper class="overview__table">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="table__checkbox-col" />
                            <th>
                                <span class="table__th-wrapper">
                                    {{ $t('calendar.appointment.proposals-date') }}
                                </span>
                            </th>
                            <th>
                                <span class="table__th-wrapper">
                                    {{ $t('calendar.appointment.proposals-conflicts') }}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(proposal, idx) in proposals" :key="idx" :class="{'proposal__row--with-conflict': proposal.has_conflict}" class="zebra proposal__row" @click.stop.prevent="toggleProposal(idx)">
                            <td>
                                <label>
                                    <input v-model="enabled[idx]" class="form-checkbox__input group-checkable" type="checkbox">
                                    <span class="form-checkbox__label form-checkbox__label--no-content" />
                                </label>
                            </td>
                            <td>
                                {{ $ds(proposal.start, 'date') }} <wbr>
                                {{ $ds(proposal.start, 'time') }} - {{ $ds(proposal.end, 'time') }}
                            </td>
                            <td>
                                <div class="proposal__conflicts">
                                    <template v-if="proposal.has_conflict">
                                        <frm-icon name="exclamation-mark" class="proposal__conflicts__icon" />
                                        <ul class="proposal__conflicts__reasons">
                                            <li v-for="(reason, reasonIdx) in proposal.reasons" :key="reasonIdx">
                                                {{ reason }}
                                            </li>
                                        </ul>
                                    </template>
                                    <template v-else>
                                        -
                                    </template>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </frm-scrollable-table-wrapper>
        </div>
    </frm-modal-component>
</template>

<script>

export default {
    props: {
        proposals: {
            type: [Array],
            required: true,
        },
    },

    data() {
        return {
            enabled: [],
        };
    },

    mounted() {
        this.enabled = this.proposals.map(proposal => proposal.is_enabled);
    },

    computed: {
        value() {
            return this.proposals.filter((proposal, idx) => this.enabled[idx]);
        },
    },

    methods: {
        toggleProposal(idx) {
            this.$set(this.enabled, idx, !this.enabled[idx]);
        },

        save() {
            this.$emit('save', this.value);
            this.$refs.modal.closeModal();
        },
    },
};
</script>

<style lang="scss">
.proposals-table-container {
    max-height: 80vh;
}

.proposal__row--with-conflict {
    > td {
        background-color: $color__attention--light !important;
    }

    &:nth-child(2n) > td {
        background-color: $color__attention !important;
    }
}

.proposal__conflicts {
    display: flex;
    align-items: center;
}

.proposal__conflicts__reasons {
    list-style: none;
    margin: 0;
    padding: 0;
}

.proposal__conflicts__icon {
    display: block;
    fill: $color__dark;
    height: rhythm(2);
    margin-right: rhythm(1);
    width: rhythm(2);
}
</style>
