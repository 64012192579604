export default {
    name: 'calendar_item_participation',
    definition: {
        chronic: '',
        complaint: '',
        date_of_birth: '',
        date_of_indication: '',
        description: '',
        diagnosis_code: '',
        gender: '',
        general_practitioner: '',
        identification_number: '',
        indication: '',
        indication_code: '',
        indication_history_id: '',
        insurance_details: {},
        invoice_can_be_printed: '',
        module: '',
        paid_by: '',
        participation_date: '',
        patient_address_phone_mobile: '',
        patient_address_phone_private: '',
        patient_age: '',
        patient_id: '',
        patient_name: '',
        performance_code: '',
        photo_id: '',
        private_invoicing_details: '',
        referrer: '',
        status: '',
        therapist: '',
        total_amount: '',
    },
    options: {
        collectionPath: 'participation_payment_status',
    },
};
