<template>
    <frm-modal-component ref="modal" @save="save">
        <template #header>
            {{ $t('masterfiles.fields.templates-patch-siblings') }}
        </template>

        <div class="form-radio">
            <input id="frm_input_patch_siblings_modal_all" v-model="selected" class="form-radio__input" name="frm_input_patch_siblings_modal_choice" type="radio" value="all">
            <label class="form-radio__label" for="frm_input_patch_siblings_modal_all">{{ $t('masterfiles.fields.templates-patch-siblings-all') }}</label>
        </div>
        <div class="form-radio">
            <input id="frm_input_patch_siblings_modal_documenttype" v-model="selected" class="form-radio__input" name="frm_input_patch_siblings_modal_choice" type="radio" value="documenttype">
            <label class="form-radio__label" for="frm_input_patch_siblings_modal_documenttype">{{ $t('masterfiles.fields.templates-patch-siblings-documenttype') }}</label>
        </div>
        <div class="form-radio">
            <input id="frm_input_patch_siblings_modal_institute" v-model="selected" class="form-radio__input" name="frm_input_patch_siblings_modal_choice" type="radio" value="institute">
            <label class="form-radio__label" for="frm_input_patch_siblings_modal_institute">{{ $t('masterfiles.fields.templates-patch-siblings-institute') }}</label>
        </div>
        <div class="form-radio">
            <input id="frm_input_patch_siblings_modal_both" v-model="selected" class="form-radio__input" name="frm_input_patch_siblings_modal_choice" type="radio" value="both">
            <label class="form-radio__label" for="frm_input_patch_siblings_modal_both">{{ $t('masterfiles.fields.templates-patch-siblings-both') }}</label>
        </div>
    </frm-modal-component>
</template>

<script>
    export default {
        data() {
            return {
                selected: 'all',
            };
        },

        methods: {
            save() {
                const params = {};

                switch (this.selected) {
                    case 'documenttype':
                        params.only_equal_institute_siblings = false;
                        params.only_equal_output_siblings = true;
                        break;
                    case 'institute':
                        params.only_equal_institute_siblings = true;
                        params.only_equal_output_siblings = false;
                        break;
                    case 'both':
                        params.only_equal_institute_siblings = true;
                        params.only_equal_output_siblings = true;
                        break;
                    default:
                        params.only_equal_institute_siblings = false;
                        params.only_equal_output_siblings = false;
                        break;
                }

                this.$emit('save', params);
                this.$refs.modal.closeModal();
            },
        },
    };
</script>
