<template>
    <div :class="{'panel--toggleable': toggleable}" class="panel">
        <header class="panel__header" @click="toggle">
            <button v-if="toggleable" class="panel__header-button" type="button" @click.stop="toggle">
                <frm-icon :class="{'frm-icon--rotate-180': isOpened}" class="panel__header-button-icon" name="caret-down" />
            </button>
            <frm-icon v-if="icon" :name="icon" class="panel__header-icon" />
            <slot name="title">
                {{ title }}
            </slot>
            <div class="panel__header-extra">
                <slot name="header-extra" />
            </div>
        </header>
        <div v-show="isOpened" class="panel__content">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            initiallyOpened: {
                type: Boolean,
                default: true,
            },
            icon: {
                type: String,
                default: null,
            },
            title: {
                type: String,
                default: null,
            },
            toggleable: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                opened: this.initiallyOpened,
            };
        },

        computed: {
            isOpened() {
                if (!this.toggleable) {
                    return true;
                }

                return this.opened;
            },
        },

        methods: {
            toggle() {
                if (this.toggleable) {
                    this.opened = !this.opened;

                    if (this.opened) {
                        this.$emit('opened');
                    } else {
                        this.$emit('closed');
                    }
                }
            },
        },
    };
</script>
