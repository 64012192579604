<script>
    import Form from '../Form.vue';

    export default {
        extends: Form,

        data() {
            return {
                phasesOverviewRowCount: 0,
                phasesOverviewDrawn: false,
                phaseItemsOverviewDrawn: false,
            };
        },

        computed: {
            phaseItemsOverviewDisabled() {
                return this.phasesOverviewRowCount === 0;
            },
        },

        methods: {
            updatePhasesOverviewRowCount() {
                this.phasesOverviewRowCount = this.$refs.phasesOverview.getRowCount();
            },

            phasesOverviewDraw() {
                this.refreshPhaseItemsOverview();
                this.phasesOverviewDrawn = true;
            },

            phaseItemsOverviewDraw() {
                this.phaseItemsOverviewDrawn = true;
            },

            refreshPhaseItemsOverview() {
                if (this.phasesOverviewDrawn && this.phaseItemsOverviewDrawn) {
                    this.$refs.phaseItemsOverview.refresh();
                }
            },
        },
    };
</script>
