<template>
    <div class="patient-search__results">
        <div
            v-if="showAllResultsOption || showNoResultsMessage"
            :class="{'patient-search__advanced--selected': selectedIndex === 0}"
            class="patient-search__advanced"
            @click="goToAdvancedSearch()"
            @mouseover="selectedIndex = 0"
        >
            <figure class="patient-search__advanced-image">
                <frm-icon name="list" class="patient-search__list-icon" />
            </figure>
            {{ advancedSearchLabel }}
        </div>

        <frm-patient-search-teaser
            v-for="(patient, patientIndex) in visiblePatients"
            :key="patient.id"
            :class="{'patient-search-teaser--striped': true, 'patient-search-teaser--selected': selectedIndex === indexOffset + patientIndex}"
            :patient="patient"
            @click.native="goToSelectedPatient()"
            @mouseenter.native="selectedIndex = indexOffset + patientIndex"
        />

        <div
            :class="{'patient-search__advanced--selected': selectedIndex === createPatientIndex}"
            class="patient-search__advanced"
            @click="goToCreate()"
            @mouseover="selectedIndex = createPatientIndex"
        >
            <figure class="patient-search__advanced-image">
                <frm-icon name="patient" class="patient-search__list-icon" />
            </figure>
            {{ $t('patient.selection.create') }}
        </div>
    </div>
</template>

<script>
    import updateQueryStringParameter from '../../services/updateQueryStringParameter';

    const MAX_PATIENTS = 5;

    export default {
        props: {
            patients: {
                type: Array,
                required: true,
            },

            selectUrl: {
                type: String,
                required: true,
            },

            createUrl: {
                type: String,
                required: true,
            },

            showAllUrl: {
                type: String,
                required: true,
            },

            query: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                selectedIndex: 0,
            };
        },

        computed: {
            /**
             * @return {Boolean}
             */
            showAllResultsOption() {
                return this.patients.length > MAX_PATIENTS;
            },

            /**
             * @return {Boolean}
             */
            showNoResultsMessage() {
                return this.patients.length === 0;
            },

            /**
             * The search endpoint returns all matches, but we only want to show a subset.
             *
             * @return {Array}
             */
            visiblePatients() {
                return this.patients.slice(0, MAX_PATIENTS);
            },

            /**
             * @return {Object}
             */
            selectedPatient() {
                if (this.showAllResultsOption) {
                    return this.patients[this.selectedIndex - 1];
                }

                return this.patients[this.selectedIndex];
            },

            /**
             * @return {String}
             */
            advancedSearchLabel() {
                return this.$t(
                    this.showAllResultsOption ? 'patient.selection.show-all-results' : 'patient.selection.no-results',
                    {
                        number: this.patients.length,
                        query: this.query,
                    }
                );
            },

            /**
             * Returns the starting offset of the selected index.
             * If "all results" or "no results" is
             * visible, the offset is one.
             *
             * @returns {Number}
             */
            indexOffset() {
                return this.showAllResultsOption || this.showNoResultsMessage ? 1 : 0;
            },

            /**
             * @return {Number}
             */
            createPatientIndex() {
                return this.indexOffset + this.visiblePatients.length;
            },
        },

        watch: {
            patients() {
                this.reset();
            },
        },

        methods: {
            up() {
                this.selectedIndex = Math.max(0, this.selectedIndex - 1);
            },

            down() {
                const lengthOfItems = this.indexOffset + this.visiblePatients.length + 1;

                this.selectedIndex = Math.min(lengthOfItems - 1, this.selectedIndex + 1);
            },

            reset() {
                this.selectedIndex = 0;
            },

            enter() {
                if ((this.showAllResultsOption || this.showNoResultsMessage) && this.selectedIndex === 0) {
                    return this.goToAdvancedSearch();
                }

                if (this.selectedIndex === this.createPatientIndex) {
                    return this.goToCreate();
                }

                return this.goToSelectedPatient();
            },

            goToSelectedPatient() {
                window.location.href = this.selectUrl.replace(':id', this.selectedPatient.id);
            },

            goToAdvancedSearch() {
                window.location.href = updateQueryStringParameter(this.showAllUrl, 'query', this.query);
            },

            goToCreate() {
                window.location.href = this.createUrl;
            },
        },
    };
</script>
