<script type="text/babel">
import Default from './Default.vue';

export default {
    extends: Default,

    computed: {
        gonioLeftWristHasValues() {
            return this.hasValues(1, 12);
        },

        gonioLeftThumbHasValues() {
            return this.hasValues(13, 25);
        },

        gonioLeftIndexFingerHasValues() {
            return this.hasValues(27, 40);
        },

        gonioLeftMiddleFingerHasValues() {
            return this.hasValues(41, 54);
        },

        gonioLeftRingFingerHasValues() {
            return this.hasValues(55, 68);
        },

        gonioLeftPinkieHasValues() {
            return this.hasValues(69, 82);
        },

        gonioRightWristHasValues() {
            return this.hasValues(101, 12 + 100);
        },

        gonioRightThumbHasValues() {
            return this.hasValues(13 + 100, 25 + 100);
        },

        gonioRightIndexFingerHasValues() {
            return this.hasValues(27 + 100, 40 + 100);
        },

        gonioRightMiddleFingerHasValues() {
            return this.hasValues(41 + 100, 54 + 100);
        },

        gonioRightRingFingerHasValues() {
            return this.hasValues(55 + 100, 68 + 100);
        },

        gonioRightPinkieHasValues() {
            return this.hasValues(69 + 100, 82 + 100);
        },

        volumeLeftWristHasValues() {
            return this.hasValues(100 + 100, 105 + 100);
        },

        volumeLeftThumbHasValues() {
            return this.hasValues(106 + 100, 107 + 100);
        },

        volumeLeftIndexFingerHasValues() {
            return this.hasValues(108 + 100, 111 + 100);
        },

        volumeLeftMiddleFingerHasValues() {
            return this.hasValues(112 + 100, 115 + 100);
        },

        volumeLeftRingFingerHasValues() {
            return this.hasValues(116 + 100, 119 + 100);
        },

        volumeLeftPinkieHasValues() {
            return this.hasValues(120 + 100, 123 + 100);
        },

        volumeRightWristHasValues() {
            return this.hasValues(100 + 200, 105 + 200);
        },

        volumeRightThumbHasValues() {
            return this.hasValues(106 + 200, 107 + 200);
        },

        volumeRightIndexFingerHasValues() {
            return this.hasValues(108 + 200, 111 + 200);
        },

        volumeRightMiddleFingerHasValues() {
            return this.hasValues(112 + 200, 115 + 200);
        },

        volumeRightRingFingerHasValues() {
            return this.hasValues(116 + 200, 119 + 200);
        },

        volumeRightPinkieHasValues() {
            return this.hasValues(120 + 200, 123 + 200);
        },

        getSqueezingForceUnit() {
            return this.newtonOrKilogram(10002, this.squeezingForceUnitQuestions);
        },
        getPinchKeyUnit() {
            return this.newtonOrKilogram(10009, this.pinchKeyUnitQuestions);
        },
        getPinchThreeJawUnit() {
            return this.newtonOrKilogram(10016, this.pinchThreeJawUnitQuestions);
        },
        getPinchTipToTipUnit() {
            return this.newtonOrKilogram(10023, this.pinchTipToTipUnitQuestions);
        },

        getSqueezingForceLeftAverage() {
            return this.calculateAverage(10003, 10005, 10007);
        },

        getSqueezingForceRightAverage() {
            return this.calculateAverage(10004, 10006, 10008);
        },

        getPinchKeyLeftAverage() {
            return this.calculateAverage(10010, 10012, 10014);
        },

        getPinchKeyRightAverage() {
            return this.calculateAverage(10011, 10013, 10015);
        },

        getPinchThreeJawLeftAverage() {
            return this.calculateAverage(10017, 10019, 10021);
        },

        getPinchThreeJawRightAverage() {
            return this.calculateAverage(10018, 10020, 10022);
        },

        getPinchTipToTipLeftAverage() {
            return this.calculateAverage(10024, 10026, 10028);
        },

        getPinchTipToTipRightAverage() {
            return this.calculateAverage(10025, 10027, 10029);
        },
    },

    props: {
        squeezingForceUnitQuestions: {
            type: Object,
            required: true,
        },
        pinchKeyUnitQuestions: {
            type: Object,
            required: true,
        },
        pinchThreeJawUnitQuestions: {
            type: Object,
            required: true,
        },
        pinchTipToTipUnitQuestions: {
            type: Object,
            required: true,
        },
    },

    methods: {
        closePopups() {
            this.$refs.leftHand.closePopup();
            this.$refs.rightHand.closePopup();
            this.$refs.leftHandVolume.closePopup();
            this.$refs.rightHandVolume.closePopup();
        },

        newtonOrKilogram(id, questions) {
            const selectedUnit = questions[this.formData.clinimetrics_item_data[id].checked_answers];

            if (selectedUnit === 2) {
                return 'N';
            } else {
                return 'kg';
            }
        },

        calculateAverage(try1Id, try2Id, try3Id) {
            const try1 = this.formData.clinimetrics_item_data[try1Id].value_as_double;
            const try2 = this.formData.clinimetrics_item_data[try2Id].value_as_double;
            const try3 = this.formData.clinimetrics_item_data[try3Id].value_as_double;

            return ((try1 + try2 + try3) / 3).toFixed(2);
        },

        hasValues(start, end) {
            let hasValues = false;

            for (let key = start; key <= end; key++) {
                if (this.formData.clinimetrics_item_data[key].value_as_double) {
                    hasValues = true;
                    break;
                }
            }

            return hasValues;
        },
    },
};
</script>
