<template>
    <button :disabled="!value" type="button" @click="click">
        <slot />
    </button>
</template>

<script>
    import ModalManager from '../services/modalManager';

    export default {
        props: {
            value: {
                type: [Number, String],
                default: null,
            },

            url: {
                type: String,
                required: true,
            },
        },

        methods: {
            click() {
                ModalManager.openModal(this.url.replace(':id', this.value))
                    .then((data) => {
                        if (data !== null) {
                            this.$emit('refresh');
                        }
                    });
            },
        },
    };
</script>
